import React, { useState } from "react";
import { Modal, Rate } from "antd";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper/modules";
import { Link } from "react-router-dom";
import useStore from "../contexts/store";
import { FaRegImage } from "react-icons/fa6";

function ProductCard({
  link,
  discount,
  imgSrc,
  title,
  originalPrice,
  discountedPrice,
  description,
  storeName,
  id,
  shopid,
  category,
  productQuantity,
  size,
  weight,
  average_star,
  reviewusers
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [animate, setAnimate] = useState(false);
  const [error, setError] = useState("");
  const [selectedSize, setSelectedSize] = useState("");
  const [isImageLoading, setIsImageLoading] = useState(true);

  const [formData, setFormData] = useState({
    quantity: "1",
  });

  // console.log("size", size)

  const handleImageLoad = () => {
    setIsImageLoading(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  let imageNames = [];
  if (imgSrc) {
    try {
      const pictureData = JSON.parse(imgSrc);
      imageNames = pictureData.imageNames;
    } catch (e) {
      console.error("Invalid JSON in item.picture", e);
    }
  }

  const images = imageNames.map((name, index) => (
    <SwiperSlide key={index}>
      <img src={`${link}${name}`} alt={`${title} ${index}`} />
    </SwiperSlide>
  ));

  const image0 = link + imageNames[0];

  const addToCart = useStore((state) => state.addToCart);
  const cart = useStore((state) => state.cart);

  const isInCart = cart.some((item) => item.id === id);

  const handleAddToCart = () => {
    if (selectedSize || !size) {
      setAnimate(true);
      setTimeout(() => {
        addToCart({ id, title, originalPrice, image: image0, shopid, category, selectedSize, productQuantity,weight });
        setAnimate(false);
      }, 1000); // Match the animation duration
    } else {
      setError("Please select a size.");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (value > productQuantity) {
      setError("We don't have enough stock.");
    } else {
      setError("");
    }
  };

  const handleSizeSelect = (size) => {
    setSelectedSize(size);
    setError("");
  };

  return (
    <>
      <div className="w-full h-full p-4 bg-white rounded-md">
        <Link to={`/productdetail/${id}`} className="relative card-img cursor-pointer">
          {/* <img
            src={image0}
            alt={title}
            className={`w-full h-60 card-img hover:blur-s ${animate ? "animate-to-cart" : ""}`}
          /> */}
          <img
            src={image0}
            // alt={title}
            onLoad={handleImageLoad}
            style={{ display: isImageLoading ? "none" : "block" }}
            className={`w-full h-60 card-img hover:blur-s ${animate ? "animate-to-cart" : ""}`}
          />
          {isImageLoading && <div className="custom-skeleton rounded w-full h-60 flex items-center justify-center">
            <FaRegImage color="#BFBFBF" size={50} />
          </div>}
          {/* <div
            onClick={showModal}
            className="image-overlay bg-[#009f7f] p-2 rounded-[50%] absolute top-2/4 left-[45%] hidden"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-white"
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M12 5c-7 0-9 7-9 7s2 7 9 7 9-7 9-7-2-7-9-7zm0 12c-3.31 0-6-2.69-6-6s2.69-6 6-6 6 2.69 6 6-2.69 6-6 6zm0-10c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm-1 4c0-.55.45-1 1-1s1 .45 1 1-.45 1-1 1-1-.45-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </div> */}
        </Link>
        <Link to={`/productdetail/${id}`} className="text-sm font-semibold h-16 truncate-3-lines text-heading mt-4 hover:text-[#009f7f]">{title}</Link>
        <div className="flex gap-x-5">
          <Rate allowHalf disabled value={Math.ceil(average_star * 10) / 10} />
          <p className="text-[#009f7f]">{reviewusers}</p>
        </div>
        <div className="flex">
          <span>$</span>
          <span className="text-sm font-semibold text-accent md:text-2xl">
            {originalPrice}
          </span>
          {/* <button
            onClick={handleAddToCart}
            disabled={isInCart}
            className={`order-5 flex items-center justify-center rounded-full border-2 border-border-100 bg-white px-3 py-2 text-sm font-semibold ${
              isInCart ? "text-gray-500" : "text-[#009f7f]"
              } transition-colors duration-300 hover:border-[#009f7f] hover:bg-[#009f7f] hover:text-white focus:border-[#009f7f] focus:bg-[#009f7f] focus:text-white focus:outline-0 sm:order-4 sm:justify-start sm:px-5`}
              >
              <svg
              className="h-4 w-4 ltr:mr-2.5 rtl:ml-2.5 text-[#009f7f] transition-colors duration-300"
              viewBox="0 0 14.4 12"
              >
              <g transform="translate(-288 -413.89)">
              <path
              fill="currentColor"
              d="M298.7,418.289l-2.906-4.148a.835.835,0,0,0-.528-.251.607.607,0,0,0-.529.251l-2.905,4.148h-3.17a.609.609,0,0,0-.661.625v.191l1.651,5.84a1.336,1.336,0,0,0,1.255.945h8.588a1.261,1.261,0,0,0,1.254-.945l1.651-5.84v-.191a.609.609,0,0,0-.661-.625Zm-5.419,0,1.984-2.767,1.98,2.767Zm1.984,5.024a1.258,1.258,0,1,1,1.319-1.258,1.3,1.3,0,0,1-1.319,1.258Zm0,0"
              ></path>
              </g>
              </svg>
              <span className="text-[#009f7f] ml-1">
              {isInCart ? " In Cart" : " Cart"}
              </span>
              </button> */}
        </div>
        <p className="text-xs font-medium">Ship from <span className="text-[#009f7f]">{storeName}</span></p>
        {
          category == "14" ?
            <Link to={`/productdetail/${id}`} className="bg-[#009f7f] mt-5 w-full rounded-2xl block text-center">
              <p className="text-md font-semibold text-white py-1 ">{isInCart ? " In Cart" : " View Details"}</p>
            </Link>
            :
            <button onClick={handleAddToCart} disabled={isInCart} className="bg-[#009f7f] mt-5 w-full rounded-2xl">
              <p className="text-md font-semibold text-white py-1 ">{isInCart ? " In Cart" : " Add to Cart"}</p>
            </button>
        }
      </div>
      {/* <Modal
        width={900}
        open={isModalOpen}
        footer={null}
        closable={false}
        onCancel={handleCancel}
      >
        <div className="grid grid-cols-1 md:grid-cols-2">
          <div>
            <Swiper
              pagination={{
                type: "fraction",
              }}
              navigation={true}
              modules={[Pagination, Navigation]}
              className="mySwiper"
            >
              {images}
            </Swiper>
          </div>
          <div className="p-4">
            <Link
              to={`/shop/${storeName?.replace(/\s/g, "-")}/${shopid}`}
              className="text-[#009f7f]"
            >
              {storeName}
            </Link>
            <h1 className="text-lg font-semibold tracking-tight text-heading md:text-xl xl:text-2xl cursor-pointer transition-colors hover:text-accent">
              {title}
            </h1>
            <div
              className="mt-6"
              dangerouslySetInnerHTML={{ __html: description }}
            />
            <h1 className="text-2xl mt-16 font-semibold text-accent no-underline md:text-3xl text-[#009f7f]">
              ${originalPrice}
            </h1>
            {size !== 'undefined' && size !== null ? (
              <div className="mt-4">
                <label className="block mb-1 font-semibold text-body-dark">Select Size</label>
                <div className="flex space-x-2">
                  {size?.split(",").map((s) => (
                    <button
                      key={s}
                      onClick={() => handleSizeSelect(s)}
                      className={`px-4 py-2 border rounded ${selectedSize === s ? "bg-[#009f7f] text-white" : "bg-white text-[#009f7f]"} transition-colors duration-300`}
                    >
                      {s}
                    </button>
                  ))}
                </div>
              </div>
            ) : ('')}
            <label className="block mb-1 mt-4 font-semibold text-body-dark">Quantity*</label>
            <input
              type="number"
              id="quantity"
              name="quantity"
              value={formData.quantity}
              onChange={handleChange}
              className="border border-gray-300 rounded-md px-3 py-2 mb-3 focus:outline-none focus:border-blue-500"
            />
            {error && <p className="text-red-500">{error}</p>}
            <div className="grid grid-cols-1 mt-6 sm:grid-cols-2">
              <button
                onClick={handleAddToCart}
                disabled={isInCart || !!error || (size && !selectedSize)}
                className="flex w-full items-center text-white bg-[#009f7f] justify-center rounded bg-accent py-4 px-5 text-sm font-light text-light transition-colors duration-300 hover:bg-accent-hover focus:bg-accent-hover focus:outline-0 lg:text-base"
              >
                <span className="text-white">
                  {isInCart ? "In Cart" : "Add to Cart"}
                </span>
              </button>
            </div>
          </div>
        </div>
      </Modal> */}
    </>
  );
}

export default ProductCard;
