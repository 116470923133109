import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { FaCloudUploadAlt } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { postRequest } from "../../backendServices/ApiCalls";
import { notification } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Stack from "@mui/material/Stack";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, clothSize, theme) {
  return {
    fontWeight:
      clothSize.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const names = ["XS", "S", "M", "L", "XL"];

export default function UpdateProduct() {
  const theme = useTheme();
  const nav = useNavigate();
  const [featuredImage, setFeaturedImage] = useState([]);
  const [imageProgress, setImageProgress] = useState(false);
  const [imageLink, setImageLink] = useState("");
  const [api, contextHolder] = notification.useNotification();
  const [formData, setFormData] = useState({
    title: "",
    price: "",
    description: "",
    category: "",
    quantity: "",
    weight: "",
  });
  const [clothSize, setClothSize] = React.useState([]);
  const [previousSize, setPreviousSize] = React.useState("");
  const sizesArray = previousSize?.split(",");

  const successNotification = (placement, message, description) => {
    api.success({ message, description, placement });
  };
  const errorNotification = (placement, message, description) => {
    api.error({ message, description, placement });
  };
  const infoNotification = (placement, message, description) => {
    api.info({ message, description, placement });
  };
  // const locationParam = useParams();
  const locationParam = { id: sessionStorage.getItem("newProductId") }
  const [categoryHook, setCategoryHook] = useState([]);
  const [productName, setProductName] = useState({});
  // console.log('categoryHook', categoryHook)

  // console.log('featuredImage', featuredImage)
  const productsFun = () => {
    let param = {
      id: locationParam.id,
    };
    postRequest(
      "/getProductById",
      param,
      async (response) => {
        //   setLoading(true);
        // console.log("response?.data?.data", response?.data?.data);
        //   console.log("response?.data", response?.data?.link);
        if (response?.data?.status === "success") {
          // infoNotification('topRight', 'Info', 'Please add images again')
          setImageLink(response?.data?.link);
          const productData = response?.data?.data[0];
          
          setFormData({
            title: productData.title.replace(/\\/g, ''),
            price: productData.price,
            description: productData.description.replace(/\\/g, ''),
            category: productData.category,
            quantity: productData.quantity,
            weight: productData.weight,
          });
          setPreviousSize(productData.size);
          if (productData.picture) {
            const images = JSON.parse(productData.picture).imageNames.map(
              (imageName) => ({
                preview: response?.data?.link + imageName, // Adding the link here
                name: imageName,
              })
            );
            // console.log('images', images)
            setFeaturedImage(images);
            // setFeaturedImageName(images)
          }
          // console.log('formData', formData)
          // setLink(response?.data?.link);
          // setLoading(false);
        }
      },
      (error) => {
        console.log(error?.response?.data);
        // setLoading(false);
      }
    );
  };

  const productCategory = () => {
    postRequest(
      "/productcategory",
      "",
      async (response) => {
        // setLoading(true);
        // console.log("response?.data", response?.data?.data);
        if (response?.data?.status === "success") {
          setCategoryHook(response?.data?.data);
          //   setLink(response?.data?.link);
          //   setLoading(false);
        }
      },
      (error) => {
        console.log(error?.response?.data);
        //   setLoading(false);
      }
    );
  };
  useEffect(() => {
    productCategory();
    productsFun();
  }, []);

  const uploadProduct = (files) => {
    const formDataToSend = new FormData();
    files.forEach((file) => {
      formDataToSend.append("images", file, file.name);
    });
    setImageProgress(true);

    postRequest(
      "/updateProductImageById",
      formDataToSend,
      (response) => {
        // console.log("response?.data?.data", response?.data?.data);
        if (response?.data?.status === "success") {
          successNotification("topRight", "Success", "Images uploaded");
          setProductName(response?.data?.data);
          setImageProgress(false);
        } else if (response?.data?.status === "error") {
          errorNotification("topRight", "Error", "Images not uploaded");
          setImageProgress(false);
        }
      },
      (error) => {
        console.log(error?.response?.data);
        setImageProgress(false);
      }
    );
  };

  const handleDrop = (acceptedFiles, setImages) => {
    const newFiles = acceptedFiles.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );
    setImages((prevFiles) => [...prevFiles, ...newFiles]);
    uploadProduct(newFiles);
    // newFiles.forEach(file => uploadProduct(file));
  };

  const handleRemoveImage = (indexToRemove, setImages) => {
    setImages((prevFiles) =>
      prevFiles.filter((file, index) => index !== indexToRemove)
    );
  };

  const featuredDropzone = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => handleDrop(acceptedFiles, setFeaturedImage),
  });

  const thumbs = (files, setImages) =>
    files.map((file, index) => (
      <div
        className="inline-flex mb-2 mr-2 w-24 h-24 p-1 box-border  relative"
        key={index}
      >
        {imageProgress ? (
          <div role="status" className="absolute z-10 top-1/4 right-1/3">
            <div className="flex items-center justify-center">
              <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#009F7F]"></div>
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="relative min-w-0 overflow-hidden">
          <IoClose
            size={20}
            className="remove-icon absolute top-0 right-0 cursor-pointer z-10 p-1 rounded-full bg-white bg-opacity-80"
            onClick={() => handleRemoveImage(index, setImages)}
          />
          <img
            src={file.preview}
            className={`block w-auto h-full rounded ${
              imageProgress ? "brightness-50" : ""
            }`}
            alt=""
          />
        </div>
      </div>
    ));

  useEffect(() => {
    return () => {
      featuredImage.forEach((file) => URL.revokeObjectURL(file.preview));
    };
  }, [featuredImage]);

  const handleChangeSize = (event) => {
    const {
      target: { value },
    } = event;
    setClothSize(
      // On autofill we get a stringified value.
      typeof value === "string" ? value?.split(",") : value
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      formData.title == "" ||
      formData.description == "<p><br></p>" ||
      formData.price == "" ||
      formData.quantity == "" ||
      featuredImage.length == 0 ||
      formData.category == "" ||
      formData.weight == ""
    ) {
      errorNotification("topRight", "Error", "Fill all the product detail");
      return;
    }

    let imageNames;
    // console.log('featuredImage in submit', featuredImage)
    if (productName && Object.keys(productName).length > 0) {
      // console.log('productName', productName)
      const nameIndex = JSON.parse(productName).imageNames;

      const fileIndices = [];

      for (let index = 0; index < featuredImage.length; index++) {
        const item = featuredImage[index];
        if (item instanceof File) {
          fileIndices.push(index);
        }
      }

      for (let index = 0; index < nameIndex.length; index++) {
        let i = fileIndices[index];
        featuredImage[i] = {
          preview: imageLink + nameIndex[index],
          name: nameIndex[index],
        };
      }

      // Update state with modified array
      setFeaturedImage([...featuredImage]); // Ensures state update triggers properly

      // console.log("Indices of File objects:", fileIndices);

      // console.log("Indices of File objects:", fileIndices);
      // console.log('formData', formData);
      // console.log('featuredImage in submit', featuredImage)

      imageNames = featuredImage.map((item) => item.name);
      imageNames = imageNames.join(",");

      // const result = { imageNames };
      // console.log('JSON.stringify(result)', JSON.stringify(result));
    } else {
      imageNames = featuredImage.map((item) => item.name);
      imageNames = imageNames.join(",");
    }
    formData.clothSize= clothSize
    sessionStorage.setItem("productinfo", JSON.stringify(formData));
    const formDataToSend = new FormData();
    formDataToSend.append("title", formData.title);
    formDataToSend.append("id", locationParam.id);
    formDataToSend.append("price", formData.price);
    formDataToSend.append("quantity", formData.quantity);
    formDataToSend.append("weight", formData.weight);
    formDataToSend.append("description", formData.description);
    formDataToSend.append("category", formData.category);
    formDataToSend.append("picture", imageNames);

		if (clothSize.length === 0) {
			formDataToSend.append("size", sizesArray);
		} else {
			formDataToSend.append("size", clothSize);
		}
    setImageProgress(true);
    postRequest(
      "/updateProductInfoById",
      formDataToSend,
      (response) => {
        if (response?.data?.status === "success") {
          successNotification("topRight", "Success", response?.data?.message);
          setImageProgress(false);
          // nav("/dashboard/allproducts");
        } else if (response?.data?.status === "error") {
          errorNotification("topRight", "Error", response?.data?.message);
          setImageProgress(false);
        }
      },
      (error) => {
        console.log(error?.response?.data);
        setImageProgress(false);
      }
    );
  };

  return (
    <>
      <div className="flex pb-5 border-b-2 mx-2 2xl:mx-20 border-dashed border-border-base">
        <h1 className="text-lg font-semibold text-heading">Update Product</h1>
      </div>
      <div className="grid grid-cols-1 2xl:mx-20 lg:grid-cols-3 mx-2 gap-2 my-5 pb-5 border-b-2 border-dashed border-border-base">
        <div className="flex items-center sm:col-span-1">
          <div className="w-full px-0 pb-5">
            <h4 className="text-base font-semibold text-body-dark mb-2">
              Image
            </h4>
            <p className="text-sm text-body">
              <span>Update your product image here</span>
            </p>
          </div>
        </div>
        <div className="sm:col-span-2">
          <div className="text-center bg-white p-4  rounded">
            <div
              {...featuredDropzone.getRootProps({
                className:
                  "dropzone p-2 border-2 border-dashed border-gray-400 rounded mt-4",
              })}
            >
              <input {...featuredDropzone.getInputProps()} />
              <p className="mt-4 text-sm text-center text-body flex items-center flex-col">
                <FaCloudUploadAlt color="#D1D5DB" size={50} />
                <span className="font-semibold text-[#019477]">
                  Update an image
                </span>
                or drag and drop <br />
                <span className="text-xs text-body">PNG, JPG</span>
              </p>
            </div>
            <aside className="flex flex-row flex-wrap mt-4">
              {thumbs(featuredImage, setFeaturedImage)}
            </aside>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 2xl:mx-20 lg:grid-cols-3 mx-2 gap-2 my-5 pb-5 border-b-2 border-dashed border-border-base">
        <div className="flex items-center sm:col-span-1">
          <div className="w-full px-0 pb-5">
            <h4 className="text-base font-semibold text-body-dark mb-2">
              Description
            </h4>
            <p className="text-sm text-body">
              <span>
                Update your product description and necessary <br />
                information from here
              </span>
            </p>
          </div>
        </div>
        <div className="sm:col-span-2">
          <div className="bg-white p-4 rounded">
            <form className="max-w-md mx-auto mt-4">
              <label
                htmlFor="title"
                className="block mb-1 font-semibold text-body-dark"
              >
                Name*
              </label>
              <input
                type="title"
                id="title"
                name="title"
                value={formData.title}
                onChange={handleChange}
                className="w-full border border-gray-300 rounded-md px-3 py-2 mb-3 focus:outline-none focus:border-blue-500"
              />
              <label
                htmlFor="price"
                className="block mb-1 font-semibold text-body-dark"
              >
                Price*
              </label>
              <input
                type="number"
                id="price"
                name="price"
                value={formData.price}
                onChange={handleChange}
                className="w-full border border-gray-300 rounded-md px-3 py-2 mb-3 focus:outline-none focus:border-blue-500"
              />
              <label
                htmlFor="category"
                className="block mb-1 font-semibold text-body-dark"
              >
                Category*
              </label>
              <select
                id="category"
                name="category"
                value={formData.category}
                onChange={handleChange}
                className="w-full border border-gray-300 rounded-md px-3 py-2 mb-3 focus:outline-none focus:border-blue-500"
              >
                <option value="" disabled>
                  Select a category
                </option>
                {categoryHook.map((category, i) => (
                  <option
                    key={i}
                    value={category.id}
                    disabled={category.name === "Select a category"}
                  >
                    {category.name}
                  </option>
                ))}
              </select>
              {formData.category === "14" ? (
                <>
                  <label
                    htmlFor="title"
                    className="block mb-1 font-semibold text-body-dark"
                  >
                    Previous Size*
                  </label>
                  <Stack direction="row" spacing={1} sx={{ mb: 2 }}>
                    {sizesArray?.map((size, index) => (
                      <Chip key={index} label={size} color="primary" />
                    ))}
                  </Stack>
                  <FormControl className="w-full border border-gray-300 rounded-md px-3 py-2 mb-3 focus:outline-none focus:border-blue-500">
                    <InputLabel id="demo-multiple-chip-label">
                      New Size
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-chip-label"
                      id="demo-multiple-chip"
                      multiple
                      value={clothSize}
                      onChange={handleChangeSize}
                      input={
                        <OutlinedInput
                          id="select-multiple-chip"
                          label="New Size"
                        />
                      }
                      renderValue={(selected) => (
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {selected.map((value) => (
                            <Chip key={value} label={value} />
                          ))}
                        </Box>
                      )}
                      MenuProps={MenuProps}
                    >
                      {names.map((name) => (
                        <MenuItem
                          key={name}
                          value={name}
                          style={getStyles(name, clothSize, theme)}
                        >
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </>
              ) : (
                ""
              )}
              <label
                htmlFor="quantity"
                className="block mb-1 font-semibold text-body-dark"
              >
                Quantity*
              </label>
              <input
                type="number"
                id="quantity"
                name="quantity"
                value={formData.quantity}
                onChange={handleChange}
                className="w-full border border-gray-300 rounded-md px-3 py-2 mb-3 focus:outline-none focus:border-blue-500"
              />
              <label
                htmlFor="weight"
                className="block mb-1 font-semibold text-body-dark"
              >
                Weight*(pound)
              </label>
              <input
                type="number"
                id="weight"
                name="weight"
                value={formData.weight}
                onChange={handleChange}
                className="w-full border border-gray-300 rounded-md px-3 py-2 mb-3 focus:outline-none focus:border-blue-500"
              />
              
              <label
                htmlFor="description"
                className="block mb-1 font-semibold text-body-dark"
              >
                Description*
              </label>
              <ReactQuill
                id="description"
                value={formData.description}
                // onChange={(value) => { setFormData({ ...formData, description: value }) }}
                onChange={(value) => {
                  setFormData((prevFormData) => ({
                    ...prevFormData,
                    description: value,
                  }));
                }}
                className="border border-gray-300 rounded-md mb-3 focus:outline-none focus:border-blue-500"
              />
            </form>
          </div>
        </div>
      </div>
      <div className="stick 2xl:mr-10 bottom-0 -mx- bg-gray-100/10 py-3 px-5 backdrop-blur text-end z-10">
        {imageProgress ? (
          <button>
            <span className="flex items-center justify-center">
              <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#009F7F]"></div>
            </span>
          </button>
        ) : (
          <button
            onClick={handleSubmit}
            type="submit"
            className="bg-[#019376] hover:bg-green-800 text-white font-bold py-4 px-6 rounded"
          >
            Update Product
          </button>
        )}
      </div>

      <div>{contextHolder}</div>
    </>
  );
}
