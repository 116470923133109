import React, { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';

export default function PrivateRouteForBuyer({ Component }) {
    const { user } = useContext(UserContext);
    const location = useLocation();
    const token = localStorage.getItem("token");
    // console.log('user in PrivateRouteForBuyer', user);
  
    if (user == false && token == null) {
      return <Navigate to="/" state={{ from: location.pathname }} replace />;
    }
  
    return <Component />;
}
