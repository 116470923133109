import React, { useState, useEffect } from "react";
import { Collapse, Button, notification } from "antd";
import { FaPlus } from "react-icons/fa";
import AddressModal from "../../components/AddressModal";
import useStore from "../../contexts/store";
import { useContext } from 'react';
import { UserContext } from "../../contexts/UserContext";
import { postRequest } from "../../backendServices/ApiCalls";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import CreditCard from "../../components/CreditCard/CreditCard";
import { Alert, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Button as MuiButton, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import countryList from 'react-select-country-list';
import Web3ModalProvider from "../web3/Web3ModalProvider";
import { useAccount } from "wagmi";

const Payment = () => {
  const nav = useNavigate()
  const options = countryList().getData();
  const [loading, setLoading] = useState(false);
  const [payText, setpayText] = useState('Pay with Matic / ETH')
  const removeFromCart = useStore((state) => state.removeFromCart);
  const [api, contextHolder] = notification.useNotification();
  const successNotification = (placement, message, description) => {
    api.success({ message, description, placement });
  };
  const errorNotification = (placement, message, description) => {
    api.error({ message, description, placement });
  };

  const { user } = useContext(UserContext);
  // console.log('user', user)
  const [selectedCarrier, setSelectedCarrier] = useState('')
  const [carriers, setCarriers] = useState([])
  const [carrierCode, setCarrierCode] = useState('')
  // console.log("🚀 ~ Payment ~ carrierCode:", carrierCode)
  const [shippingCharges, setShippingCharges] = useState(0)
  const [serviceCode, setServiceCode] = useState(0)
  // console.log("🚀 ~ Payment ~ serviceCode:", serviceCode)
  const [showRates, setShowRates] = useState(false)
  const [carriersLoading, setcarriersLoading] = useState(true)
  const [carrierrates, setCarrierrates] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false); // State to manage edit mode
  const [activePanel, setActivePanel] = useState("1");
  const [address, setAddress] = useState([]);
  const [openEvm, setOpenEvm] = useState(false); // Possible values: 'loading', 'success', 'rejected'
  const { isConnected } = useAccount()


  const cart = useStore((state) => state.cart);
  const [checkedItems, setCheckedItems] = useState(() => {
    const savedCheckedItems = JSON.parse(localStorage.getItem("checkedItems"));
    if (savedCheckedItems) {
      return savedCheckedItems;
    } else {
      return {};
    }
  });
  // console.log("🚀 ~ Payment ~ cart:", cart);
  if (cart.length == 0) {
    nav("/")
  }

  let orderTotalAmount = cart.reduce((acc, item, index) => {
    if (checkedItems[index]) {
      return acc + item.totalPrice;
    }
    return acc;
  }, 0)


  const orderTotalWeight = cart.reduce((acc, item, index) => {
    if (checkedItems[index]) {
      return acc + item.weight;
    }
    return acc;
  }, 0)

  // console.log('orderTotalWeight', orderTotalWeight)
  const showModal = (editMode) => {
    setIsEditMode(editMode);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setActivePanel((parseInt(activePanel) + 1).toString());
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    let initialCheckedState = {};
    cart.forEach((item, index) => {
      if (!checkedItems[index]) {
        initialCheckedState[index] = true;
      } else {
        initialCheckedState[index] = true;
      }
    });
    setCheckedItems(initialCheckedState);
  }, [cart]);



  const handleOrder = (paymentType, additionalData = {}) => {
    // console.log('selectedCarrier', selectedCarrier)
    // console.log('carriers', carriers)
    // console.log('carrierCode', carrierCode)
    // console.log('carrierrates', carrierrates)
    // console.log('shippingCharges', shippingCharges)
    // console.log('serviceCode', serviceCode)
    // console.log('packageCode', packageCode)
    // console.log('orderTotalWeight', orderTotalWeight)
    // return
    let totalAmount = (+orderTotalAmount + +shippingCharges).toFixed(2);

    if (paymentType === "E-Wallet" && totalAmount > address.current_balance) {
      return errorNotification("topRight", "Error", "Insufficient Balance");
    }

    let storeId = cart.map((item) => item.shopid);
    let storeIdString = storeId.join(", ");

    let ids = cart.map((item) => item.id);
    let productIdString = ids.join(", ");
    const idsArray = productIdString.split(',').map(id => Number(id.trim()));
    // console.log('idsArray', typeof(idsArray), idsArray)
    // console.log('productIdString', typeof(productIdString), productIdString)

    let idquantity = cart.map((item) => item.quantity);
    let quantityString = idquantity.join(", ");

    let idweight = cart.map((item) => item.quantity);
    let weightString = idweight.join(", ");

    let sizeSelected = cart.map((item) => item.selectedSize);
    let sizeSelectedString = sizeSelected.join(", ");

    let param = {
      totalAmount: totalAmount,
      productId: productIdString,
      quantity: quantityString,
      weight: weightString,
      size: sizeSelectedString,
      paymentType: paymentType,
      serviceCode, carrierCode,
      ...additionalData // Spread any additional data passed in
    };
    // console.log("param", param);
    // return 
    setLoading(true);
    postRequest("/orderInfo", param, (response) => {
      if (response?.data?.status === "success") {
        if (paymentType == "Credit-Card") {
          console.log("Credit-Card===>",response?.data)
        }
        for (const id of idsArray) {
          removeFromCart(id)
        }
        Swal.fire({
          title: "Processing...",
          html: '<div class="flex items-center justify-center h-20">' +
            '<div class="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#009F7F]"></div>' +
            '</div>',
          showConfirmButton: false,
          timer: 2000,
          // didOpen: () => {
          //   // Start loading spinner
          //   Swal.showLoading();
          // }
        }).then(() => {
          Swal.fire({
            title: response?.data?.message,
            icon: 'success',
            showConfirmButton: false,
            timer: 2000
          });
        });

        setTimeout(() => {
          nav(`/order/${response?.data?.orderId}`)
        }, 4000);
        setLoading(false);
      } else if (response?.data?.status === "error") {
        errorNotification("topRight", "Error", response?.data?.message);
        setLoading(false);
      }
    },
      (error) => {
        console.log(error);
        setLoading(false);
      }
    );
  };

  const payWithCrypto = () => {
    let ids = cart.map((item) => item.id);
    let productIdString = ids.join(", ");
    const idsArray = productIdString.split(',').map(id => Number(id.trim()));
    setpayText('Loading...')
    let totalAmount = (+orderTotalAmount + +shippingCharges).toFixed(2);
    let params = {
      amount: totalAmount,
    };
    postRequest(
      '/createcharge',
      params,
      async (response) => {
        if (response?.data?.status === "error") {
          errorNotification("topRight", "Error", "Something went wrong. Please try again later");

        } else if (response?.data?.status === "success") {
          // console.log(response?.data?.data?.data?.hosted_url)
          for (const id of idsArray) {
            removeFromCart(id)
          }
          Swal.fire({
            title: "Processing...",
            html: '<div class="flex items-center justify-center h-20">' +
              '<div class="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#009F7F]"></div>' +
              '</div>',
            showConfirmButton: false,
            timer: 2000,
            // didOpen: () => {
            //   // Start loading spinner
            //   Swal.showLoading();
            // }
          }).then(() => {
            Swal.fire({
              title: response?.data?.message,
              icon: 'success',
              showConfirmButton: false,
              timer: 2000
            });
          });
          setTimeout(() => {
            window.location.href = response?.data?.data?.data?.hosted_url
          }, 4000);
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  }

  const handleOkPanel = (key) => {
    let k = parseInt(key[0]);

    if (k === 4 && shippingCharges <= 0) {
      errorNotification("topRight", "Error", "Please select a shipping option before proceeding.");
      return;
    }

    if (activePanel < items.length) {
      setActivePanel((parseInt(activePanel) + 1).toString());
    } else {
      setActivePanel(key);
    }
  };

  const getData = () => {
    postRequest("/getaddress", "", (response) => {
      // console.log('response?.data?.data', response?.data?.data[0])
      if (response?.data?.status === "success") {
        setAddress(response?.data?.data[0])
        // successNotification("topRight", "Success", response?.data?.message);
      } else if (response?.data?.status === "error") {
        // errorNotification("topRight", "Error", response?.data?.message);
      }
    },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  const getCarriers = () => {
    postRequest("/carrierslist", "", (response) => {
      // console.log('carrierslist', response?.data?.data[0])
      if (response?.data?.status === "success") {
        setCarriers(response?.data?.data)
        setcarriersLoading(false)
        // successNotification("topRight", "Success", response?.data?.message);
      }
    },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  useEffect(() => {
    getData()
    getCarriers()
  }, [])

  useEffect(() => {
    getData()
  }, [isModalOpen])

  const handleRadioChange = (event, cdata) => {
    setCarrierrates([])
    setShowRates(false)
    setCarrierCode(cdata?.code)
    setSelectedCarrier(cdata?.code)
    const initialCountry = options.find(option => option.label === address?.country);
    const params = {
      carriercode: cdata?.code,
      frompostalcodes: 33815,
      tocountry: initialCountry?.value,
      topostalcode: address?.zipcode,
      weight: orderTotalWeight

    }
    postRequest("/shippingrates", params, (response) => {
      // console.log('shippingrates', response?.data?.data[0])
      if (response?.data?.status === "success") {
        setCarrierrates(response?.data?.data)
        setShowRates(true)
        setSelectedCarrier('')
        // successNotification("topRight", "Success", response?.data?.message);
      }
    },
      (error) => {
        console.log(error?.response?.data);
      }
    );

    // Handle the change event as needed
  };

  const openEvmModal = () => {
    setOpenEvm(true)
  }

  const handleCloseEvm = () => {
    setOpenEvm(false)

  }
  const handleCreditCardData = (data) => {
    console.log("Received data from CreditCard component:", data);
    // Call handleOrder with credit card payment type and the credit card data
    handleOrder("Credit-Card", { creditCardData: data });
  };
  const onPlaceOrderClick = () => {
    handleOrder("E-Wallet"); // This will call handleOrder with "E-Wallet" as the payment type
  };
  const items = [
    {
      key: "1",
      label: "Choose a shipping address",
      showAtEnd: "Use this address",
      content: (
        <div className="border rounded-lg">
          <p className="mx-6 border-b mt-4">Your addresses</p>
          <p className="mx-6 border border-[#FBD8B4] mt-2 py-2 mb-2 bg-[#FCF5EE] rounded-lg px-10 text-sm font-normal">
            <span className="font-bold">{address.firstname} {address.lastname}</span>
            <p> {address.address}, {address.city}, {address.state} {address.zipCode}, {address.country}, Mobile: {address.mobile}</p>
            <span
              onClick={() => showModal(true)} // Pass true for edit mode
              className="text-[#009DC5] cursor-pointer hover:underline hover:text-red-500"
            >
              {" "}
              Edit address
            </span>
          </p>
          {/* <div
            onClick={() => showModal(false)} // Pass false for add new address mode
            className="flex cursor-pointer gap-2 mb-12 mx-6"
          >
            <FaPlus className="text-[#DDDDDD]" />
            <p className="text-sm font-normal text-[#009DC5] hover:underline hover:text-red-500 cursor-pointer">
              Add a new address
            </p>
          </div> */}
          <div className="bg-[#F0F2F2">
            <button
              className="bg-[#009f7f] my-3 mx-5 rounded-lg"
              onClick={handleOk}
            >
              <p className="font-bold px-3 py-1 text-white text-sm">
                Use this address
              </p>
            </button>
          </div>
          {activePanel === "1" && (
            <p className="text-center text-xs mb-1 px-1">
              Choose a shipping address to continue checking out.
            </p>
          )}
        </div>
      ),
    },
    {
      key: "2",
      label: "2 Items",
      content: (
        <div>
          {cart.map((item, i) => (
            <div key={i} className="border flex gap-2 mt-4 rounded-lg">
              <div>
                <img src={item.image} className="w-36 h-36 m-2" alt="Item" />
              </div>
              <div className="w-[70%] flex items-center flex-col text-center sm:items-start sm:text-left">
                <h3 className="font-semibold truncate-3-lines mt-2 lg:mr-40 text-sm sm:text-lg">
                  {item.title}
                </h3>
                <div className="flex">
                  <span className="text-sm text-[#009f7f]">$</span>
                  <span className="text-xl font-bold text-[#009f7f] text-accent md:text-2xl">
                    {item.originalPrice}
                  </span>
                </div>
                <div className="w-16 text-sm rounded-3xl my-1 text-center bg-gray-300">
                  Qty: {item.quantity}
                </div>
                {/* <p className="text-sm font-normal">{item.category}</p> */}
              </div>
            </div>
          ))}
          <div className="border rounded-lg mt-4 p-2 flex flex-col-reverse sm:grid sm:grid-cols-3 items-start sm:items-center gap-2">
            <div>
              <button className="bg-[#009f7f] p-2 text-xs sm:text-base rounded-lg text-white mx-auto float-start" onClick={handleOk}>Confirm products</button>
            </div>
            <div className="w-[70%]">
              <div className="flex mt-3 text-[#B12704] mb-1 font-bold text-xl px-4">
                <p>Order total: </p>
                <p>
                  $
                  {(+orderTotalAmount + +shippingCharges).toFixed(2)}
                </p>
              </div>
              <p p className="text-sm mb-2">
                By clicking or tapping Place order, You agree to{" "}
                <span className="underline text-green-600 text-sm">
                  Privacy notice{" "}
                </span>{" "}
                and{" "}
                <span className="underline text-green-600 text-sm">
                  {" "}
                  contidions of use
                </span>
              </p>
              <p className="text-sm mb-3 font-normal">
                "Thank you for shopping with us! To place your order, please
                review your items in the cart, ensure your shipping and billing
                information is accurate, and select your preferred payment
                method. Once everything is confirmed, click the 'Place Order'
                button.{" "}
              </p>
            </div>
          </div>
        </div>
      ),
    },
    {
      key: "3",
      label: "3 Shipping",
      content: (
        <div>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">Shipping Carrier</FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="female"
              name="radio-buttons-group"
            >
              {
                !carriersLoading && carriers.map((cdata) => {
                  return (
                    <FormControlLabel
                      key={cdata?.code}
                      value={cdata?.code}
                      control={<Radio disabled={selectedCarrier !== ""} onChange={(event) => handleRadioChange(event, cdata)} />}
                      label={cdata?.name}
                    />
                  );
                })
              }

            </RadioGroup>
            {
              selectedCarrier !== "" && (
                <div className="flex items-center justify-center">
                  <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#009F7F]"></div>
                </div>
              )
            }

          </FormControl>
          {
            showRates &&
            (
              carrierrates.length > 0 ? (

                <p className="mx-6 border border-[#FBD8B4] mt-2 py-2 mb-2 bg-[#FCF5EE] rounded-lg px-10 text-sm font-normal">
                  <span className="font-bold">Select Shipment Priority</span>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    name="radio-buttons-group"
                  >
                    {
                      carrierrates.map((cdata) => {
                        return (
                          <FormControlLabel
                            key={cdata?.shipmentCost}
                            value={cdata?.shipmentCost}
                            control={<Radio onChange={(event) => { setShippingCharges(cdata?.shipmentCost); setServiceCode(cdata?.serviceCode) }} />}
                            label={`${cdata?.serviceName} ($${cdata?.shipmentCost})`}
                          />
                        );
                      })
                    }

                  </RadioGroup>

                </p>
              )
                :
                (
                  <Alert severity="error">
                    Your selected carrier does not offer shipment in your desired address. Please select another carrier
                  </Alert>
                )
            )
          }


          <div className="border flex mt-4 rounded-lg">
            <div className="my-auto">
              <Button
                className="bg-[#009f7f] my-3 mx-1 sm:mx-5 rounded-lg hover-custom-btn"
                onClick={handleOk}
                disabled={shippingCharges <= 0}
              >
                <p className="font-bold px-1 sm:px-4 text-white sm:py-2 text-[10px] sm:text-sm">
                  confirm shipping
                </p>
              </Button>{" "}
            </div>
            <div className="w-[70%]">
              <div className="flex mt-3 text-[#B12704] mb-1 font-bold text-xl px-4">
                <p>Shipping Charges: </p>
                <p>
                  ${shippingCharges}

                </p>
              </div>
              <p p className="text-sm mb-2">
                Shipping charges and delivery time may vary for each carrier{" "}
              </p>

            </div>
          </div>
        </div>
      ),
    },
    {
      key: "4",
      label: "4 Choose a payment method",
      content: (
        loading ?
          (
            <div className="flex items-center justify-center">
              <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#009F7F]"></div>
            </div>
          ) :

          (
            <div className="border rounded-lg">
              <p className="mx-6 border-b mt-4 pb-1">Pay E-Wallet Balance</p>
              <div className="flex text-xs py-2 mx-6">
                <p>Your E-wallet Balance</p>
                <p className="font-semibold sm:ml-8 ml-2  text-green-600">{address.current_balance}</p>
              </div>
              <div className="flex cursor-pointer gap-2 mt-4 mb-6 mx-6">
                <FaPlus className="text-[#DDDDDD]" />
                {/* <img src="/icon/dabit.png" className="w-8" alt="Debit Card" /> */}
                <img src="/icon/logo.png" className="w-8" alt="Debit Card" />
                <p onClick={onPlaceOrderClick} className="text-sm font-normal text-[#009DC5] hover:underline hover:text-red-500 cursor-pointer">
                  E-Wallet Balance
                </p>
              </div>
              <p className="mx-6 border-b mt-4 pb-1">Pay with Crypto</p>
              <div className="flex cursor-pointer gap-2 mt-4 mb-6 mx-6">
                <FaPlus className="text-[#DDDDDD]" />
                <img src="/icon/matic.png" className="h-9" alt="Debit Card" />
                <img src="/icon/eth.png" className="h-7 mt-1" alt="Debit Card" />
                <p onClick={() => payWithCrypto()} className="text-sm font-normal text-[#009DC5] hover:underline hover:text-red-500 cursor-pointer pt-2">
                  {
                    payText
                  }
                </p>
              </div>
              <p className="mx-6 border-b p-1">Pay With Elevated Token (EVC)</p>

              <div className="flex cursor-pointer gap-2 mt-4 mb-12 mx-6">
                <FaPlus className="text-[#DDDDDD]" />
                <MuiButton
                  onClick={() => openEvmModal()}
                  startIcon={<img src={process.env.PUBLIC_URL + "/icon/mm.png"} style={{ height: '30px' }} />}
                  color="success"
                  variant="contained"
                  size="small"
                  sx={{ borderRadius: '50px' }}
                >
                  Pay Using ($EVC)

                </MuiButton>
              </div>
              <p className="mx-6 border-b p-1">Pay Credit Card</p>

              <div className="flex flex-col sm:flex-row cursor-pointer text-sm font-normal gap-2 mt-4 mb-12 mx-6">
                <div className="flex items-start gap-2">
                  <FaPlus className="text-[#DDDDDD]" />
                  <img src="/icon/logo.png" className="w-8" alt="Debit Card" />
                </div>
                <div className="w-full col-span-2">
                  <CreditCard loading={loading} address={address} onSubmit={handleCreditCardData} />
                </div>
              </div>
              <div className="bg-[#F0F2F2">
                {/* <button
              className="bg-[#009f7f] my-3 mx-5 rounded-lg"
              onClick={handleOk}
            >
              <p className="font-bold px-3 text-white py-1 text-sm">
                Use this Payment method
              </p>
            </button> */}
              </div>
              {activePanel === "2" && (
                <p className="text-center text-xs mb-1 px-1">
                  Choose a payment method to continue checking out. You'll still
                  have a chance to review and edit your order before it's final.
                </p>
              )}
            </div>
          )
      ),
    },
  ];
  return (
    <>
      <div className="flex flex-col px-3 h-full">
        <div className="lg:col-span-">
          {
            openEvm && (
              <Dialog
                open={openEvm}
                keepMounted
                onClose={handleCloseEvm}
                aria-describedby="alert-dialog-slide-description"
                style={{ borderRadius: '50px' }}

              >
                <DialogTitle sx={{ textAlign: 'center' }}>{isConnected ? 'Pay Using $EVC' : 'Connect Wallet'}</DialogTitle>
                <DialogContent>
                  <div style={{ textAlign: 'center' }}>
                    <Web3ModalProvider totalCartAmount={(orderTotalAmount + shippingCharges).toFixed(2)} />

                  </div>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseEvm} size="small" color="warning">Close</Button>
                </DialogActions>
              </Dialog>
            )
          }
          <Collapse
            accordion
            expandIcon={() => null}
            ghost
            activeKey={activePanel}
            onChange={(key) => handleOkPanel(key)}
          // onChange={(key) => setActivePanel(key)}
          >
            {items.map((item) => (
              <Collapse.Panel
                key={item.key}
                header={item.label}
                className="border-b font-bold text-xl bg-white"
              >
                {item.content}
              </Collapse.Panel>
            ))}
          </Collapse>
          <div className="border rounded-xl pb-2">
            <div className="items-center justify-center border-r flex">
              { (activePanel === '1' || activePanel === '2' || activePanel === '3' || activePanel === '4') &&
                <Button className="bg-[#009f7f] my-3 rounded-lg hover-custom-btn" disabled={activePanel == "3" && shippingCharges <= 0} onClick={handleOk}>
                  <p className="font-bold text-white px-8 py-1.5 text-sm">
                    {activePanel == "1" && "Use this address"}
                    {activePanel == "2" && "confirm products"}
                    {activePanel == "3" && "confirm shipping"}
                    {activePanel == "4" && "Payment method"}
                  </p>
                </Button>
              }
            </div>
            <p className="text-center text-xs mb-1 px-1">
              {activePanel == "1" && (
                <>
                  Choose a shipping address to continue checking out.
                </>
              )}
              {activePanel == "4" && (
                <>
                  <div className="flex justify-between text-base p-2">
                    <p>Your E-wallet Balance</p>
                    <p className="font-semibold">{address.current_balance}</p>
                  </div>
                  Choose a payment method to continue checking out. You'll still
                  have a chance to review and edit your order before it's final.
                </>
              )}
            </p>
            <hr />
            <h1 className="text-lg font-bold mt-2 ml-2">Order Summary</h1>
            <div className="flex mt-2 justify-between px-4">
              <p>Total Items:</p>
              <p>{cart.length}</p>
            </div>
            <div className="flex mt-2 justify-between px-4">
              <p>Products Amount:</p>
              <p>${orderTotalAmount}</p>
            </div>
            <div className="flex mt-2 justify-between px-4">
              <p>Shipping Charges:</p>
              <p>${shippingCharges}</p>
            </div>
            <div className="flex mt-3 text-[#B12704] mb-1 font-semibold text-xl justify-between px-4">
              <p>Order total:</p>
              <p>
                $
                {(+orderTotalAmount + +shippingCharges).toFixed(2)}
              </p>
            </div>
            <hr />
            <p className="text-center text-xs pb-1 bg-[#F0F2F2] px-1">
              We're dedicated to delivering quality and satisfaction with every order.
            </p>
          </div>
        </div>
        {/* <div className="lg:col-span-1 order-last md:mt-[20%] lg:order-none rounded-lg">
          <div className="border rounded-xl">
            <div className="items-center justify-center border-r flex">
              <button className="bg-[#009f7f] my-3 rounded-lg" disabled={activePanel == "3"} onClick={handleOk}>
                <p className="font-bold text-white px-8 py-1.5 text-sm">
                  {activePanel == "1" && "Use this address"}
                  {activePanel == "2" && "confirm products"}
                  {activePanel == "3" && "Payment method"}
                </p>
              </button>
            </div>
            <p className="text-center text-xs mb-1 px-1">
              {activePanel == "1" && (
                <>
                  Choose a shipping address to continue checking out.
                </>
              )}
              {activePanel == "3" && (
                <>
                  <div className="flex justify-between text-base p-2">
                    <p>Your E-wallet Balance</p>
                    <p className="font-semibold">{address.current_balance}</p>
                  </div>
                  Choose a payment method to continue checking out. You'll still
                  have a chance to review and edit your order before it's final.
                </>
              )}
            </p>
            <hr />
            <h1 className="text-lg font-bold mt-2 ml-2">Order Summary</h1>
            <div className="flex mt-2 justify-between px-4">
              <p>Items:</p>
              <p>{cart.length}</p>
            </div>
            <div className="flex mt-3 text-[#B12704] mb-1 font-semibold text-xl justify-between px-4">
              <p>Order total:</p>
              <p>
                $
                {cart.reduce((acc, item, index) => {
                  if (checkedItems[index]) {
                    return acc + item.totalPrice;
                  }
                  return acc;
                }, 0)}
              </p>
            </div>
            <hr />
            <p className="text-center text-xs pb-1 bg-[#F0F2F2] px-1">
            We're dedicated to delivering quality and satisfaction with every order.
            </p>
          </div>
        </div> */}
      </div>
      <AddressModal
        showModal={showModal}
        handleOk={handleOk}
        handleCancel={handleCancel}
        isModalOpen={isModalOpen}
        isEditMode={isEditMode}
        Address={address}
      />
      <div>{contextHolder}</div>
    </>
  );
};

export default Payment;
