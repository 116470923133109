import React, { useEffect, useState } from "react";
import { shopByName } from "../../backendServices/ApiCalls";
import { useLocation, useParams } from "react-router-dom";
import ProductCard from "../../components/ProductCard";
import DrawerRight from "../../components/DrawerRight";

export default function SingleShop() {
  let { pathname } = useLocation();
  let { shopname, id } = useParams();
  const [shop, setShop] = useState([]);
  const [shopProduct, setShopProduct] = useState([]);
  const [link, setLink] = useState("");
  const [bannerLink, setBannerLink] = useState("");
  const [logoLink, setLogoLink] = useState("");

  // console.log('pathname=>', pathname.split('/').filter(Boolean).join('/'))
  // console.log('shopname', shopname)
  // console.log('shopname id', id)

  useEffect(() => {
    let param = {
      id,
      shopname: shopname?.replace(/-/g, " ")
    };
    console.log('param', param)
    shopByName(
      param,
      async (response) => {
        if (response?.data?.status === "success") {
          // console.log("response?.data?.data", response?.data?.data);
          // console.log("response?.data?.data", response?.data?.shopProduct);
          setShop(response?.data?.data);
          setLink(response?.data?.link);
          setBannerLink(response?.data?.banner_link);
          setLogoLink(response?.data?.logo_link);
          setShopProduct(response?.data?.shopProduct)
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  }, []);
  return (
    <>
      <div className="">
        <img
          src={bannerLink + shop[0]?.store_banner_image}
          className="w-[80%] mx-auto"
          alt=""
        />
        <div className="bg-white rounded p-4 sm:p-7 my-4">
          <div className="flex flex-col-reverse sm:flex-row mx-8 justify-between">
            <div>
              <h1 className="text-2xl sm:text-4xl font-bold py-5">{shop[0]?.store_name}</h1>
              <p className="font-semibold text-md sm:text-lg">{shop[0]?.tags}</p>
            </div>
            <img src={logoLink + shop[0]?.store_logo} className="w-32" alt="" />
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: shop[0]?.store_desc }}
            className="mt-2 mx-8 text-xs sm:text-md"
          />
        </div>
        {/* <div className="h-2"></div> */}
        <div className="grid p-6 sm:grid-cols-2 md:grid-cols-2 gap-4 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5  grid-cols-1">
          {shopProduct.map((product, index) => (
            <ProductCard
              key={index}
              // discount={product.discount}
              id={product?.id}
              imgSrc={product?.picture}
              link={link}
              title={product?.title}
              // weight={product?.weight}
              originalPrice={product?.price}
              description={product?.description}
              storeName={product?.store_name}
              weight={product?.weight}
            // discountedPrice={product.discountedPrice}
            />
          ))}
        </div>
      </div>

      <DrawerRight/>
    </>
  );
}
