import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import { UserContext } from '../../contexts/UserContext';
import { postRequest } from '../../backendServices/ApiCalls';
import { Input, notification, Select } from 'antd';
import countryList from 'react-select-country-list';
import { json, useNavigate } from 'react-router-dom';
import AddShop from '../seller/AddShop';

// Define your steps
const steps = ['Basic Information', 'Shop Address', 'Create Shop'];

// Main Component
export default function HorizontalLinearStepper() {
  const nav = useNavigate()
  const [api, contextHolder] = notification.useNotification();
  const successNotification = (placement, message, description) => {
    api.success({ message, description, placement });
  };
  const errorNotification = (placement, message, description) => {
    api.error({ message, description, placement });
  };
  const [activeStep, setActiveStep] = React.useState(0);
  const [formData, setFormData] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  // const { user } = React.useContext(UserContext);

  React.useEffect(() => {
    postRequest("/getProfile", '', async (response) => {
      // console.log("response?.data profile", response?.data?.data[0]);
      if (response?.data?.status === "success") {
        setLoading(false)
        const { username, email, mobile, firstname, lastname, birth_date, address, city, country, state, zipCode } = response?.data?.data[0];
        setFormData({ username, email, mobile, firstname, lastname, birth_date, address, city, country, state, zipcode: zipCode, });
        sessionStorage.setItem("sellerCheck", JSON.stringify({ username, email, mobile, firstname, lastname, birth_date, address, city, country, state, zipcode: zipCode, }))
      }
    },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  }, []);

  const areObjectsEqual = (obj1, obj2) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  };

  const handleNext = () => {
    if (activeStep === 0) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    if (activeStep === 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      const responseData = JSON.parse(sessionStorage.getItem("sellerCheck"));
      if (areObjectsEqual(responseData, formData)) {
        // console.log('The objects are the same.');
      } else {
        // console.log('The objects are different.');
        // console.log('Form Data:', formData);
        postRequest("/sellerDataUpdate", formData, async (response) => {
          // console.log("response?.data profile", response?.data);
          if (response?.data?.status === "success") {
            successNotification("topRight", "Success", response?.data?.message);
            sessionStorage.setItem("sellerCheck", JSON.stringify(formData))
          }
        },
          (error) => {
            console.log(error?.response?.data);
          }
        );
      }
    }
    if (activeStep === 2) {
      // console.log('Finish')
      postRequest("/registerSeller", '', async (response) => {
        // console.log("response?.data", response?.data);
        if (response?.data?.status === "success") {
          successNotification('topRight', 'Success', response?.data?.message)
          setTimeout(() => {
            nav("/")
          }, 1000);
        }
        if (response?.data?.status === "error") {
          errorNotification('topRight', 'Error', response?.data?.message)
        }
      },
        (error) => {
          console.log(error?.response?.data);
          setLoading(false);
        }
      );
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleFormChange = (newData) => {
    setFormData((prevData) => ({
      ...prevData,
      ...newData,
    }));
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <BasicInformation formData={formData} onFormChange={handleFormChange} />;
      case 1:
        return <Address formData={formData} onFormChange={handleFormChange} />;
      case 2:
        return <AddShop />;
      default:
        return 'Unknown step';
    }
  };

  return (
    <div className='min-h-[100vh] h-ful flex flex-col bg-white'>
      {contextHolder}
      <div className='sm:w-[80%] mx-auto'>
        <div className='flex items-center justify-center gap-x-3 mb-5'>
          <img src="/icon/logo.png" className='size-8 lg:size-10' alt="Logo" />
          <h2 className="text-2xl font-semibold text-gray-800 text-center">Elevated Seller Central</h2>
        </div>
        <Box sx={{ width: '80%', m: 'auto' }}>
          <Stepper
            activeStep={activeStep}
            sx={{
              '& .MuiStep-root': {
                '& .Mui-completed': { color: '#009f7f' }, // color for completed steps
                '& .Mui-active': { color: '#009f7f' }, // color for active step
                '& .Mui-disabled': { color: 'gray' }, // color for disabled steps
              },
              '& .MuiStepLabel-label': {
                color: '#009f7f' // color for step labels
              },
            }}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            <Box sx={{ mt: 2, mb: 1 }}>
              {loading ? <div className="flex items-center justify-center">
                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#009F7F]"></div>
              </div>
                : getStepContent(activeStep)}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1, color: 'gray' }}
              >
                Back
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button
                onClick={handleNext}
                sx={{ color: '#009f7f' }}
              >
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              </Button>
            </Box>
          </React.Fragment>
        </Box>
      </div>
    </div>
  );
}



const BasicInformation = ({ formData, onFormChange }) => {
  const { username, email, mobile, firstname, lastname, birth_date } = formData;

  const handleChange = (field, value) => {
    onFormChange({ [field]: value });
  };

  return (
    <div className='w-[90%] mx-auto'>
      <div className="grid gap-2">
        <div className="">
          <input
            type="text"
            name="username"
            value={username || ''}
            disabled
            placeholder="Username"
            className="w-full p-2 border border-gray-300 rounded mt-2 focus:outline-none focus:border-[#009F7F]"
          />
        </div>
        <div className="">
          <input
            type="email"
            name="email"
            value={email || ''}
            disabled
            placeholder="Email"
            className="w-full p-2 border border-gray-300 rounded mt-2 focus:outline-none focus:border-[#009F7F]"
          />
        </div>
        <div className="flex space-x-4">
          <input
            type="text"
            name="firstname"
            value={firstname || ''}
            placeholder="First Name"
            className="w-1/2 p-2 border border-gray-300 rounded mt-2 focus:outline-none focus:border-[#009F7F]"
            onChange={(e) => handleChange('firstname', e.target.value)}
          />
          <input
            type="text"
            name="lastname"
            value={lastname || ''}
            placeholder="Last Name"
            className="w-1/2 p-2 border border-gray-300 rounded mt-2 focus:outline-none focus:border-[#009F7F]"
            onChange={(e) => handleChange('lastname', e.target.value)}
          />
        </div>
        <div className="flex space-x-4">
          <input
            type="text"
            name="mobile"
            value={mobile || ''}
            placeholder="Phone Number"
            className="w-full p-2 border border-gray-300 rounded mt-2 focus:outline-none focus:border-[#009F7F]"
            onChange={(e) => handleChange('mobile', e.target.value)}
          />
          <input
            type="date"
            name="birth_date"
            value={birth_date || ''}
            className="w-full p-2 border border-gray-300 rounded mt-2 focus:outline-none focus:border-[#009F7F]"
            onChange={(e) => handleChange('birth_date', e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};




const Address = ({ formData, onFormChange }) => {
  const options = countryList().getData();
  const { country, address, city, state, zipcode } = formData;

  const handleCountryChange = (value) => {
    onFormChange({ country: value });
  };

  const handleInputChange = (field, value) => {
    onFormChange({ [field]: value });
  };

  const customSelectStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? '#009f7f' : '#ccc',
      '&:hover': {
        borderColor: '#009f7f',
      },
      boxShadow: state.isFocused ? '0 0 0 1px #009f7f' : 'none',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#009f7f' : state.isFocused ? '#e6f7f4' : null,
      color: state.isSelected ? '#fff' : '#333',
      '&:hover': {
        backgroundColor: '#e6f7f4',
        color: 'black',
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'black',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#999',
    }),
  };

  return (
    <div className="w-full">
      <div>
        <div className="mt-6">
          <p className='font-semibold text-sm mb-1'>Country/Region</p>
          <Select
            className='inputColorForAddress outline-0 hover:border-[#009f7f] w-full'
            options={options}
            value={country || ''}
            onChange={handleCountryChange}
            placeholder="Select a country"
            styles={customSelectStyles}
          />
        </div>
        <div className="mt-4">
          <p className='font-semibold text-sm mb-1'>Address</p>
          <Input
            placeholder="Street address or P.O. Box"
            className='inputColorForAddress outline-0 hover:border-[#009f7f] mb-1'
            value={address || ''}
            onChange={(e) => handleInputChange('address', e.target.value)}
          />
        </div>
        <div className="mt-4 flex gap-4">
          <div>
            <p className='font-semibold text-sm mb-1'>City</p>
            <Input
              placeholder="E.g New York"
              className='inputColorForAddress outline-0 hover:border-[#009f7f]'
              value={city || ''}
              onChange={(e) => handleInputChange('city', e.target.value)}
            />
          </div>
          <div>
            <p className='font-semibold text-sm mb-1'>State</p>
            <Input
              placeholder="State"
              className='inputColorForAddress outline-0 hover:border-[#009f7f]'
              value={state || ''}
              onChange={(e) => handleInputChange('state', e.target.value)}
            />
          </div>
          <div>
            <p className='font-semibold text-sm mb-1'>ZIP Code</p>
            <Input
              placeholder="5-digit-Zipcode"
              className='inputColorForAddress outline-0 hover:border-[#009f7f]'
              value={zipcode || ''}
              onChange={(e) => handleInputChange('zipcode', e.target.value)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

