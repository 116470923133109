import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { postRequest } from "../../backendServices/ApiCalls";
import useStore from "../../contexts/store";
import DrawerRight from "../../components/DrawerRight";
import { Rate } from "antd";
import { FaRegImage } from "react-icons/fa6";
import { notification } from "antd";

export default function ProductDetail({ average_star, reviewusers }) {
  const [products, setProduct] = useState([]);
  const [hoveredImage, setHoveredImage] = useState(null);
  const [picturelink, setPictureLink] = useState([]);
  const [imageLinks, setImageLinks] = useState([]);
  const [error, setError] = useState("");
  const [errorQuantity, setErrorQuantity] = useState("");
  const [selectedSize, setSelectedSize] = useState("");
  const LocationParam = useParams();
  const [review, setReview] = useState([]);
  const [averageStar, setAverageStar] = useState("");
  const [link, setLink] = useState();
  const [api, contextHolder] = notification.useNotification();
  const [suggestProduct, setSuggestProduct] = useState([]);
  
  const addToCart = useStore((state) => state.addToCart);
  const cart = useStore((state) => state.cart);
  const isInCart = cart.some((item) => item.id === products?.id);
  const successNotification = (placement, message, description) => {
    api.success({ message, description, placement });
  };
  const errorNotification = (placement, message, description) => {
    api.error({ message, description, placement });
  };

  const [isImageLoading, setIsImageLoading] = useState(true);
  const handleImageLoad = () => {
    setIsImageLoading(false);
  };

  const productsCatagory = () => {
    const param = {
      category: products.category,
      limit: 4,
      offset: 0
    };
    // console.log('param', param)
    postRequest("/getProductCategoryCheckout", param, async (response) => {
      if (response?.data?.status === "success") {
        setSuggestProduct(response?.data?.data);
        // console.log('response?.data?.data', response?.data?.data)
        setLink(response?.data?.link);
      }
    },
      (error) => {
        console.log(error?.response?.data);
      });
  };

  useEffect(() => {
    productsCatagory();
  }, [products]);

  const img = (imgSrc) => {
    let imageNames = [];
    if (imgSrc) {
      try {
        const pictureData = JSON.parse(imgSrc);
        return (imageNames = pictureData.imageNames[0]);
      } catch (e) {
        console.error("Invalid JSON in item.picture", e);
      }
    }
  };
  const [formData, setFormData] = useState({
    quantity: "1",
  });
  // console.log(LocationParam.id)
  const productsFun = () => {
    let param = {
      id: LocationParam.id,
    };
    postRequest(
      "/getProductById",
      param,
      async (response) => {
        // setLoading(true);
        // console.log("response?.data", response?.data?.data[0]);
        if (response?.data?.status === "success") {
          setProduct(response?.data?.data[0]);
          setPictureLink(response?.data?.link);

          const imagesData = JSON.parse(response?.data?.data[0]?.picture);
          // console.log('imagesData', imagesData)
          const imageNames = imagesData.imageNames;
          const imageLinksArray = imageNames.map(
            (imageName) => `${response?.data?.link}${imageName}`
          );
          // console.log('imageLinksArray', imageLinksArray)
          setImageLinks(imageLinksArray);
          // successNotification('topRight', 'Success', response?.data?.message)
          // setLoading(false);
        }
        if (response?.data?.status === "error") {
          // errorNotification('topRight', 'Error', response?.data?.message)
          // setLoading(false);
        }
      },
      (error) => {
        console.log(error?.response?.data);
        // setLoading(false);
      }
    );
  };
  useEffect(() => {
    productsFun();
    getReview();
  }, []);


  const handleAddToCart = () => {
    const { id, title, price, shopid, category, quantity, weight } = products;
    // console.log('price', price, typeof (price))
    if (selectedSize || !products?.size) {
      addToCart({
        id,
        title,
        originalPrice: price,
        image: imageLinks[0],
        shopid,
        category,
        selectedSize,
        weight,
        productQuantity: quantity,
      });
    } else {
      setError("Please select a size.");
    }
  };
  const handleSizeSelect = (size) => {
    setSelectedSize(size);
    setError("");
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (value > products?.quantity) {
      setErrorQuantity("We don't have enough stock.");
    } else {
      setErrorQuantity("");
    }
  };
  const getReview = () => {
    let param = {
      productid: LocationParam.id,
    };
    // console.log('param', param)
    postRequest(
      "/getProductReviews",
      param,
      async (response) => {
        // setLoading(true);
        // console.log("response?.data", response?.data);
        if (response?.data?.status === "success") {
          setReview(response?.data?.data);
          setAverageStar(response?.data?.averageStar[0]?.average_star);
          // successNotification('topRight', 'Success', response?.data?.message)
          // setLoading(false);
        }
        if (response?.data?.status === "error") {
          // errorNotification('topRight', 'Error', response?.data?.message)
          // setLoading(false);
        }
      },
      (error) => {
        console.log(error?.response?.data);
        // setLoading(false);
      }
    );
  };
  return (
    <>
      <div className="grid grid-rows-1 gap-y-2 sm:grid-cols-2 gap-x-2">
        <div className="flex flex-col gap-y-2 items-center sm:items-start sm:flex-row">
          <div className="sm:w-1/4 lg:w-1/12 h-full">
            <div className="flex sm:flex-col gap-3 h-full">
              {imageLinks.map((imageUrl, index) => (
                <div key={index} className="w-4/5 ">
                  <img
                    onLoad={handleImageLoad}
                    className={`rounded ${
                      hoveredImage === imageUrl
                        ? "border-2 border-green-600"
                        : ""
                    }`}
                    style={{ cursor: "pointer" }}
                    onMouseEnter={() => setHoveredImage(imageUrl)}
                    src={imageUrl}
                    // alt={`Thumbnail ${index + 1}`}
                  />
                  {isImageLoading && (
                    <div className="custom-skeleton rounded w-full h-full flex items-center justify-center">
                      <FaRegImage color="#BFBFBF" size={50} />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className="sm:w-3/4 lg:w-full">
            <img
              onLoad={handleImageLoad}
              className="rounded mb-3 custom-width custom-height object-cover"
              src={hoveredImage || (imageLinks.length > 0 ? imageLinks[0] : "")}
              // alt="Main"
            />
            {isImageLoading && (
              <div className="custom-skeleton rounded w-72 h-80 flex items-center justify-center">
                <FaRegImage color="#BFBFBF" size={70} />
              </div>
            )}
            {/* <style>
                        {`
        @media screen and (max-width: 992px) {
          .custom-width {
            width: 100%;
          }
        }
        .custom-height {
          height: 20rem;
        }
        @media screen and (max-width: 992px) {
          .custom-height {
            height: auto;
          }
        }
      `}
                    </style> */}
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
          <div>
            <Link
              to={`/shop/${products?.store_name?.replace(/\s/g, "-")}/${
                products?.shopid
              }`}
              className="text-[#009f7f] hover:text-[#009f7f]"
            >
              {products?.store_name}
            </Link>
            <h1 className="text-lg font-semibold">
              {products?.title?.replace(/\\/g, "")}
            </h1>
            <div className="flex gap-x-5 border-b border-gray-500 pb-2">
              <Rate
                allowHalf
                disabled
                value={Math.ceil(averageStar * 10) / 10}
              />
              <p className="text-[#009f7f]">{reviewusers}</p>
            </div>
            
            <div className="flex my-2 border-b border-gray-500 pb-2">
              <span>$</span>
              <span className="text-sm font-semibold text-accent md:text-2xl">
                {products?.price}
              </span>
            </div>
            <div
            className="border-b border-gray-500 pb-2"
              dangerouslySetInnerHTML={{
                __html: products?.description?.replace(/\\/g, ""),
              }}
            />
            {/* {products?.size !== 'undefined' && products?.size !== null ? (
                            <div className="mt-4">
                                <label className="block mb-1 font-semibold text-body-dark">Select Size</label>
                                <div className="flex space-x-2">
                                    {products?.size?.split(",").map((s) => (
                                        <button
                                            key={s}
                                            onClick={() => handleSizeSelect(s)}
                                            className={`px-4 py-2 border rounded ${selectedSize === s ? "bg-[#009f7f] text-white" : "bg-white text-[#009f7f]"} transition-colors duration-300`}
                                        >
                                            {s}
                                        </button>
                                    ))}
                                </div>
                            </div>
                        ) : ('')}
                        {error && <p className="text-red-500">{error}</p>} */}
            {/* <button onClick={handleAddToCart} disabled={isInCart} className="bg-[#009f7f] mt-5 px-5 rounded">
                        <p className="text-md font-semibold text-white py-1 ">{isInCart ? " In Cart" : " Add to Cart"}</p>
                    </button> */}
          </div>
          <div className="border rounded-md p-4 mb-2">
            <div className="flex my-2">
              <span>$</span>
              <span className="text-sm font-semibold text-accent md:text-2xl">
                {products?.price}
              </span>
            </div>
            {products?.size !== "undefined" && products?.size !== null ? (
              <div className="mt-4">
                <label className="block mb-1 font-semibold text-body-dark">
                  Select Size
                </label>
                <div className="flex flex-wrap items-center space-x-2">
                  {products?.size?.split(",").map((s) => (
                    <button
                      key={s}
                      onClick={() => handleSizeSelect(s)}
                      className={`px-2 border rounded ${
                        selectedSize === s
                          ? "bg-[#009f7f] text-white"
                          : "bg-white text-[#009f7f]"
                      } transition-colors duration-300`}
                    >
                      {s}
                    </button>
                  ))}
                </div>
              </div>
            ) : (
              ""
            )}
            {error && <p className="text-red-500">{error}</p>}
            <div>
              <label className="block mb-1 mt-4 font-semibold text-body-dark">
                Quantity*
              </label>
              <input
                type="number"
                id="quantity"
                name="quantity"
                min="1"
                value={formData.quantity}
                onChange={handleChange}
                className="border border-gray-300 rounded-md px-1 w-[50%] focus:outline-none focus:border-[#009f7f]"
              />
              {errorQuantity && <p className="text-red-500">{errorQuantity}</p>}
            </div>
            <button
              onClick={handleAddToCart}
              disabled={isInCart}
              className="bg-[#009f7f] mt-5 px-5 rounded"
            >
              <p className="text-md font-semibold text-white py-1 ">
                {isInCart ? " In Cart" : " Add to Cart"}
              </p>
            </button>
            <div className="text-xs py-4 flex flex-col gap-2">
              <div className="flex justify-between">
                <p>Ships from</p>
                <p className="font-semibold">Elevated Marketplace</p>
              </div>
              <div className="flex justify-between">
                <p>Sold by</p>
                <Link
                  to={`/shop/${products?.store_name?.replace(/\s/g, "-")}/${
                    products?.shopid
                  }`}
                  className="text-[#009f7f] hover:text-[#009f7f]"
                >
                  {products?.store_name}
                </Link>
              </div>
              <div className="flex justify-between">
                <p>Packaging</p>
                <p className="font-semibold text-[#009f7f]">
                  Ships in product <br /> packaging
                </p>
              </div>
              <div className="flex justify-between">
                <p>Payment</p>
                <p className="font-semibold text-[#009f7f]">
                  Secure transaction
                </p>
              </div>
              <div className="flex justify-between">
                <p>Customer Service</p>
                <Link
                  to={`/shop/${products?.store_name?.replace(/\s/g, "-")}/${
                    products?.shopid
                  }`}
                  className="text-[#009f7f] hover:text-[#009f7f]"
                >
                  {products?.store_name}
                </Link>
              </div>
            </div>
          </div>
        </div>
      
      </div>
      <p className="text-xl font-semibold mt-10">Related Products</p>
      <div className="grid py-6 gap-2 grid-cols-2 sm:grid-cols-4 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4">
        {suggestProduct.map((item, i) => (
              <div
              key={i}
              className="grid grid-row-3 sm:grid-row-1 border-b gap-y-2 py-3"
            >
              <Link to={`/productdetail/${item?.id}`} target="_blank" className="row-span-1 flex justify-center items-center">
                <img
                  src={link + img(item.picture)}
                  alt="same products"
                  className="w-24 h-24 mt-1"
                />
              </Link>
              <div className="row-span-2 px-2">
                <Link to={`/productdetail/${item?.id}`} target="_blank" className="truncate-3-lines h-[4.5rem] hover:text-[#009f7f]">{item?.title?.replace(/\\/g, '')}</Link>
                <div className="text-center">
                <p className="font-semibold mx-2 text-xl text-[#009f7f]">
                  ${item.price}
                </p>
                <button
                  disabled={cart.some((it) => it.id === item?.id)}
                  onClick={() => {
                    successNotification('topRight', 'Success', "Product Added");
                    addToCart({
                      id: item?.id,
                      title: item?.title,
                      originalPrice: item?.price,
                      image: link + img(item.picture),
                      shopid: item?.shopid,
                      category: item?.category,
                    })
                  }}
                  data-variant="normal"
                  className="inline-flex text-white bg-[#009F7F] items-center justify-center shrink-0 font-semibold leading-none rounded outline-none transition duration-300 ease-in-out focus:outline-0 focus:shadow focus:ring-1 focus:ring-accent-700 bg-accent text-light border border-transparent hover:bg-[#009f77] h-8 mt-2 w-[70%]"
                >
                  {cart.some((it) => it.id === item?.id) ? " In Cart" : " Add to Cart"}
                </button>
                </div>
              </div>
            </div>
            ))}
        </div>
        <div>  {review.length > 0 && (
          <div className="my-5">
            <h2 className="text-xl font-semibold">Top reviews</h2>
            {review.map((item, i) => (
              <div className="flex flex-col gap-y-2 border-b py-3" index={i}>
                <div className="flex items-center gap-x-2">
                  <img
                    src="/icon/profile.svg"
                    className="w-10 h-10 my-2  shadow-xl rounded-full"
                    alt="Profile Logo"
                  />
                  <p className="text-base font-semibold">{item.username}</p>
                </div>
                <Rate allowHalf value={item?.star} />
                <p>{item?.review}</p>
              </div>
            ))}
          </div>
        )}</div>
      <div>{contextHolder}</div>
      <DrawerRight />
    </>
  );
}
