import React, { useState, useEffect, useRef, useContext } from "react";
import { PiPackageDuotone, PiShoppingCartSimpleDuotone } from "react-icons/pi";
import { CgMenuLeft } from "react-icons/cg";
import { IoIosSearch } from "react-icons/io";
import { AppstoreOutlined } from "@ant-design/icons";
import { FaListOl } from "react-icons/fa6";

import { Button, Layout, Menu, notification, Popover, Tooltip } from "antd";
import Sider from "antd/es/layout/Sider";
import { Content } from "antd/es/layout/layout";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BsShop } from "react-icons/bs";
import { UserContext } from "../contexts/UserContext";
import { MdLogout } from "react-icons/md";
import { Dropdown, Space } from "antd";
import { postRequest } from "../backendServices/ApiCalls";
import { googleLogout } from "@react-oauth/google";
import GoogleTranslate from "../GoogleTranslate";
import { IoSearch } from "react-icons/io5";
import useStore from "../contexts/store";
import { GrLocation } from "react-icons/gr";
import LeftNavbar from "./LeftNavbar";
import LocationComponent from "./LocationComponent";

// const iconMapping = {
//   bakery: "/icon/bakery.svg",
//   makeup: "/icon/makeup.svg",
//   bags: "/icon/bags.svg",
//   clothing: "/icon/clothing.svg",
//   furniture: "/icon/furniture.svg",
//   "daily needs": "/icon/dailyneeds.svg",
//   books: "/icon/books.svg",
//   electronics: "/icon/electronics.svg",
//   medicine: "/icon/medicine.svg",
// };

const Navbar = ({ childComponent: ChildComponent }) => {
  const { user, setUser } = useContext(UserContext);
  // console.log('user in navbar', user)
  const [collapsed, setCollapsed] = useState(true);
  const [selectedKeys, setSelectedKeys] = useState(["1"]);
  const [defaultOpenKeys, setDefaultOpenKeys] = useState(["1"]);
  const [isMobile, setIsMobile] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDashboard, setIsDashboard] = useState(false);
  const [category, setCategory] = useState("all");
  const [address, setAddress] = useState([]);
  const [title, setTitle] = useState("");
  const location = useLocation();
  // console.log(location.pathname)
  const cart = useStore((state) => state.cart);
  const [categoryHook, setCategoryHook] = useState([]);
  const [suggestion, setSuggestion] = useState([]);
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const [open, setOpen] = useState(false);
  const [visible, setVisible] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const infoNotification = (placement, message, description) => {
    api.info({ message, description, placement, duration: 2 })
  };
  const errorNotification = (placement, message, description) => {
    api.error({ message, description, placement, duration: 2 })
  };
  const token = localStorage.getItem("token");
  const role = sessionStorage.getItem("role");

  const SellerCheck = () => {
    if (role == 'buyer') {
      if (user?.sellerApprove == 'pending') {
        infoNotification('topRight', 'Please Wait', `You request is ${user?.sellerApprove}`)
      }
      if (user?.sellerApprove == 'rejected') {
        errorNotification('topRight', 'Sorry', `You request is ${user?.sellerApprove}`)
      }
    }
  }
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  // const handleMouseEnter = () => {
  //   setIsHovered(true);
  // };

  // const handleMouseLeave = () => {
  //   setIsHovered(false);
  // };
  const getData = () => {
    postRequest(
      "/getaddress",
      "",
      (response) => {
        // console.log('response?.data?.data', response?.data?.data[0])
        if (response?.data?.status === "success") {
          setAddress(response?.data?.data[0]);
          // successNotification("topRight", "Success", response?.data?.message);
        } else if (response?.data?.status === "error") {
          // errorNotification("topRight", "Error", response?.data?.message);
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };
  useEffect(() => {
    getData();
    const checkout_login_session = sessionStorage.getItem("checkout_login");
    // console.log('checkout_login_session', checkout_login_session)
    if (checkout_login_session) {
      navigate("/checkout");
      sessionStorage.removeItem("checkout_login");
    }
  }, []);
  const [checkedItems, setCheckedItems] = useState(() => {
    // Initialize checkedItems from localStorage or default to all true

    const savedCheckedItems = JSON.parse(localStorage.getItem("checkedItems"));
    if (savedCheckedItems) {
      return savedCheckedItems;
    } else {
      return {};
    }
  });
  useEffect(() => {
    // Initialize checkedItems state to mark all items as checked
    let initialCheckedState = {};
    cart.forEach((item, index) => {
      if (!checkedItems[index]) {
        initialCheckedState[index] = true; // Initialize unchecked items as false
      } else {
        initialCheckedState[index] = true; // Keep already checked items as true
      }
    });
    setCheckedItems(initialCheckedState);
  }, [cart]);
  const inputRef = useRef(null);
  // console.log("categoryHook", categoryHook);
  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setSuggestion([]);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const productCategory = () => {
    postRequest(
      "/productcategory",
      "",
      async (response) => {
        // setLoading(true);
        // console.log("response?.data", response?.data?.data);
        if (response?.data?.status === "success") {
          setCategoryHook(response?.data?.data);
          // const categories = response?.data?.data.map(item => ({
          //     ...item,
          //     name: item.name.toLowerCase().replace(/\s+/g, '-')
          // }));
          //   setLink(response?.data?.link);
          //   setLoading(false);
        }
      },
      (error) => {
        console.log(error?.response?.data);
        //   setLoading(false);
      }
    );
  };
  useEffect(() => {
    productCategory();
    const key = sessionStorage.getItem("selectedkey");
    if (key == null) {
      // console.log('key is null');
      setDefaultOpenKeys(["1"]);
      setSelectedKeys(["1"]);
    } else {
      // console.log('key is not null');
      setDefaultOpenKeys([key]);
      setSelectedKeys([key]);
    }
    // console.log('key on refresh', [key])
  }, []);

  const handleSearch = () => {
    if (title == "") {
      setSuggestion([]);
      return;
    }
    let param = {
      category,
      title,
    };
    // console.log('param', param)
    postRequest(
      "/getsearchproducts",
      param,
      async (response) => {
        if (response?.data?.status === "success") {
          setSuggestion(response?.data?.data);
          // navigate(`/search?category=${category}&title=${title}`);
          // console.log('response?.data?.data', response?.data?.data)
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };
  useEffect(() => {
    handleSearch();
  }, [title, category]);

  const handleSuggestionClick = () => {
    // console.log('title', title)
    let param = {
      category,
      title,
    };
    // console.log('param', param)
    navigate(`/search?category=${category}&title=${title}`);
    // navigate(`/s?url=${category}&field-keywords=${title}`);
    postRequest(
      "/getsearchproducts",
      param,
      async (response) => {
        if (response?.data?.status === "success") {
          // setSuggestion(response?.data?.data);
          setSuggestion([]);
          // console.log("response?.data?.data", response?.data?.data);
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  const handelSubmit = (event) => {
    event.preventDefault();
    setSuggestion([]);
    navigate(`/search?category=${category}&title=${title}`);
  };

  const itemsForSeller = [
    {
      key: "1",
      icon: <AppstoreOutlined />,
      label: (
        <Link
          onClick={() => isMobile && setCollapsed(!collapsed)}
          to="/dashboard/home"
        >
          Dashboard
        </Link>
      ),
    },
    {
      key: "sub1",
      label: "Lists",
      icon: <PiPackageDuotone />,
      children: [
        {
          key: "2",
          label: (
            <Link
              to="/dashboard/product"
              onClick={() => isMobile && setCollapsed(!collapsed)}
            >
              Add List
            </Link>
          ),
        },
        {
          key: "3",
          label: (
            <Link
              to="/dashboard/allproducts"
              onClick={() => isMobile && setCollapsed(!collapsed)}
            >
              All Lists
            </Link>
          ),
        },
      ],
    },
    {
      key: "sub2",
      label: "Shops",
      icon: <PiShoppingCartSimpleDuotone />,
      children: [
        {
          key: "4",
          label: (
            <Link
              to="/dashboard/shop"
              onClick={() => isMobile && setCollapsed(!collapsed)}
            >
              Add new shop
            </Link>
          ),
        },
      ],
    },
    {
      key: "5",
      icon: <FaListOl />,
      label: (
        <Link
          onClick={() => isMobile && setCollapsed(!collapsed)}
          to="/dashboard/orders"
        >
          Orders
        </Link>
      ),
    },
  ];

  const itemsForBuyer = [
    {
      key: "1",
      icon: <PiPackageDuotone className="mr-2 ml-1" size={20} />,
      label: (
        <Link onClick={() => isMobile && setCollapsed(!collapsed)} to="/">
          Home
        </Link>
      ),
    },

    ...categoryHook.map((category, index) => ({
      key: `${index + 2}`,
      icon: (
        <img
          // src={iconMapping[category.name.toLowerCase()]}
          src={`/icon/${category.icon}.svg`}
          alt={`${category.name} Icon`}
          className="mr-2 h-5"
        />
      ),
      label: (
        <Link
          onClick={() => isMobile && setCollapsed(!collapsed)}
          to={`/product/${category.name.toLowerCase().replace(/\s+/g, "-")}`}
        >
          {category.name}
        </Link>
      ),
    })),
  ];

  const itemsColumn1 = [
    {
      label: (
        <div
          className="text-lg  font-bold hover:text-black"
        >
          Your Lists
        </div>
      ),
      key: "0",
      className: "dropdown-item",
    },
    {
      label: (
        <Link
          // to={user ? "/dashboard/product" : "/login"}
          to={user ? "/sell" : "/login"}
          className="hover:text-[#0da889] text-md font-medium "
        >
          Create Lists
        </Link>
      ),
      key: "1",
      className: "dropdown-item",
    },
    {
      label: (
        <Link
          // to={user ? "/dashboard/product" : "/login"}
          to={user ? "/productsection" : "/login"}
          className="hover:text-[#0da889] text-md font-medium "
        >
          Request to List
        </Link>
      ),
      key: "1",
      className: "dropdown-item",
    },
    {
      label: (
        <Link
          // to={user ? "/dashboard/allproducts" : "/login"}
          to='/sell'
          className="hover:text-[#0da889]  text-md font-medium"
        >
          All Lists
        </Link>
      ),
      key: "2",
      className: "dropdown-item",
    },
    {
      label: (
        <Link to="/shops" className="hover:text-[#0da889]  text-md font-medium">
          View Stores
        </Link>
      ),
      key: "3",
      className: "dropdown-item",
    },
    {
      label: (
        <Link
          to={user ? "/purchasedProduct" : "/login"}
          className="hover:text-[#0da889]  text-md font-medium"
        >
          Purchased List
        </Link>
      ),
      key: "4",
      className: "dropdown-item",
    },
  ];

  const itemsColumn2 = [
    {
      label: (
        <div
          className="text-lg  font-bold hover:text-black"
        >
          Your Account
        </div>
      ),
      key: "0",
      className: "dropdown-item",
    },
    {
      label: (
        <Link
          to={user ? "/profile" : "/login"}
          className="hover:text-[#0da889] font-sans  text-md font-medium"
        >
          Profile
        </Link>
      ),
      key: "1",
      className: "dropdown-item",
    },

    {
      label: (
        <Link to="/" className="hover:text-[#0da889] text-md font-medium">
          MarketPlace
        </Link>
      ),
      key: "2",
      className: "dropdown-item",
    },
    {
      label: (
        // <Link
        //   to={user ? (user.seller == 0 ? "/sellerRegister" : role == 'buyer' ? "/" : "/dashboard/home") : "/login"}
        //   className="hover:text-[#0da889]  text-md font-medium"
        //   onClick={SellerCheck}
        // >
        //   Seller Dashboard
        // </Link>
        <Link
          // to={user ? (user.seller == 0 ? "/sellerRegister" : role == 'buyer' ? "/" : "/dashboard/home") : "/login"}
          // to={user ? (user.seller == 0 ? "/sell" : role == 'buyer' ? "/" : "/dashboard/home") : "/login"}
          to={user ? "/sell" : "/login"}
          className="hover:text-[#0da889]  text-md font-medium"
          onClick={SellerCheck}
        >
          Seller Dashboard
        </Link>
      ),
      key: "3",
      className: "dropdown-item",
    },
    user
      ? {
        label: (
          <span
            className="flex border-t hover:text-[#0da889] py-1 cursor-pointer text-md font-medium"
            onClick={() => {
              localStorage.removeItem("token");
              setUser(false);
              if (user.loginType === "google") {
                googleLogout();
              }
            }}
          >
            <MdLogout className="mt-1 mr-2 " /> Logout
          </span>
        ),
        key: "4",
        className: "dropdown-item",
      }
      : "",
  ];

  const handleMouseEnter = () => setVisible(true);
  const handleMouseLeave = () => setVisible(false);

  const content = (
    <div
      className="dropdown-menu w-full rounded-md gap-10 px-6 sm:px-10 mt-1 mx-auto bg-white"
    >
      {!user ? (
        <div className="py-4">
          <Link to="/login">
            <div className="w-[50%] rounded-md mb-4 py-1 font-semibold text-lg text-center text-white mx-auto bg-[#0da889]">
              Sign in
            </div>
          </Link>
          <hr />
        </div>
      ) : null}
      <div className="flex">
        <div
          className={`dropdown-column border-r pr-2 sm:pr-10 pl-4 ${user ? 'py-2' : ''
            }`}
        >
          {itemsColumn1.map((item) => (
            <div key={item.key} className={item.className}>
              {item.label}
            </div>
          ))}
        </div>
        <div
          className={`dropdown-column pl-2 sm:pl-10 ${user ? 'py-2' : ''
            }`}
        >
          {itemsColumn2.map((item) => (
            <div key={item.key} className={item.className}>
              {item.label}
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  const handleSearchClick = () => {
    if (isMobile) {
      setIsModalVisible(true);
    }
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };
  useEffect(() => {
    setIsDashboard(location.pathname.includes("/dashboard"));
    // console.log('isDashboard', isDashboard)
    setTitle('')
  }, [location.pathname]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  // useEffect(() => {
  //   if (isMobile) {
  //     setCollapsed(!collapsed);
  //   }
  // }, [isMobile]);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
    // console.log("collapsed", collapsed);
  };
  // console.log("isMobile", isMobile);

  const handleClick = (e) => {
    let key = [e.key];
    setSelectedKeys(key);
    sessionStorage.setItem("selectedkey", key);
    setDefaultOpenKeys(key);
  };

  useEffect(() => {
    const allPathsForSeller = itemsForSeller.flatMap((item) => {
      if (item.children) {
        return item.children.map((child) => child.label.props.to);
      }
      return item.label.props.to;
    });
    const allPathsForBuyer = [
      "/",
      ...categoryHook.map(
        (category) =>
          `/product/${category.name.toLowerCase().replace(/\s+/g, "-")}`
      ),
    ];
    const allPaths = [...allPathsForSeller, ...allPathsForBuyer];
    // console.log('allPaths', allPaths);

    let allPathLocal = localStorage.getItem("allPaths");
    if (allPathLocal !== null) {
      const allPathsGetLocal = allPathLocal.split(",");
      // console.log('allPathsGetLocal', allPathsGetLocal)
      // if (
      //   allPathsGetLocal == null ||
      //   allPathsGetLocal.length < allPaths.length
      // ) {
      localStorage.setItem("allPaths", allPaths);
      // }
    } else {
      localStorage.setItem("allPaths", allPaths);
    }
  }, [categoryHook]);

  useEffect(() => {
    let allPaths = localStorage.getItem("allPaths");
    // console.log('allPaths', allPaths)
    if (allPaths !== null) {
      allPaths = allPaths.split(",");
      // console.log('allPaths', allPaths)
      if (!allPaths.includes(location.pathname)) {
        setSelectedKeys([]);
      }
      // console.log(location.pathname == '/')
      if (location.pathname == "/") {
        sessionStorage.setItem("selectedkey", ["1"]);
        setDefaultOpenKeys(["1"]);
        setSelectedKeys(["1"]);
      }
    }
  }, [location.pathname]);

  // useEffect(() => {
  //   console.log('selectedKeys', selectedKeys);
  // }, [selectedKeys]);

  // useEffect(() => {
  //   console.log('DefaultOpenKeys', defaultOpenKeys);
  // }, [defaultOpenKeys]);

  const selectRef = useRef(null);
  const [width, setWidth] = useState("auto");

  const updateWidth = () => {
    const selectElement = selectRef.current;
    const selectedOption = selectElement.options[selectElement.selectedIndex];
    const dummyDiv = document.createElement("div");
    dummyDiv.style.position = "absolute";
    dummyDiv.style.visibility = "hidden";
    dummyDiv.style.whiteSpace = "nowrap";
    dummyDiv.style.font = window.getComputedStyle(selectElement).font;
    dummyDiv.textContent = selectedOption.textContent;
    document.body.appendChild(dummyDiv);
    setWidth(`${dummyDiv.clientWidth + 32}px`);
    document.body.removeChild(dummyDiv);
  };

  useEffect(() => {
    updateWidth();
  }, [category]);

  const formRef = useRef(null); // Ref for the form

  const handleFormBlur = (event) => {
    if (formRef.current && !formRef.current.contains(event.relatedTarget)) {
      // console.log('Focus moved outside the form');
      setSuggestion([])
      // Add any additional logic you want to execute when focus leaves the form
    }
  };

  const [visibleCopy, setVisibleCopy] = useState(false);

  const handleClickCopy = () => {
    navigator.clipboard.writeText(user?.referalcodeshop || '');
    setVisibleCopy(true);
    setTimeout(() => setVisibleCopy(false), 1000); // Hide tooltip after 1 seconds
  };

  return (
    <div className="">
      {contextHolder}
      <div className="fixed z-40 top-0 bg-white w-full">
        <nav className="px-2 sm:px-6 flex sm:justify-start overflow-x-hidden  items-center">
          <div
            // className={`flex py-2 ${isMobile ? "" : "custom-border"} ${isMobile ? "w-8" : ""
            //   }${isMobile
            //     ? `${collapsed ? " w-14" : " w-8"}`
            //     : `${collapsed ? " w-24" : " w-24"}`
            //   }`}
            className='flex py-2'
          >
            {/* <div className="sm:hidden">
              <div className="flex ">
                <Button type="text" onClick={toggleCollapsed}>
                  {collapsed ? (
                    <CgMenuLeft style={{ width: 24, height: 24 }} />
                  ) : (
                    <CgMenuLeft style={{ width: 24, height: 24 }} />
                  )}
                </Button>
              </div>
            </div> */}
            <div className="sm:hidden">
              <div className="flex ">
                <Button type="text" onClick={showDrawer}>
                  {collapsed ? (
                    <CgMenuLeft
                      style={{ width: 24, height: 24 }}
                    />
                  ) : (
                    <CgMenuLeft
                      style={{ width: 24, height: 24 }}
                    />
                  )}
                </Button>
                <LeftNavbar open={open} isDashboard={isDashboard} categoryHook={categoryHook} onClose={onClose} />
              </div>
            </div>
            <Link to="/">
              <img
                src="/icon/logo.png"
                // className={!collapsed || isMobile ? " size-8  " : "w-8 "}
                className='size-8 sm:w-32 sm:h-12 md:w-20 md:h-12 lg:h-auto'
                alt="Logo"
              />
            </Link>
          </div>
          {/* <div className="flex">
            <Button type="text" onClick={toggleCollapsed}>
              {collapsed ? <CgMenuLeft /> : <CgMenuLeft />}
            </Button>
          </div> */}
          <div className="mr-auto hidden sm:block sm:mr-0 md:min-w-32">
            <p className="text-sm text-gray-600 mb-[-4px] pl-4  font-semibold">
              Deliver to
            </p>
            <div className="flex">
              <div className="mt-1">
                <GrLocation />
              </div>
              <p className="text-md font-bold">
                {/* {user ? `${address.country == 0 ? 'Address' : address.country}` : "Address"} */}
                <LocationComponent />
              </p>
            </div>
          </div>
          <div className="w-full hidden sm:block py-2 px-4">
            <div id="nav-searc" className="flex justify-center items-center">
              <div id="nav-bar-left" className="hidden lg:block"></div>
              <form
                action="/search"
                className="flex w-full rounded-lg items-center focus-within:border-2 border-gray-300 focus-within:border-green-700"
                role="search"
                onBlur={handleFormBlur}
                ref={formRef}
              >
                <div className="flex items-center">
                  <div id="nav-search-dropdown-card">
                    <div className="relative">
                      <select
                        ref={selectRef}
                        className="nav-search-dropdown pl-1 font-sans h-10 searchSelect nav-progressive-attribute nav-progressive-search-dropdown bg-gray-200 border border-gray-300 text-gray-900 text-sm rounded-l-md focus:ring-[#009f7f] focus:border-[#009f7f]"
                        name="category"
                        style={{ width }}
                        onChange={(event) => setCategory(event.target.value)}
                      >
                        <option selected="selected" value="11">
                          All
                        </option>
                        {categoryHook.map((category, index) => (
                          <option key={`${index + 2}`} value={category.id}>
                            {category.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="flex-grow">
                  <div className="nav-search-field">
                    <input
                      type="text"
                      name="title"
                      placeholder="Search Marketplace"
                      className="w-full h-10 px-4 py-2 border border-gray-300 focus:ring-[#009f7f] focus:border-[#009f7f] outline-none"
                      onChange={(e) => setTitle(e.target.value.trim())}
                    />
                  </div>
                </div>
                <div className="nav-right flex items-center">
                  <div className="nav-search-submit nav-sprite">
                    <button
                      onClick={handelSubmit}
                      id="nav-search-submit-button"
                      type="submit"
                      className="h-10 px-4 py-2 bg-[#009f7f] text-white rounded-r-md hover:bg-[#009f8f] focus:ring-2 focus:ring-[#009f7f] focus:ring-opacity-50"
                      aria-label="Search"
                    >
                      <IoSearch />
                    </button>
                  </div>
                </div>
                {suggestion?.length > 0 && (
                  <div
                    ref={dropdownRef}
                    className="absolute top-12 h-[50vh] overflow-auto bg-white border border-gray-300 rounded-md shadow-lg z-50 w-full sm:w-[50%] 3xl:w-[30%] 2xl:w-[40%]"
                  >
                    {suggestion?.map((item, index) => (
                      <Link to={`/productdetail/${item.id}`}
                        key={index}
                        className="p-3 cursor-pointer hover:bg-gray-100 w-full inline-block"
                        onClick={() =>
                          handleSuggestionClick(item.title?.replace(/\\/g, ""))
                        }
                      >
                        {item.title?.replace(/\\/g, "")}
                      </Link>
                    ))}
                  </div>
                )}
              </form>
            </div>
          </div>

          {/* <div className="flex items-end justify-end">
            <div className="flex flex-row cursor-pointer justify-between items-center gap-2">
              <Dropdown
                overlay={
                  <div
                    className="dropdown-menu w-full rounded-md gap-10 px-10 mt-1 mx-auto bg-white"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    {!user ? (
                      <div className="py-4">
                        <Link to="/login">
                          <div className="w-[50%] rounded-md mb-4 py-1 font-semibold text-lg text-center text-white mx-auto bg-[#0da889]">
                            Sign in
                          </div>
                        </Link>
                        <hr />
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="flex">
                      <div
                        className={`dropdown-column border-r pr-10 pl-4 ${
                          user ? "py-2" : ""
                        }`}
                      >
                        {itemsColumn1.map((item) => (
                          <div key={item.key} className={item.className}>
                            {item.label}
                          </div>
                        ))}
                      </div>
                      <div
                        className={`dropdown-column pl-10 ${
                          user ? "py-2" : ""
                        }`}
                      >
                        {itemsColumn2.map((item) => (
                          <div key={item.key} className={item.className}>
                            {item.label}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                }
                trigger={[]}
                visible={isHovered}
                onVisibleChange={(visible) => setIsHovered(visible)}
              >
                <Space
                  className="w-32 sm:mr-4"
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <div>
                    <p className="text-sm text-gray-600 font-semibold">
                      Hello, Ahmad
                    </p>
                    <p className="text-md font-bold">Account & Lists</p>
                  </div>
                </Space>
              </Dropdown>
            </div>
          </div> */}
          <div className="w-[60%] sm:w-auto flex items-en justify-en pl-8 sm:pl-0">
            <div>
              <Popover
                content={content}
                trigger="hover"
              // visible={visible}
              // onVisibleChange={(visible) => setVisible(visible)}
              // onMouseEnter={handleMouseEnter}
              // onMouseLeave={handleMouseLeave}
              >
                <Space className="w-full sm:w-32 sm:mr-4 cursor-pointer">
                  <div>
                    <p className="text-sm text-gray-600 mb-[-4px] font-semibold">
                      Hello, {user ? `${address.firstname}` : 'sign in'}
                    </p>
                    <p className="text-md font-bold">Account & Lists</p>
                  </div>
                </Space>
              </Popover>
            </div>
          </div>
          {/* <div className="w-[40%] invisible sm:hidden">F</div> */}
          <div className=" sm:w-[50%] md:w-[25%] lg:w-[15%] 2xl:w-[8%] hidden sm:block mr-1">
            <Link to={user ? "/purchasedProduct" : "/login"}>
              <p className="text-xs md:text-sm text-gray-600 mb-[-4px] font-semibold ">
                Recent
              </p>
              <p className="text-md font-bold ">Orders</p>

              {/* <div className="flex gap-1">
                <p className="text-xs md:text-lg font-bold">&</p>
                <p className="text-xs md:text-lg font-bold"> Orders</p>
              </div> */}
            </Link>
          </div>
          <Link to="/checkout">
            <div className="flex gap-2 min-w-16 items-center sm:mr-2">
              <div>
                <p
                  // className={`text-md sm:text-sm md:text-sm lg:text-md z-10 font-bold sm:mt-7  md:mt-5 ${collapsed ? "lg:ml-3.5 2xl:ml-5" : "lg:ml-3.5 2xl:ml-3.5" } lg:mt-3 ml-[1.2rem] sm:ml-3 md:ml-3 lg:ml-2 absolute text-[#009f7f]`}
                  className={`text-md sm:text-sm md:text-sm lg:text-md z-10 font-bold sm:mt-7 md:mt-5 lg:mt-3 ml-5 sm:ml-3 md:ml-3 lg:ml-3.5 absolute text-[#009f7f]`}
                >
                  {Object.values(checkedItems).filter((value) => value).length}
                </p>
                <img
                  src="/icon/cart1.svg"
                  alt="cart image"
                  className="size-10 sm:size-24 md:size-20 lg:size-16 relative"
                />
              </div>
              <p className=" font-bold hidden sm:block text-sm md:text-md text-[#009f7f] lg:ml-[-3px]">
                Cart
              </p>
            </div>
          </Link>
        </nav>
        <div className="flex flex-col-reverse sm:flex-col">
          <nav className="px-2 sm:px-6 w-full bg-[#009f7f] py-2 sm:py-1  flex justify-between sm:justify-start overflow-x-hidden  items-center">
            <div className="flex gap-3 items-center sm:gap-5">
              {/* <div className="hidden sm:block">
                <div className="flex ">
                  <Button type="text" onClick={toggleCollapsed}>
                    {collapsed ? (
                      <CgMenuLeft
                        style={{ color: "white", width: 24, height: 24 }}
                      />
                    ) : (
                      <CgMenuLeft
                        style={{ color: "white", width: 24, height: 24 }}
                      />
                    )}
                    <p className="text-white my-auto font-bold ml-[-10%]">
                      ALL
                    </p>
                  </Button>
                </div>
              </div> */}
              <div className="hidden sm:block">
                <div className="flex ">
                  <Button type="text" onClick={showDrawer}>
                    {collapsed ? (
                      <CgMenuLeft
                        style={{ color: "white", width: 24, height: 24 }}
                      />
                    ) : (
                      <CgMenuLeft
                        style={{ color: "white", width: 24, height: 24 }}
                      />
                    )}
                    <p className="text-white my-auto font-bold ml-[-10%]">
                      ALL
                    </p>
                  </Button>
                  <LeftNavbar open={open} isDashboard={isDashboard} categoryHook={categoryHook} onClose={onClose} />
                </div>
              </div>
              {
                location.pathname == "/" ?
                  <a href='/#trending'>
                    <p className="text-white cursor-pointer text-xs md:text-sm my-auto font-semibold ">
                      Today's Deals
                    </p>
                  </a>
                  :
                  <Link to='/#trending'>
                    <p className="text-white cursor-pointer text-xs md:text-sm my-auto font-semibold ">
                      Today's Deals
                    </p>
                  </Link>
              }
              {/* <Link to="/customerService">
                <p className="text-white cursor-pointer text-xs md:text-sm my-auto font-semibold ">
                  Customer Service
                </p>
              </Link> */}
              {user ? (
                <Link to="/buyagain">
                  <p className="text-white cursor-pointer text-xs md:text-sm my-auto font-semibold ">
                    Buy Again
                  </p>
                </Link>
              ) : (
                ""
              )}

              <Link to="/sell">
                <p className="text-white cursor-pointer text-xs md:text-sm my-auto font-semibold ">
                  Sell
                </p>
              </Link>
              {/* <p onClick={() => {navigator.clipboard.writeText(user?.referalcodeshop || '')}} className="text-white cursor-pointer text-xs md:text-sm my-auto font-semibold ">
                Referral Code: {user?.referalcodeshop}
              </p> */}
              {
                user &&
                <Tooltip
                  title={visibleCopy ? 'Referral code copied!' : ''}
                  visible={visibleCopy}
                  placement="top"
                >
                  <button
                    className="text-white cursor-pointer text-xs md:text-sm my-auto font-semibold"
                    onClick={handleClickCopy}
                  >
                    Your Referral Code: {user?.referalcodeshop}
                  </button>
                </Tooltip>
              }
            </div>
          </nav>
          <div>
            {isMobile && (
              <div>
                <nav className="px-2 sm:px-6 sm:hidden flex justify-between sm:justify-start overflow-x-hidden  items-center">
                  <div className="w-full  py-2 px-4 ">
                    <div
                      id="nav-searc"
                      className="flex justify-center  items-center"
                    >
                      <div id="nav-bar-left" className="hidden lg:block"></div>
                      <form
                        // id="nav-search-bar-form"
                        action="/search"
                        className="flex w-full rounded-lg max-w-2xl items-center focus-within:border-2 border-gray-300  focus-within:border-green-700"
                        // method="GET"
                        // name="site-search"
                        role="search"
                      >
                        <div className="flex-grow">
                          <div className="nav-search-field">
                            <input
                              type="text"
                              // id="twotabsearchtextbox"
                              name="title"
                              // autocomplete="on"
                              placeholder="Search Marketplace"
                              className="w-full h-10 px-4 py-2 border rounded-l-md border-gray-300 focus:ring-[#009f7f] focus:border-[#009f7f] outline-none"
                              // dir="auto"
                              // tabindex="0"
                              // aria-label="Search Marketplace"
                              // spellcheck="false"
                              onChange={(e) => setTitle(e.target.value.trim())}
                            />
                          </div>
                        </div>
                        <div className="nav-right flex items-center">
                          <div className="nav-search-submit nav-sprite">
                            <button
                              onClick={handelSubmit}
                              id="nav-search-submit-button"
                              type="submit"
                              className="h-10 px-4 py-2 bg-[#009f7f] text-white rounded-r-md hover:bg-[#009f8f] focus:ring-2 focus:ring-[#009f7f] focus:ring-opacity-50"
                              aria-label="Search"
                            >
                              <IoSearch />
                            </button>
                          </div>
                        </div>
                        {suggestion.length > 0 ? (
                          <div
                            ref={dropdownRef}
                            className="absolute top-28 bg-white border border-gray-300 rounded-md shadow-lg z-10 w-[80%] sm:w-[50%] 3xl:w-[30%] 2xl:w-[40%]"
                          >
                            {suggestion.map((item, index) => (
                              <div
                                key={index}
                                className="p-2 cursor-pointer hover:bg-gray-100"
                                onClick={() =>
                                  handleSuggestionClick(
                                    item.title?.replace(/\\/g, "")
                                  )
                                }
                              >
                                {item.title?.replace(/\\/g, "")}
                              </div>
                            ))}
                          </div>
                        ) : (
                          ""
                        )}
                        {/* </div> */}
                      </form>
                    </div>
                  </div>
                </nav>
              </div>
            )}
          </div>
        </div>
        {isMobile && (
          <nav className="px-2 sm:px-6 border-t border-white sm:hidden flex bg-[#009f7f] justify-between sm:justify-start overflow-x-hidden  items-center">
            <div className="flex mx-6 py-2">
              <GrLocation style={{ color: "white", width: 20, height: 20 }} />{" "}
              <p className="text-sm   px-1 text-white  font-semibold">
                Deliver to
              </p>
              <p className="text-sm text-white  font-bold">
                {" "}
                {user ? `${address.country == 0 ? 'Address' : address.country}` : "Address"}
              </p>
            </div>
          </nav>
        )}
      </div>

      {/* <Layout className={`layout ${isMobile ? "h-[100vh]" : "h-[100vh]"}`}> */}
      {/* <Layout
        className={`layout ${!collapsed ? "pt-[10.8rem]" : " pt-[4rem]"
          }  md:pt-[7.5rem] lg:pt-[6.5rem] h-[100vh]`}
      >
        {isMobile && collapsed ? (
          ""
        ) : (
          <Sider
            className={`bg-white  ${collapsed ? "hidden" : ""
              } overflow-auto customHeight  ${user ? "sm:mt-[3.6rem" : "sm:mt-[3.7rem"
              }  ${!collapsed && !isMobile ? "sider-web" : ""} ${isMobile ? "sider-tag" : ""
              }`}
            trigger={null}
            collapsible
            collapsed={collapsed}
          >
            <Menu
              selectedKeys={selectedKeys}
              defaultOpenKeys={defaultOpenKeys}
              mode="inline"
              theme="light"
              inlineCollapsed={collapsed}
              items={isDashboard ? itemsForSeller : itemsForBuyer}
              onClick={handleClick}
              className="custom-menu"
            />
          </Sider>
        )} */}
      {/* {isMobile && collapsed ? ( */}
      {/* <Layout
          className={`overflow-y-auto px-5 py-32 sm:py-2  ${isMobile && !collapsed ? "hidden" : ""
            }`}
        > */}
      {/* <Content>{ */}
      {/* <div className="bg-[#E3E6E6]"> */}
      <div className="lg:w-[80% xl:w-[90%] 2xl:w-[70%] mx-auto mt-44 sm:mt-[8rem] px-3">
        <ChildComponent />
      </div>
      {/* </div> */}
      {/* // }</Content> */}
      {/* </Layout> */}
      {/* // ) : (
        //   !isMobile && (
        //     <Layout className="overflow-y-auto h-[88vh] px-5 pt-5 mt-[3.7rem]">
        //       <Content>
        //         <Route />
        //       </Content>
        //     </Layout>
        //   )
        // )} */}
      {/* </Layout> */}
      <SearchModal isVisible={isModalVisible} onClose={closeModal} />
    </div>
  );
};

export default Navbar;

const SearchModal = ({ isVisible, onClose }) => {
  const modalRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isVisible, onClose]);

  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-start justify-center bg-black bg-opacity-50">
      <div
        ref={modalRef}
        className="flex items-center rounded-3xl border border-white bg-white  p-1 mt-12"
      >
        <IoIosSearch className="mx-2" />
        <input
          type="text"
          className="py-2 text-sm w-64 rounded-3xl bg-white h-8 text-heading placeholder:text-gray-400 focus:outline-none focus:ring-0"
          placeholder="Search your route..."
        />
      </div>
    </div>
  );
};
