import React from "react";
import SummaryCard from "../../components/SummaryCard";
import Orders from "./Orders";

function Dashoard() {
  return (
    <div>
      {/* Summary */}
      <div className="bg-white rounded-lg mt-48 mx-2 sm:mt-36 md:mt-32 pt-6">
        <p className="font-semibold text-lg  border-l-4 h-6 pl-6 border-green-700">
          Summary
        </p>
        <div className="grid  p-6 sm:grid-cols-2  sm:gap-4 gap-2 lg:grid-cols-4 grid-cols-2">
          <SummaryCard
            title="Your Orders"
            details="Track your orders"
            border="border-[#1eae98]"
            icon="/icon/orders.png"
            route="/dashboard/orders"
          />
          <SummaryCard
            title="Buy Again"
            details="Conveniently buy your frequently purchased items."
            border="border-[#1eae98]"
            icon="/icon/cart.png"
            route="/buyagain"
          />
          <SummaryCard
            title="Your Lists"
            details="Track and update your listings"
            border="border-[#1eae98]"
            icon="/icon/lists.png"
            route="/dashboard/allproducts"
          />
          <SummaryCard
            title="Your Profiles"
            details="Update your profile information and address"
            border="border-[#1eae98]"
            icon="/icon/profile.png"
            route="/profile"

          />

        </div>
      </div>
<div className="mt-[-28%] mx-2 sm:mt-[-14%] md:mt-[-8%]">
        <Orders />
        </div>
    </div>
  );
}

export default Dashoard;