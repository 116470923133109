import React, { useContext, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { verifyjwt } from '../../backendServices/ApiCalls'
import { UserContext } from '../../contexts/UserContext';

function VerifyAuth() {
    const { user, setUser } = useContext(UserContext);
    const navigate = useNavigate();
    const params = useParams()
    const setToken = () => {
        let para = {
            token: params.token
        }
        verifyjwt(para, async (response) => {
            const token = response?.data?.token
            if (response?.data?.status === "success") {
                localStorage.setItem("token", token)
                // window.location.href = `http://localhost:3001/`
                setUser(response?.data?.user)
                navigate('/')
            }
        },
            (error) => {
                console.log(error?.response?.data);
            });
    }
    useEffect(() => {
        setToken()
    }, [])

    return (
        <div>
            <p>We are verifying you. You will be redirect please wait... </p>
        </div>
    )
}

export default VerifyAuth