import React from 'react'
import { getConnections, switchChain } from '@wagmi/core'
import { config } from './chainConfig'
import { polygon } from 'viem/chains'
import { Button } from '@mui/material'
import { SystemUpdate } from '@mui/icons-material'

const UpdateNetwork = () => {

    const connections = getConnections(config)

    const changeNetwork = async () =>{
        try{
            const result = await switchChain(config, {
                chainId: polygon.id,
                connector: connections[0]?.connector, 
              })
        }
        catch(e){
            console.error(e.message)
        }
    }
  return (
    <Button sx={{mt:2, borderRadius:'50px', fontWeight:'bold'}} variant='contained' color='primary' startIcon={<img src={process.env.PUBLIC_URL + "assets/img/polygon.webp"} style={{height:'30px'}} />} onClick={()=>changeNetwork()}>
      Change to Polygon
    </Button>
  )
}

export default UpdateNetwork