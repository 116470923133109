import axios from 'axios';

const API_BASE_URL = 'https://mptestbackend.m5networkhub.com/shop/api';
// const API_BASE_URL = 'http://localhost:8000/shop/api';


function updateAuthorizationHeader() {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common['authorization'] = 'Bearer ' + token;
}
export function registerUser(params, callback, errorCallback) {
  updateAuthorizationHeader()

  axios.post(API_BASE_URL + '/register', params)
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function authenticate(service, callback, errorCallback) {
  updateAuthorizationHeader()
  axios.post(API_BASE_URL, service)
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

//Post Request
export function postRequest(url, params, callback, errorCallback) {
  updateAuthorizationHeader()
  axios.post(API_BASE_URL + url, params)
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function passjwt(params, callback, errorCallback) {
  // updateAuthorizationHeader()

  axios.post(API_BASE_URL + '/pass-jwt', params)
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}
export function verifyjwt(params, callback, errorCallback) {
  // updateAuthorizationHeader()

  axios.post(API_BASE_URL + '/verify-jwt', params)
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function loginVerify(params, callback, errorCallback) {
  // updateAuthorizationHeader()

  axios.post(API_BASE_URL + '/loginVerify', params)
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function getallproducts(params, callback, errorCallback) {
  // updateAuthorizationHeader()

  axios.post(API_BASE_URL + '/getallproducts', params)
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function getSingleProduct(params, callback, errorCallback) {
  // updateAuthorizationHeader()

  axios.post(API_BASE_URL + '/getSingleProduct', params)
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}
export function shopByName(params, callback, errorCallback) {
  // updateAuthorizationHeader()

  axios.post(API_BASE_URL + '/shopbyname', params)
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function randomShop(params, callback, errorCallback) {
  // updateAuthorizationHeader()

  axios.post(API_BASE_URL + '/randomshop', params)
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}
export function GetProductCategory(params, callback, errorCallback) {
  // updateAuthorizationHeader()

  axios.post(API_BASE_URL + '/getProductCategory', params)
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}