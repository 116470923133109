import React, { useEffect, useState } from "react";
import ProductCard from "../../components/ProductCard";
import { getallproducts, postRequest } from "../../backendServices/ApiCalls";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper/modules";
import useStore from "../../contexts/store";
import DrawerRight from "../../components/DrawerRight";
import { useNavigate } from "react-router-dom";
import { Result } from "antd";
import TrendingProducts from "../../components/TrendingProducts";
import { FaRegImage } from "react-icons/fa6";

export default function Home() {
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [bannerLoading, setBannerLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [offset, setOffset] = useState(0); // Track the offset for pagination
  const [totalResults, setTotalResults] = useState(0);
  const [link, setLink] = useState();
  const [bannerLink, setBannerLink] = useState('');
  const [banner, setBanner] = useState([]);
  const [trending, setTrending] = useState([]);
  const [trendingLink, setTrendingLink] = useState('');
  const nav = useNavigate();
  const [isImageLoading, setIsImageLoading] = useState(true);
  let limit = 20

  const handleImageLoad = () => {
    setIsImageLoading(false);
  };

  const CategoryImg = () => {
    postRequest("/allcategoryimage", '', async (response) => {
      setBannerLoading(true);
      if (response?.data?.status === "success") {
        setBanner(response?.data?.data);
        // console.log('response?.data?.link + response?.data?.data[0]?.banner',response?.data?.data)
        setBannerLink(response?.data?.link)
        setBannerLoading(false);
      }
      if (response?.data?.status === "error") {
        setBannerLoading(false);
      }
    },
      (error) => {
        console.log(error?.response?.data);
        setLoading(false);
      });
  };

  const TrendingProduct = () => {
    postRequest("/trendingdata", '', async (response) => {
      // console.log('response?.data?.data', response?.data?.data)
      if (response?.data?.status === "success") {
        setTrending(response?.data?.data);
        setTrendingLink(response?.data?.link)
      }
      // if (response?.data?.status === "error") {
      // }
    },
      (error) => {
        console.log(error?.response?.data);
      });
  };

  useEffect(() => {
    CategoryImg()
    TrendingProduct()
  }, [])


  const fetchProducts = (offset) => {
    setLoadingMore(true); // Start loading for pagination
    getallproducts(
      { limit, offset: offset }, // Adjust limit as per your API pagination
      async (response) => {
        setLoading(true);
        if (response?.data?.status === "success") {
          const newProducts = response?.data?.data.filter(product => !products.some(p => p.id === product.id));
          setProducts((prevProducts) => [...prevProducts, ...newProducts]);
          // setProducts(response?.data?.data);
          setLink(response?.data?.link);
          setTotalResults(response?.data?.totalResults[0]?.totalProducts)
          setLoading(false); // Stop initial loading
          setLoadingMore(false); // Stop loading for pagination
        }
      },
      (error) => {
        console.log(error?.response?.data);
        setLoading(false); // Stop initial loading on error
        setLoadingMore(false); // Stop loading for pagination on error
      }
    );
  };

  useEffect(() => {
    fetchProducts(offset);
  }, [offset]); // Fetch products when offset changes

  const handleLoadMore = () => {
    // if (totalResults>=limit) {
    //   console.log('totalResults', totalResults , typeof totalResults);
    //   console.log('limit', limit , typeof limit)
    // }
    // if (totalResults>=limit) {
    //   console.log("offset remaining: " + limit);
    setOffset((prevOffset) => prevOffset + limit); // Increment offset on load more
    // }else{
    //   const offset = totalResults - offset;
    //   console.log("offset remaining: " + offset);
    //   setOffset((prevOffset) => prevOffset + offset); // Increment offset on load more
    // }
    // console.log('limit', limit);
    // console.log('offset', offset);
    // const remaining = totalResults - offset > limit
    // const result = remaining > limit
    // console.log('result',result)
    // console.log('remaining', remaining)
  };

  return (
    <div className="relative flex items-center">
      {bannerLoading ?
        <div className="flex items-center justify-center">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#009F7F]"></div>
        </div>
        :
        <>
          <Swiper pagination={true} modules={[Pagination, Autoplay]}
            className="mySwiper h-20" autoplay={{ delay: 3000, disableOnInteraction: false, }}>
            {
              banner.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <img onLoad={handleImageLoad} src={bannerLink + item.banner} className="w-8" alt="Logo" />
                    {/* {isImageLoading && <div className="custom-skeleton w-8 flex items-center justify-center"><FaRegImage color="#BFBFBF" size={50} /></div>} */}
                  </SwiperSlide>
                )
              })
            }
          </Swiper>
        </>
      }
      {/* <div className="absolute sm:z-10 top-[75%] flex w-full h-full justify-center items-center"> */}
      <div className={`absolute sm:z-10 top-[90%] sm:top-[75%] px-5 ${loadingMore ? "flex w-full h-[90vh] flex-col justify-center items-center" : ""}`}>
        <div id="trending" className="grid py-6 gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 ">
          {
            trending.map((item, i) => {
              return (
                <TrendingProducts
                  key={i}
                  title={item?.title}
                  design={item?.design}
                  thumbnails={item?.thumbnails}
                  categoryIdsName={item?.categoryIdsName}
                  link={trendingLink}
                />
              )
            })
          }
        </div>

        {loading ? (
          <div className="flex items-center justify-center">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#009F7F]"></div>
          </div>
        ) : (
          <div className="grid py-6 sm:grid-cols-2 md:grid-cols-2 gap-4 lg:grid-cols-3 xl:grid-cols-4   grid-cols-1">
            {products.map((product, index) => (
              <ProductCard
                key={index}
                id={product?.id}
                shopid={product?.shopid}
                imgSrc={product?.picture}
                link={link}
                title={product?.title?.replace(/\\/g, '')}
                originalPrice={product?.price}
                description={product?.description?.replace(/\\/g, '')}
                storeName={product?.store_name}
                category={product?.category}
                productQuantity={product?.quantity}
                size={product?.size}
                weight={product?.weight}
                average_star={product?.average_star}
                reviewusers={product?.reviewusers}
              />
            ))}
          </div>
        )}

        {/* Load more button */}
        {loadingMore ?
          (
            <div className="flex items-center justify-center">
              <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#009F7F]"></div>
            </div>
          ) : (
            totalResults - offset > limit ?
              (<div className="flex justify-center my-4">
                <button
                  onClick={handleLoadMore}
                  className="bg-[#009f7f] hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                >
                  Load More Products
                </button>
              </div>) : ""
          )}
      </div>

      {/* Drawer side bar */}
      <DrawerRight />
    </div>
  );
}
