import React from 'react'
import Navbar from '../../components/Navbar'
import SellerLinks from './SellerLinks'

export default function index() {
    return (
        <div className='mt-28'>
            <Navbar childComponent={SellerLinks} />
        </div>
    )
}
