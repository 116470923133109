import React, { useContext, useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";
import {
  GetProductCategory,
  postRequest,
} from "../../backendServices/ApiCalls";
import { notification } from "antd";
import useStore from "../../contexts/store";
import Checkbox from "@mui/material/Checkbox";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

export default function Checkout() {
  const nav = useNavigate()
  const [checkedItems, setCheckedItems] = useState(() => {
    // Initialize checkedItems from localStorage or default to all true
    const savedCheckedItems = JSON.parse(localStorage.getItem("checkedItems"));
    if (savedCheckedItems) {
      return savedCheckedItems;
    } else {
      return {};
    }
  });
  const [link, setLink] = useState();
  const [phone, setPhone] = useState("");
  const [suggestProduct, setSuggestProduct] = useState([]);
  // console.log("🚀 ~ Checkout ~ suggestProduct:", suggestProduct);

  const cart = useStore((state) => state.cart);
  const increaseQuantity = useStore((state) => state.increaseQuantity);
  const decreaseQuantity = useStore((state) => state.decreaseQuantity);
  const removeFromCart = useStore((state) => state.removeFromCart);
  const addToCart = useStore((state) => state.addToCart);
  const emptyCart = useStore((state) => state.emptyCart);
  if(cart.length== 0){
    nav("/")
  }

  const { user, setUser } = useContext(UserContext);
  const [api, contextHolder] = notification.useNotification();

  const successNotification = (placement, message, description) => {
    api.success({ message, description, placement });
  };
  const errorNotification = (placement, message, description) => {
    api.error({ message, description, placement });
  };

  useEffect(() => {
    // Save checkedItems state to localStorage whenever it changes
    localStorage.setItem("checkedItems", JSON.stringify(checkedItems));
  }, [checkedItems]);

  useEffect(() => {
    // Initialize checkedItems state to mark all items as checked
    let initialCheckedState = {};
    cart.forEach((item, index) => {
      if (!checkedItems[index]) {
        initialCheckedState[index] = true; // Initialize unchecked items as false
      } else {
        initialCheckedState[index] = true; // Keep already checked items as true
      }
    });
    setCheckedItems(initialCheckedState);
  }, [cart]);
  // console.log("checkedItems", checkedItems);
  useEffect(() => {
    // localStorage.setItem('checkedItems', JSON.stringify(checkedItems))
  }, [checkedItems]);

  const handleCheckboxClick = (index) => {
    setCheckedItems((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const getCheckedItems = () => {
    // Collect all item IDs to be removed first
    const itemsToRemove = [];
    for (const [index, value] of Object.entries(checkedItems)) {
      if (!value) {
        console.log(`Index ${index} is false`);
        itemsToRemove.push(cart[index]?.id);
      }
    }
    // Remove collected items from the cart
    itemsToRemove.forEach(itemId => removeFromCart(itemId));
    // console.log('cart', cart);
    nav('/paymentDescription')
  };
  // const handleOrder = () => {
  //   const checkedCartItems = getCheckedItems();
  //   console.log('checkedCartItems', checkedCartItems)
  //   return
  //   let totalAmount = cart.reduce((acc, item, index) => {
  //     if (checkedItems[index]) {
  //       return acc + item.totalPrice;
  //     }
  //     return acc;
  //   }, 0);

  //   let storeId = cart.map((item) => item.shopid);
  //   let storeIdString = storeId.join(", ");

  //   let ids = cart.map((item) => item.id);
  //   let productIdString = ids.join(", ");

  //   let idquantity = cart.map((item) => item.quantity);
  //   let quantityString = idquantity.join(", ");

  //   let param = {
  //     totalAmount: totalAmount,
  //     productId: productIdString,
  //     quantity: quantityString,
  //   };
  //   console.log("param", param);

  //   postRequest(
  //     "/orderInfo",
  //     param,
  //     (response) => {
  //       if (response?.data?.status === "success") {
  //         successNotification("topRight", "Success", response?.data?.message);
  //       } else if (response?.data?.status === "error") {
  //         errorNotification("topRight", "Error", response?.data?.message);
  //       }
  //     },
  //     (error) => {
  //       console.log(error?.response?.data);
  //     }
  //   );
  // };

  const productsCatagory = () => {
    let categoryLastIndex;
    if (cart.length > 0) {
      categoryLastIndex = cart[cart.length - 1].category;
    }
    const param = {
      category: categoryLastIndex,
      limit: 4,
      offset: 0
    };
    // console.log('param', param)
    postRequest("/getProductCategoryCheckout", param, async (response) => {
      if (response?.data?.status === "success") {
        setSuggestProduct(response?.data?.data);
        // console.log('response?.data?.data', response?.data?.data)
        setLink(response?.data?.link);
      }
    },
      (error) => {
        console.log(error?.response?.data);
      });
  };

  useEffect(() => {
    productsCatagory();
  }, [cart]);

  const handleDeleteClick = (itemId) => {
    removeFromCart(itemId);
    productsCatagory();
  };

  const img = (imgSrc) => {
    let imageNames = [];
    if (imgSrc) {
      try {
        const pictureData = JSON.parse(imgSrc);
        return (imageNames = pictureData.imageNames[0]);
      } catch (e) {
        console.error("Invalid JSON in item.picture", e);
      }
    }
  };

  return (
    <div className="sm:gap-6 sm:mx-2 grid grid-cols-1 sm:grid-cols-4 mx-3">
      <div className="bg-white overflow-auto col-span-3">
        <div className="flex justify-between mb-2 px-4  mt-4">
          <p className=" text-lg sm:text-2xl font-semibold">Shopping Cart</p>
          <p className="text-md sm:text-lg font-normal">Price</p>
        </div>
        <hr />
        {cart.map((item, i) => (
          <div
            key={i}
            className={`flex justify-between overflow-auto mt-2 border-b items-start mb-4 ${checkedItems[i] ? "" : "opacity-50"
              }`}
          >
            <div className="flex items-center">
              <Checkbox
                {...label}
                checked={checkedItems[i] || false}
                color="success"
                onChange={() => handleCheckboxClick(i)}
              />
              <img src={item.image} className="w-32 h-32" alt="Logo" />
            </div>

            <div className="flex flex-col w-full">
              <div className="flex justify-between mt-2 w-full">
                <div className="ml-20">
                  <h3 className="font-semibold truncate-3-lines text-md sm:text-lg">
                    {item.title}
                  </h3>
                  <p className="text-[#009f7f] mt-1"> In Stock</p>
                </div>
                <div>
                  <p className="font-semibold mx-2 text-xl text-[#009f7f]">
                    ${item.originalPrice}
                  </p>
                </div>
              </div>

              <div className="flex justify-between ml-20 mb-2 mt-20 gap-3">
                <div className="flex gap-3">
                  <div className="flex overflow-hidden flex-row items-center w-24 h-8 bg-gray-100 text-heading rounded-full">
                    <button
                      onClick={() => decreaseQuantity(item.id)}
                      className="cursor-pointer p-2 transition-colors duration-200 hover:bg-accent-hover focus:outline-0 hover:!bg-gray-100"
                    >
                      <span className="sr-only">Decrease</span>
                      <svg
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        className="h-3 w-3 stroke-2.5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M20 12H4"
                        ></path>
                      </svg>
                    </button>
                    <div className="flex flex-1 items-center justify-center px-3 text-sm font-semibold text-heading">
                      {item.quantity}
                    </div>
                    <button
                      onClick={() => increaseQuantity(item.id)}
                      className="cursor-pointer p-2 transition-colors duration-200 hover:bg-accent-hover focus:outline-0 hover:!bg-gray-100"
                    >
                      <span className="sr-only">Increase</span>
                      <svg
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        className="md:w-4.5 h-3.5 w-3.5 stroke-2.5 md:h-4.5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                        ></path>
                      </svg>
                    </button>
                  </div>
                  <p
                    className="hover:text-red-500 cursor-pointer mt-3"
                    onClick={() => handleDeleteClick(item.id)}
                  >
                    Delete
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}

        <div className="text-lg font-semibold p-3 text-right">
          Subtotal (
          <span>
            {Object.values(checkedItems).filter((value) => value).length} items
          </span>
          ): $
          {cart.reduce((acc, item, index) => {
            if (checkedItems[index]) {
              return acc + item.totalPrice;
            }
            return acc;
          }, 0)}
        </div>
      </div>
      <div>
        <div className="col-span-1  bg-white">
          <div className="text-lg font-semibold p-3 ">
            Subtotal (
            <span>
              {Object.values(checkedItems).filter((value) => value).length}{" "}
              items
            </span>
            {/* {item.category === "clothing" && (
              <span
                className="font-semibold text-xs  text-body"
                style={{ fontSize: "15px" }}
              >
                size: {item.selectedSize}
              </span>
            )} */}
            ): $
            {cart.reduce((acc, item, index) => {
              if (checkedItems[index]) {
                return acc + item.totalPrice;
              }
              return acc;
            }, 0)}
          </div>
          <div>
          </div>
          {/* <Link to="/paymentDescription"> */}
          <button
            onClick={emptyCart}
            className="inline-flex mx-3 2xl:mx-5 text-white bg-[#009F7F] items-center justify-center shrink-0 font-semibold leading-none rounded outline-none transition duration-300 ease-in-out focus:outline-0 focus:shadow focus:ring-1 focus:ring-accent-700 bg-accent text-light border border-transparent hover:bg-[#009f77] px-3 py-0 h-12 mt-5 w-[90%]"
          >
            Empty Cart
          </button>{" "}
          <button
            onClick={getCheckedItems}
            className="inline-flex mx-3 2xl:mx-5 text-white bg-[#009F7F] items-center justify-center shrink-0 font-semibold leading-none rounded outline-none transition duration-300 ease-in-out focus:outline-0 focus:shadow focus:ring-1 focus:ring-accent-700 bg-accent text-light border border-transparent hover:bg-[#009f77] px-3 py-0 h-12 mt-5 w-[90%]"
          >
            Proceed to checkout
          </button>{" "}
          {/* </Link> */}
          <div className="col-span-1 border-t-8 border-w border-[#F5F5F5] mt-6 h-full bg-white">
            <p className=" mb-4 pt-3 text-center text-lg  font-semibold">
              Your recently viewed items
            </p>
            {suggestProduct.map((item, i) => (
              <div
              key={i}
              className="grid grid-cols-3 sm:grid-cols-1 border-b gap-y-4 py-3 place-items-center"
            >
              <div className="col-span-1 flex justify-center items-center">
                <img
                  src={link + img(item.picture)}
                  alt="same products"
                  className="w-24 h-24 mt-1"
                />
              </div>
              <div className="col-span-2 px-2 text-center">
                <p className="truncate-3-lines">{item.title}</p>
                <p className="font-semibold mx-2 text-xl text-[#009f7f]">
                  ${item.price}
                </p>
                <button
                  disabled={cart.some((it) => it.id === item?.id)}
                  onClick={() => {
                    successNotification('topRight', 'Success', "Product Added");
                    addToCart({
                      id: item?.id,
                      title: item?.title,
                      originalPrice: item?.price,
                      image: link + img(item.picture),
                      shopid: item?.shopid,
                      category: item?.category,
                    })
                  }}
                  data-variant="normal"
                  className="inline-flex text-white bg-[#009F7F] items-center justify-center shrink-0 font-semibold leading-none rounded outline-none transition duration-300 ease-in-out focus:outline-0 focus:shadow focus:ring-1 focus:ring-accent-700 bg-accent text-light border border-transparent hover:bg-[#009f77] h-8 mt-2 w-[70%]"
                >
                  {cart.some((it) => it.id === item?.id) ? " In Cart" : " Add to Cart"}
                </button>
              </div>
            </div>
            ))}
          </div>
        </div>
      </div>
      <div>{contextHolder}</div>
    </div >
  );
}



