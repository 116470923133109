import { Modal, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { IoPencil } from 'react-icons/io5';
import { postRequest } from '../../backendServices/ApiCalls';
import AddressModal from '../../components/AddressModal';

export default function Profile() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [address, setAddress] = useState('');
  const [loading, setLoading] = useState(false);

  const [isModalOpenForAddress, setIsModalOpenForAddress] = useState(false);
  const [isEditModeForAddress, setIsEditModeForAddress] = useState(false);


  const [api, contextHolder] = notification.useNotification();
  const successNotification = (placement, message, description) => {
    api.success({ message, description, placement })
  };
  const errorNotification = (placement, message, description) => {
    api.error({ message, description, placement })
  };

  const getProfileData =()=>{
    postRequest("/getProfile", '', async (response) => {
      // setLoading(true);
      // console.log("response?.data profile", response?.data?.data[0]);
      if (response?.data?.status === "success") {
        const {email, mobile, firstname, lastname, address, city, country, state, zipCode} = response?.data?.data[0]
        setName(firstname)
        setEmail(email)
        setMobile(mobile)
        // if (address == null) {
        //   setAddress("")
        // }else{
          setAddress({firstname,lastname,mobile,address,city,country,state,zipCode})
        // }
        // successNotification('topRight', 'Success', response?.data?.message)
        // setLoading(false);
        // setIsModalOpen(false);
      }
      if (response?.data?.status === "error") {
        // errorNotification('topRight', 'Error', response?.data?.message)
        // setLoading(false);
        // setIsModalOpen(false);
      }
    },
      (error) => {
        console.log(error?.response?.data);
        setLoading(false);
        setIsModalOpen(false);
      }
    );
  }
  useEffect(() => {
    getProfileData()
  }, [])
  
  const showModal = (content) => {
    setModalContent(content);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    let param
    switch (modalContent) {
      case 'name':
        // console.log('Name:', name);
        param = { name }
        break;
      case 'email':
        // console.log('Email:', email);
        // console.log('Mobile:', mobile);
        param = { email, mobile }
        break;
      case 'address':
        // console.log('Address:', address);
        param = { address }
        break;
      default:
        break;
    }

    // console.log('param', param)
    postRequest("/updateProfile", param, async (response) => {
      setLoading(true);
      // console.log("response?.data", response?.data);
      if (response?.data?.status === "success") {
        successNotification('topRight', 'Success', response?.data?.message)
        setLoading(false);
        setIsModalOpen(false);
      }
      if (response?.data?.status === "error") {
        errorNotification('topRight', 'Error', response?.data?.message)
        setLoading(false);
        setIsModalOpen(false);
      }
    },
      (error) => {
        console.log(error?.response?.data);
        setLoading(false);
        setIsModalOpen(false);
      }
    );
  };

  const renderModalContent = () => {
    switch (modalContent) {
      case 'name':
        return (
          <div className='flex flex-col gap-y-2'>
            <p>Changes made to your profile name here will be shown anywhere your profile is used.</p>
            <input
              type="text"
              name='name'
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="User Name"
              className="p-2 w-full border rounded focus:outline-none focus:border-[#009F7F]"
            />
          </div>
        );
      case 'email':
        return (
          <div className='flex flex-col gap-y-2'>
            <p>Receive important alerts for your profile here.</p>
            <input
              type="email"
              name='email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              className="p-2 w-full border rounded focus:outline-none focus:border-[#009F7F]"
            />
            <div className='flex flex-col gap-y-2'>
              <p>Receive important alerts for your profile here.</p>
              <input
                type="text"
                name='mobile'
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
                placeholder="Mobile Number"
                className="p-2 w-full border rounded focus:outline-none focus:border-[#009F7F]"
              />
            </div>
          </div>
        );
      case 'address':
        return (
          <div className='flex flex-col gap-y-2'>
            <p>Add your address here.</p>
            <input
              type="text"
              name='address'
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              placeholder="Address"
              className="p-2 w-full border rounded focus:outline-none focus:border-[#009F7F]"
            />
          </div>
        );
      default:
        return null;
    }
  };

  const showModalForAddress = (editMode) => {
    setIsEditModeForAddress(editMode);
    setIsModalOpenForAddress(true);
    getProfileData()
  };

  const handleOkForAddress = () => {
    setIsModalOpen(false);
    getProfileData()
    // setActivePanel((parseInt(activePanel) + 1).toString());
  };

  return (
    <div className='mx-auto w-4/5'>
      <div className='flex flex-col gap-4'>
        <h2 className='text-2xl font-bold'>Manage Your Profile</h2>
        <div className='bg-[#009f7f] text-white p-4 rounded'>
          <div className='flex items-center gap-x-2'>
            <h1 className='text-2xl font-bold'>{name}</h1>
            <IoPencil size={20} className='cursor-pointer' onClick={() => showModal('name')} />
          </div>
          <p className='font-bold'>Account holder</p>
        </div>
        <div className='border p-4 rounded flex items-center justify-between gap-2'>
          <div className='flex flex-col gap-y-2'>
            <h1 className='text-xl font-bold'>Email</h1>
            <p>Receive important alerts for your profile here.</p>
            <h1 className='text-xl font-bold'>Mobile number</h1>
            <p>Receive important alerts for your profile here.</p>
          </div>
          <IoPencil size={20} className='cursor-pointer' onClick={() => showModal('email')} />
        </div>
        <div className='border p-4 flex items-center justify-between rounded'>
          <div>
            <h1 className='text-xl font-bold'>Address</h1>
            <p>Add your address</p>
          </div>
          <IoPencil size={20} className='cursor-pointer' onClick={() => showModalForAddress(true)} />
        </div>
      </div>

      <Modal title="Edit your information" open={isModalOpen} footer={null} onCancel={() => {setIsModalOpen(false)}}>
        {renderModalContent()}
        <div className='flex justify-end mt-3'>
          {
            loading ?
              <div className="flex items-center justify-center">
                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#009F7F]"></div>
              </div>
              :
              <button className="bg-[#009F7F] hover:bg-green-700 text-white font-bold py-2 px-4 rounded" onClick={handleOk}>Save Changes</button>
          }
        </div>
      </Modal>
      <div>
      <AddressModal
          showModal={showModalForAddress}
          handleOk={handleOkForAddress}
          handleCancel={()=> setIsModalOpenForAddress(false)}
          isModalOpen={isModalOpenForAddress}
          isEditMode={isEditModeForAddress}
          Address={address}
        />
        {contextHolder}
      </div>
    </div>
  );
}
