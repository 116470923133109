import { CheckCircle, ContentCopy, Done, Payments } from '@mui/icons-material'
import { Alert, Button } from '@mui/material'
import { useState } from 'react'
import { useAccount} from 'wagmi'

export function Account() {
  const { address,chainId } = useAccount()
  const [copySuccess, setCopySuccess] = useState(false);

  const handleCopyAddress = () => {
    navigator.clipboard.writeText(address);
    setCopySuccess(true);
    setTimeout(() => {
      setCopySuccess(false);
    }, 2000);
  };

    // Get the first 6 characters
    const firstSix = address.slice(0, 6);

    // Get the last 4 characters
    const lastFour = address.slice(-4);
  return (
    <div>

      {
        chainId !== 137 ? (
          <Alert severity='error'>Wrong Network Update to Polygon</Alert>
          )
        :
        (
          <div className='row'>
            <div className='col-12'>
            <Button color='info' variant='outlined' sx={{borderRadius:'50px'}} startIcon={<img src={"icon/mm.png"}  style={{height:'30px'}} />}
            endIcon={copySuccess ? <CheckCircle /> : <ContentCopy />}
            onClick={handleCopyAddress}
            >{`${firstSix}...${lastFour}`}</Button>
            </div>

          </div>
        )
      }
    </div>
  )
}