import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { FaCloudUploadAlt } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { postRequest } from "../../backendServices/ApiCalls";
import { notification } from "antd";
import { useNavigate } from "react-router-dom";

export default function AddShop() {
  const [logoImage, setLogoImage] = useState([]);
  const [bannerImages, setBannerImages] = useState([]);
  const [storeLogoName, setStoreLogoName] = useState(null);
  const [storeBannerName, setStoreBannerName] = useState(null);
  const [storeLogoLink, setStoreLogoLink] = useState(null);
  const [storeBannerLink, setStoreBannerLink] = useState(null);
  const [logoProgress, setLogoProgress] = useState(false);
  const [bannerProgress, setBannerProgress] = useState(false);
  const initialization = { storeTitle: "", tag: "", description: "" };
  const [formData, setFormData] = useState(initialization);
  const [infoProgress, setInfoProgress] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const nav = useNavigate();
  const successNotification = (placement, message, description) => {
    api.success({ message, description, placement });
  };
  const errorNotification = (placement, message, description) => {
    api.error({ message, description, placement });
  };


  const uploadLogo = (file) => {
    // console.log('file', file)
    let formData = new FormData();
    formData.append("image", file);
    setLogoProgress(true);
    postRequest(
      "/uploadlogo",
      formData,
      (response) => {
        if (response?.data?.status === "success") {
          setStoreLogoName(response?.data?.data);
          successNotification("topRight", "Success", "Logo upload");
          setLogoProgress(false);
        } else if (response?.data?.status === "error") {
          setLogoProgress(false);
          errorNotification("topRight", "Error", "Logo not upload");
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };
  // console.log('storeLogoName', storeLogoName)
  // console.log('storeBannerName', storeBannerName)

  const handleDropLogo = (acceptedFiles, setImages) => {
    const newFiles = acceptedFiles.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );
    setImages((prevFiles) => [...prevFiles, ...newFiles]);
    // console.log('newFiles', newFiles[0])
    uploadLogo(newFiles[0]);
    // Log image details to the console
    newFiles.forEach((file) => {
      // console.log(`File: ${file.name}, Size: ${file.size}, Type: ${file.type}`);
    });
  };

  const handleDropHeader = (acceptedFiles, setImages) => {
    const newFiles = acceptedFiles.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );
    setImages((prevFiles) => [...prevFiles, ...newFiles]);
    UploadBanner(newFiles[0]);
    // Log image details to the console
    newFiles.forEach((file) => {
      // console.log(`File: ${file.name}, Size: ${file.size}, Type: ${file.type}`);
    });
  };

  const UploadBanner = (file) => {
    let formData = new FormData();
    formData.append("image", file);
    setBannerProgress(true);
    postRequest(
      "/uploadbanner",
      formData,
      (response) => {
        if (response?.data?.status === "success") {
          setStoreBannerName(response?.data?.data);
          successNotification("topRight", "Success", "Banner upload");
          setBannerProgress(false);
        } else if (response?.data?.status === "error") {
          setBannerProgress(false);
          errorNotification("topRight", "Error", "Banner not upload");
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  const GetStoreData = async (file) => {
    await postRequest("/getstoredetail", '', (response) => {
      if (response?.data?.status === "success") {
        const data = response?.data?.data[0]
        console.log('data', data)
        console.log('data?.store_name', data?.store_name)
        setFormData({
          storeTitle: data.store_name,
          tag: data.tags,
          description: data.store_desc
        });
        console.log('formData', formData);
        setStoreLogoLink(response?.data?.storelogolink);
        setStoreBannerLink(response?.data?.storebannerlink);
        setStoreLogoName(response?.data?.data[0]?.store_logo)
        setStoreBannerName(response?.data?.data[0]?.store_banner_image)
      }
    },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  useEffect(() => {
    // GetStoreData();
  }, []);

  const handleRemoveImage = (indexToRemove, setImages) => {
    setImages((prevFiles) =>
      prevFiles.filter((file, index) => index !== indexToRemove)
    );
  };

  const featuredDropzone = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => handleDropLogo(acceptedFiles, setLogoImage),
    maxFiles: 1,
    disabled: logoImage.length !== 0 ? true : false,
  });

  const galleryDropzone = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => handleDropHeader(acceptedFiles, setBannerImages),
    maxFiles: 1,
    disabled: bannerImages.length !== 0 ? true : false,
  });

  const thumbsLogo = (files, setImages) =>
    files.map((file, index) => (
      <div
        className="inline-flex mb-2 mr- w-24 h-24 p-1 box-border relative"
        key={index}
      >
        {logoProgress ? (
          <div role="status" className="absolute z-10 top-1/4 right-1/3">
            <div className="flex items-center justify-center">
              <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#009F7F]"></div>
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="relative min-w-0 overflow-hidden">
          <IoClose
            size={20}
            className="remove-icon absolute top-0 right-0 cursor-pointer z-10 p-1 rounded-full bg-white bg-opacity-80"
            onClick={() => handleRemoveImage(index, setImages)}
          />
          <img
            src={file.preview}
            className={`block w-auto h-full rounded ${logoProgress ? "brightness-50" : ""
              }`}
            alt=""
          />
        </div>
      </div>
    ));
  const thumbsHeader = (files, setImages) =>
    files.map((file, index) => (
      <div
        className="inline-flex mb-2 mr- w-24 h-24 p-1 box-border relative"
        key={index}
      >
        {bannerProgress ? (
          <div role="status" className="absolute z-10 top-1/4 right-1/3">
            <div className="flex items-center justify-center">
              <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#009F7F]"></div>
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="relative min-w-0 overflow-hidden">
          <IoClose
            size={20}
            className="remove-icon absolute top-0 right-0 cursor-pointer z-10 p-1 rounded-full bg-white bg-opacity-80"
            onClick={() => handleRemoveImage(index, setImages)}
          />
          <img
            src={file.preview}
            className={`block w-auto h-full rounded ${bannerProgress ? "brightness-50" : ""
              }`}
            alt=""
          />
        </div>
      </div>
    ));

  useEffect(() => {
    return () => {
      logoImage.forEach((file) => URL.revokeObjectURL(file.preview));
      bannerImages.forEach((file) => URL.revokeObjectURL(file.preview));
    };
  }, [logoImage, bannerImages]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: name === "tag" ? validateTags(value) : value, // Apply tag validation if the 'tag' field is being updated
    }));
  };

  const validateTags = (tagsString) => {
    const inputTags = tagsString
      .trim()
      .split(",")
      .map((tag) => tag.trim().toLowerCase());
    const validTags = inputTags.slice(0, 3).join(", "); // Allows only the first three tags
    return validTags;
  };

  // console.log('formData', formData);
  // console.log('logoImage, bannerImages', logoImage, bannerImages);
  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log('formData', formData);
    // console.log('logoImage, bannerImages', logoImage, bannerImages);
    if (
      formData.storeTitle == "" ||
      formData.description == "<p><br></p>" ||
      formData.tag == "" ||
      storeLogoName == 0 ||
      storeBannerName == 0
    ) {
      errorNotification("topRight", "Error", "Fill the store detail");
      return;
    }
    if (storeLogoName != null && storeBannerName !== null) {
      let params = {
        storename: formData.storeTitle,
        storelogo: storeLogoName,
        storebanner: storeBannerName,
        description: formData.description,
        tags: formData.tag,
      };
      // console.log('params', params)
      await postRequest(
        "/addstoreinfo",
        params,
        (response) => {
          setInfoProgress(true);
          // console.log('response on submit', response)
          if (response?.data?.status === "success") {
            successNotification("topRight", "Success", response?.data?.message);
            setInfoProgress(false);
            // setFormData(initialization)
            // setBannerImages([])
            // setLogoImage([])
            // nav("/dashboard/home");
          } else if (response?.data?.status === "error") {
            setInfoProgress(false);
            errorNotification("topRight", "Error", response?.data?.message);
          }
        },
        (error) => {
          console.log(error?.response?.data);
        }
      );
    }
  };

  return (
    <div className="">
      {/* <div className="flex pb-5 border-b-2 2xl:mx-20 mx-2 border-dashed border-border-base">
        <h1 className="text-lg font-semibold text-heading">Create Shop</h1>
      </div> */}
      <div className="grid sm:grid-cols-2 gap-x-2">
        <div className="grid gap-y-2 px-2">
          <h4 className="text-base font-semibold text-body-dark">Logo</h4>
          <p className="text-sm text-body">Upload your shop logo from here</p>
          <div className="text-center bg-white p-2 rounded">
            {
              logoImage.length === 0 &&
              <div {...featuredDropzone.getRootProps({ className: "dropzone py-2 border-2 border-dashed border-gray-400 rounded", })}>
                <input {...featuredDropzone.getInputProps()} />
                <p className="mt-4 text-sm text-center text-body flex items-center flex-col">
                  <FaCloudUploadAlt color="#D1D5DB" size={50} />
                  <span className="font-semibold text-[#019477]">
                    Upload a logo
                  </span>{" "}
                  or drag and drop <br />
                  <span className="text-xs text-body">PNG, JPG</span>
                </p>
              </div>
            }
            <aside className="flex flex-row flex-wrap mt-4">
              {thumbsLogo(logoImage, setLogoImage)}
            </aside>
          </div>
        </div>
        <div className="grid gap-y-2 px-2">
          <h4 className="text-base font-semibold text-body-dark">Cover Image</h4>
          <p className="text-sm text-body">Upload your shop cover image from here</p>
          <div className="text-center bg-white p-2 rounded">
            {
              bannerImages.length === 0 &&
              <div {...galleryDropzone.getRootProps({ className: "dropzone py-2 border-2 border-dashed border-gray-400 rounded", })}>
                <input {...galleryDropzone.getInputProps()} />
                <p className="mt-4 text-sm text-center text-body flex items-center flex-col">
                  <FaCloudUploadAlt color="#D1D5DB" size={50} />
                  <span className="font-semibold text-[#019477]">
                    Upload a cover
                  </span>
                  or drag and drop <br />
                  <span className="text-xs text-body">PNG, JPG</span>
                </p>
              </div>
            }
            <aside className="flex flex-row flex-wrap mt-4">
              {thumbsHeader(bannerImages, setBannerImages)}
            </aside>
          </div>
        </div>
      </div>
      <div className="grid">
        <div>
          <h4 className="text-base font-semibold text-body-dark mb-2">Basic Info</h4>
          <p className="text-sm text-body">Add some basic info about your shop from here</p>
        </div>
        <form>
          <div className="grid grid-cols-2 gap-x-2">
            <div>
              <label htmlFor="storeTitle" className="block mb-1 font-semibold text-body-dark">Name*</label>
              <input type="text" id="storeTitle" name="storeTitle" value={formData.storeTitle} onChange={handleChange}
                className="w-full border border-gray-300 rounded-md px-3 py-2 mb-3 focus:outline-none focus:border-blue-500" />
            </div>
            <div>
              <label htmlFor="tag" className="block mb-1 font-semibold text-body-dark" >Tag*</label>
              <input type="text" id="tag" name="tag" value={formData.tag} onChange={handleChange} placeholder="Enter tags separated by commas (tag1, tag2, tag3)"
                className="w-full border border-gray-300 rounded-md px-3 py-2 mb-3 focus:outline-none focus:border-blue-500" />
            </div>
          </div>
          <div>
            <label htmlFor="description" className="block mb-1 font-semibold text-body-dark" >Description*</label>
            <ReactQuill id="description" value={formData.description} onChange={(value) => { setFormData({ ...formData, description: value }); }}
              className="border border-gray-300 rounded-md mb-3 focus:outline-none focus:border-blue-500" />
          </div>
        </form>
      </div>
      <div className="text-end">
        {infoProgress ? (
          <button>
            <span className="flex items-center justify-center">
              <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#009F7F]"></div>
            </span>
          </button>
        ) : (
          <button onClick={handleSubmit} type="submit" className="bg-[#019376] hover:bg-green-800 text-white font-bold py-4 px-6 rounded">
            Add Shop
          </button>
        )}
      </div>

      <div>{contextHolder}</div>
    </div>
  );
}
