import React, { useContext, useEffect, useState } from 'react'
import { passjwt, postRequest } from '../../backendServices/ApiCalls';
import { Link, useNavigate } from 'react-router-dom';
import { notification } from 'antd';
import { MdEmail } from "react-icons/md";
import { FaFacebook, FaGoogle } from "react-icons/fa";
import { UserContext } from '../../contexts/UserContext';

import { GoogleLogin } from '@react-oauth/google';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import FacebookLogin from 'react-facebook-login';
export default function Login() {
    // if login from here it will shopuser will be 1 and one more loginType
    const [userGoogle, setUserGoogle] = useState([]);
    const [googleProfile, setGoogleProfile] = useState([]);
    const { user, setUser } = useContext(UserContext);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [resp, setresp] = useState({})
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [api, contextHolder] = notification.useNotification();
    const errorNotification = (placement, message, description) => {
        api.error({ message, description, placement })
    };
    function generateUUID() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'?.replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0;
            const v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }
    const handleElevated = () => {
        const width = 600;
        const height = 400;
        const left = (window.innerWidth / 2) - (width / 2);
        const top = (window.innerHeight / 2) - (height / 2);

        window.open(
            `https://mptest.elevatedmarketplace.world/elevated-auth/${generateUUID()}`, // URL to open
            'Sign In', // Name of the window (can be any string)
            `width=${width},height=${height},left=${left},top=${top},toolbar=no,location=no,menubar=no,scrollbars=yes,resizable=yes`
        );
        setLoading(true)
        // let i = 1;
        const intervalId = setInterval(() => {
            passjwt("", async (response) => {
                setresp(response?.data)
                const { username, genetemptoken, status, message } = response?.data
                if (status === "success") {
                    navigate(`verifyAuth/${genetemptoken}`)
                    clearInterval(intervalId);
                    setLoading(false)
                }
            },
                (error) => {
                    console.log(error?.response?.data);
                    setLoading(false)
                });
            // i++;
        }, 5000);

        setTimeout(() => {
            clearInterval(intervalId);
            if (resp?.status == 'error') {
                errorNotification('topRight', 'Error', resp?.message + ". Please try again")
            }
            setLoading(false)
        }, 20000);
    };
    const handleEmail = (event) => {
        event.preventDefault();
        // console.log('username:', username);
        // console.log('Password:', password);
        let param = {
            username: username,
            password: password
        }

        // console.log('param', param);
        setLoading(true);
        postRequest("/loginEmail", param, async (response) => {
            // console.log("response?.data", response?.data);
            if (response?.data?.status === "success") {
                localStorage.setItem("token", response?.data?.token)
                setUser(response?.data?.user)
                navigate('/')
                // window.location.href = `http://localhost:3001/`
                // setCategoryHook(response?.data?.data)
                // successNotification('topRight', 'Success', response?.data?.message)
                setLoading(false);
            }
            if (response?.data?.status === "error") {
                // setCategoryHook(response?.data?.data)
                errorNotification('topRight', 'Error', response?.data?.message)
                setLoading(false);
            }
        },
            (error) => {
                console.log(error?.response?.data);
                setLoading(false);
            }
        );
    };
    const GoogleLogin = useGoogleLogin({
        onSuccess: (codeResponse) => setUserGoogle(codeResponse),
        onError: (error) => console.log('Login Failed:', error)
    });
    useEffect(() => {
        if (userGoogle) {
            fetch(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${userGoogle.access_token}`, {
                headers: {
                    Authorization: `Bearer ${userGoogle.access_token}`,
                    Accept: 'application/json'
                }
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => {
                    setGoogleProfile(data);
                    handleGoogle(data)
                })
                .catch(error => console.log(error));
        }
    }, [userGoogle]);
    // console.log('googleProfile', googleProfile)
    const handleGoogle = (data) => {
        let param = {
            email: data.email,
            username: data.id,
            firstName: data.given_name,
            LastName: data.family_name,
        }
        // console.log('param', param);
        setLoading(true);
        postRequest("/googleLoginStoreData", param, async (response) => {
            // console.log("response?.data", response?.data);
            if (response?.data?.status === "success") {
                localStorage.setItem("token", response?.data?.token)
                setUser(data)
                navigate('/')
                // successNotification('topRight', 'Success', response?.data?.message)
                setLoading(false);
            }
            if (response?.data?.status === "error") {
                // setCategoryHook(response?.data?.data)
                // errorNotification('topRight', 'Error', response?.data?.message)
                setLoading(false);
            }
        },
            (error) => {
                console.log(error?.response?.data);
                setLoading(false);
            }
        );
    };
    // const logOut = () => {
    //     googleLogout();
    //     setGoogleProfile(null);
    // };
    const responseFacebook = (response) => {
        if (response?.status === "unknown") {
            console.error('Sorry!', 'Something went wrong with facebook Login.');
            return;
        }
        console.log(response);
    }

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100">
            <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md text-center">
                <h2 className="text-xl mb-6 text-gray-800">Continue with Elevated market place</h2>
                {
                    loading ?
                        <div className="flex items-center justify-center">
                            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#009F7F]"></div>
                        </div>
                        :
                        <div className='flex flex-col gap-2'>
                            <button className="bg-[#009F7F] hover:bg-green-700 text-white font-bold py-2 px-4 rounded flex justify-center items-center gap-2" onClick={handleElevated}>
                                <img src="/icon/loginlogo.png" className='w-5' alt="logo" />
                                Elevated
                            </button>
                            <button className="bg-[#009F7F] hover:bg-green-700 text-white font-bold py-2 px-4 rounded flex justify-center items-center gap-2" onClick={GoogleLogin}>
                                <FaGoogle />
                                Continue With Google
                            </button>
                            {/* <FacebookLogin
                                appId="1499080024329016" // Replace with your Facebook App ID
                                autoLoad={false}
                                fields="name,email,picture"
                                callback={responseFacebook}
                                icon="fa-facebook"
                            /> */}
                            <form onKeyDown={(event)=> {event.key === 'Enter' && handleEmail(event)}} className='flex flex-col gap-y-2'>
                                <input type="text" name='username' value={username} onChange={(e) => setUsername(e.target.value)} placeholder="User Name" className="w-full p-2 border rounded focus:outline-none focus:border-[#009F7F]" />
                                <input type="password" name='password' value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" className="w-full p-2 border rounded focus:outline-none focus:border-[#009F7F]" />
                                <button className="bg-[#009F7F] hover:bg-green-700 text-white font-bold py-2 px-4 rounded flex justify-center items-center gap-2 w-full" onClick={handleEmail}>
                                    <MdEmail />
                                    Continue With User Name
                                </button>
                            </form>
                        </div>
                }
                <div className='flex justify-around'>
                    <Link to='/register' className='py-5 underline hover:text-[#009F7F]'>Register</Link>
                    <Link to='/forgetpassword' className='py-5 underline hover:text-[#009F7F]'>Forget Password</Link>
                </div>
            </div>
            <div>
                {contextHolder}
            </div>
        </div>
    )
}
