import { Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import { UserContext } from './contexts/UserContext';
import { useContext } from 'react';
import Buyer from "./Pages/buyer/index";
import Seller from "./Pages/seller/index";
import PrivateRoute from './Pages/PrivateRoute';
import Login from './Pages/auth/Login';
import VerifyAuth from './Pages/auth/VerifyAuth';
import Register from './Pages/auth/Register';
import ForgetPassword from './Pages/auth/ForgetPassword';
import SellerRegister from './Pages/auth/AddSellerRegister';
import { config } from './Pages/web3/chainConfig';
import { WagmiProvider } from 'wagmi';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import AddProductSection from './Pages/buyer/AddProductSection';
import PrivateRouteForBuyer from './Pages/PrivateRouteForBuyer';

function App() {
  const { user } = useContext(UserContext);
  // console.log('user', user)
  const queryClient = new QueryClient();

  return (
    <>
      {/* <Navbar /> */}
      {/* <Layout /> */}
      <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/login/verifyAuth/:token" element={<VerifyAuth />} />
        <Route path="/register" element={<Register />} />
        <Route path="/sellerRegister" element={<SellerRegister />} />
        <Route path="/forgetpassword" element={<ForgetPassword />} />
        <Route path="/productsection" element={<PrivateRouteForBuyer Component={AddProductSection} />} />

        {/* Home page accessible to everyone */}
        <Route path="/*" element={<Buyer />} />

        {/* Protected route for Seller */}
        <Route path="/dashboard/*" element={<PrivateRoute Component={Seller} />} />

        {/* Redirect to home page for any other routes */}
        <Route path="/*" element={<Navigate to="/" />} />
      </Routes>
      </QueryClientProvider>
      </WagmiProvider>
    </>
  );
}

export default App;
