import { Button } from '@mui/material'
import * as React from 'react'
import { useConnect } from 'wagmi'
import { getAccount } from '@wagmi/core'
import { config } from './chainConfig'

export function WalletOptions() {
  const { connectors, connect } = useConnect()
  const {isConnecting} = getAccount(config)

  return (
    <div>
      {
        connectors.length < 2 ? (
          <a href='https://metamask.io/download/' target='_blank'>Install MetaMask</a>
        )
        :
        null
      }
      {connectors.map((connector) => {
        // console.log('connector', connector)

          return (
            <>
            {
              connector?.id === "io.metamask" ? (
                <Button
                color="success" 
                onClick={() => connect({ connector })}
                variant="contained" 
                sx={{width:'300px', borderRadius:'50px', fontSize:'25PX', fontWeight:'bold'}}
                key={connector.uid}                           
                startIcon={<img src={connector.icon}  height={40} />}
                >
                  {isConnecting ? 'connecting...' : connector.name}
                </Button>

              )
              :
             null
            }
          </>
          )


      })}
    </div>
  )
}
