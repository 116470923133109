import React, { createContext, useState, useEffect } from 'react';
import { loginVerify } from '../backendServices/ApiCalls';

const UserContext = createContext();

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(false);

  const fetchUserFromAPI = async (token) => {
    let para = {
      accesstoken:token
    }
    loginVerify(para, async (response) => {
      const { user } = response?.data
      // console.log('user in context hook', user);
      if (response?.data?.status === "success") {
        setUser(user)
        if(user.seller == 0 || (user.seller == 1 && user.sellerApprove == 'pending')) {
          sessionStorage.setItem('role', "buyer")
          // console.log('the user is buyer')
        }
        if(user.seller == 1 && user.sellerApprove == 'approved') {
          sessionStorage.setItem('role', "seller")
          // console.log('the user is seller and approved')
        }
      }
      if (response?.data?.status === "error") {
        localStorage.removeItem('token')
      }
    },
      (error) => {
        console.log(error?.response?.data);
        if (error?.response?.data?.message === "token_expired") {
          localStorage.removeItem('token')
          // setUser(false)
        }
      });
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      fetchUserFromAPI(token);
    }
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
