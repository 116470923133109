import { DataGrid } from "@mui/x-data-grid";
import React, { useContext, useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import IconButton from "@mui/material/IconButton";
import { postRequest } from "../../backendServices/ApiCalls";
import { UserContext } from "../../contexts/UserContext";
import { notification } from "antd";
import { useNavigate } from "react-router-dom";

function AllProducts() {
  const [api, contextHolder] = notification.useNotification();
  const successNotification = (placement, message, description) => {
    api.success({ message, description, placement })
  };
  const errorNotification = (placement, message, description) => {
    api.error({ message, description, placement })
  };
  const { user, setUser } = useContext(UserContext);
  const [products, setProduct] = useState([]);
  const [link, setLink] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()
  const productsFun = () => {
    
    postRequest("/getUserProduct", "", async (response) => {
      setLoading(true);
      // console.log("response?.data", response?.data?.data);
      if (response?.data?.status === "success") {
        setProduct(response?.data?.data);
        setLink(response?.data?.link);
        setLoading(false);
      }
    },
      (error) => {
        console.log(error?.response?.data);
        setLoading(false);
      }
    );
  };
  useEffect(() => {
    productsFun();
  }, []);

  const handleDelete = (productId) => {
    let param = {
      id: productId,
    }
    postRequest("/UpdateStatusProduct", param, async (response) => {
      setLoading(true);
      if (response?.data?.status === "success") {
        successNotification('topRight', 'Success', response?.data?.message);
        productsFun();
      }
    },
      (error) => {
        console.log(error?.response?.data);
        errorNotification('topRight, error', error?.response?.message);
      }
    );
  }
  const initialState = {
    initialState: {
      columns: {
        columnVisibilityModel: {
          product: false,
          title: false,
          store_name: false,
          price: false,
          actions: false,
        },
      },
    },
  };
  const gridDesign = {
    "& .MuiDataGrid-toolbarContainer": {
      "& .MuiButton-text": {
        fontSize: "13px !important",
        color: "#000",
      },
      "& .MuiBadge-badge": {
        backgroundColor: "#074682",
      },
      "& .MuiInput-root": {
        borderRadius: 2,
        paddingLeft: 2,
        overflow: "hidden",
      },
    },
  };
  const columns = [
    {
      field: "product",
      headerName: "Product",
      dataGeneratorUniquenessEnabled: true,
      width: 100,
      editable: false,
      groupable: false,
      aggregable: false,
      renderCell: (params) => {
        let firstImageUrl = '';
        if (params.row.picture) {
          try {
            const pictureData = JSON.parse(params.row.picture);
            if (pictureData.imageNames && pictureData.imageNames.length > 0) {
              firstImageUrl = link + pictureData.imageNames[0];
            }
          } catch (e) {
            console.error("Invalid JSON in item.picture", e);
          }
        }
        return (
          <div className="flex items-center">
            <img src={firstImageUrl} alt="Shop" className="rounded-full w-10 h-10 mr-2 mt-1.5" />
            {/* <span>{params.value}</span> */}
          </div>
        );
      },
    },
    {
      field: "title",
      headerName: "Title",
      dataGeneratorUniquenessEnabled: true,
      width: 250,
      editable: false,
      groupable: false,
      aggregable: false,
    },
    {
      field: "store_name",
      headerName: "Shop Name",
      dataGeneratorUniquenessEnabled: true,
      width: 250,
      editable: false,
      groupable: false,
      aggregable: false,
    },
    {
      field: "price",
      headerName: "Price",
      width: 250,
      editable: false,
      groupable: false,
      aggregable: false,
      renderCell: (params) => (
        <span>${params.value}</span>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 250,
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            paddingTop: "6px",
            width: "100%",
          }}
        >
          {/* <IconButton aria-label="view">
            <VisibilityIcon />
          </IconButton> */}
          <IconButton aria-label="edit" onClick={() => navigate(`/dashboard/updateProduct/${params.row.id}`)}>
            <EditIcon />
          </IconButton>
          <IconButton aria-label="delete" onClick={() => handleDelete(params.row.id)}
            size="small"
            style={{ color: "#ef4444" }}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];
  const rows = products

  return (
    <div className="bg-white rounded mt-48 mx-2 sm:mt-36 md:mt-32 p-2">
        <p className="font-semibold text-lg mb-3 border-l-4 h-6 pl-6 border-green-700">
          Your Listings
        </p>
      <DataGrid
        className="custom-border"
        initialState={{
          initialState,
          pagination: { paginationModel: { pageSize: 6 } },
        }}
        rows={rows}
        columns={columns}
        sx={gridDesign}
        pageSizeOptions={[6, 12, 18, 24, 30]}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
      />
      <div>
        {contextHolder}
      </div>
    </div>
  );
}

export default AllProducts;
