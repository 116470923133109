import React, { useState } from 'react'
import { FaRegImage } from 'react-icons/fa6';
import { Link } from 'react-router-dom';

export default function TrendingProducts({ design, title, thumbnails, link, categoryIdsName }) {
  // console.log('categoryIdsName.length', categoryIdsName.length)
  let jsonStr = thumbnails.replace(/\\/g, '');
  let arrayImages = JSON.parse(jsonStr);
  return (
    <>
      {categoryIdsName.length == 1 &&
        <ShowOne
          title={title}
          arrayImages={arrayImages}
          categoryIdsName={categoryIdsName}
          link={link}
        />}
      {categoryIdsName.length == 4 && design.includes('2,2') &&
        (<ShowFourOne
          title={title}
          arrayImages={arrayImages}
          categoryIdsName={categoryIdsName}
          link={link}
        />)}
      {categoryIdsName.length == 4 && design.includes('1,3') &&
        (<ShowFourTwo
          title={title}
          arrayImages={arrayImages}
          categoryIdsName={categoryIdsName}
          link={link}
        />)}
    </>
  )
}

function ShowOne({ title, arrayImages, categoryIdsName, link }) {
  const [isImageLoading, setIsImageLoading] = useState(true);
  const handleImageLoad = () => setIsImageLoading(false);
  return (
    <div className="bg-white p-5 border-0 rounded h-full">
      <h1 className="font-bold text-lg">{title}</h1>
      <div className="grid grid-cols-1 gap-2 h-full">
        <div className="flex flex-col gap-y-2 h-full">
          {
            arrayImages.map((product, index) => {
              return (
                <Link to={`/product/${categoryIdsName[index].toLowerCase().replace(/\s+/g, "-")}`} key={index} className={`flex flex-col gap-y-2 hover:text-[#0da889] ${isImageLoading && "h-[80%]"}`}>
                  <img onLoad={handleImageLoad} src={link + product} className='w-auto h-full'
                  // alt={categoryIdsName[index]}
                  />
                  {isImageLoading && <div className="custom-skeleton rounded w-full h-full flex items-center justify-center"><FaRegImage color="#BFBFBF" size={50} /></div>}
                  <h2 title={categoryIdsName[index]} className='truncate text-base'>{categoryIdsName[index]}</h2>
                </Link>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}

function ShowFourOne({ title, arrayImages, categoryIdsName, link }) {
  const [isImageLoading, setIsImageLoading] = useState(true);
  const handleImageLoad = () => setIsImageLoading(false);
  return (
    <div className='bg-white p-5 border-0 rounded h-full'>
      <h1 className='font-bold text-lg'>{title}</h1>
      <div className='grid grid-cols-2 gap-2 h-full'>
        {
          arrayImages.map((product, index) => {
            return (
              <Link to={`/product/${categoryIdsName[index].toLowerCase().replace(/\s+/g, "-")}`} key={index} className={`flex flex-col gap-y-2 hover:text-[#0da889] ${isImageLoading && "h-[80%]"}`}>
                <img onLoad={handleImageLoad} src={link + product} className='w-auto'
                //  alt={categoryIdsName[index]}
                />
                {isImageLoading && <div className="custom-skeleton rounded w-full h-full flex items-center justify-center"><FaRegImage color="#BFBFBF" size={50} /></div>}
                <h2 title={categoryIdsName[index]} className='truncate text-xs font-medium'>{categoryIdsName[index]}</h2>
              </Link>
            )
          })
        }
      </div>
    </div>
  )
}

function ShowFourTwo({ title, arrayImages, categoryIdsName, link }) {
  const [isImageLoading, setIsImageLoading] = useState(true);
  const handleImageLoad = () => setIsImageLoading(false);
  return (
    <div className="bg-white p-5 border-0 rounded h-full">
      <h1 className="font-bold text-lg">{title}</h1>
      <div className="grid grid-cols-1 gap-2 h-full">
        <Link to={`/product/${categoryIdsName[0].toLowerCase().replace(/\s+/g, "-")}`} className={`flex flex-col gap-y-2 hover:text-[#0da889] ${isImageLoading && "h-[80%]"}`}>
          <img onLoad={handleImageLoad} src={link + arrayImages[0]} className="w-auto"
          // alt={categoryIdsName[0]}
          />
          {isImageLoading && <div className="custom-skeleton rounded w-full h-full flex items-center justify-center"><FaRegImage color="#BFBFBF" size={50} /></div>}
          <h2 className="truncate text-base">{categoryIdsName[0]}</h2>
        </Link>
        <div className="grid grid-cols-3 gap-2 h-full">
          {arrayImages.slice(1, 4).map((product, index) => {
            return (
              <Link to={`/product/${categoryIdsName[index + 1].toLowerCase().replace(/\s+/g, "-")}`} key={index} className={`flex flex-col gap-y-2 hover:text-[#0da889] ${isImageLoading && "h-[80%]"}`}>
                <img onLoad={handleImageLoad} src={link + product} className="w-auto"
                //  alt={categoryIdsName[index + 1]}
                />
                {isImageLoading && <div className="custom-skeleton rounded w-full h-full flex items-center justify-center"><FaRegImage color="#BFBFBF" size={50} /></div>}
                <h2 className="truncate text-base">{categoryIdsName[index + 1]}</h2>
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  )
}