import React, { useEffect, useState } from "react";
import ProductCard from "../../components/ProductCard";
import { getallproducts, postRequest } from "../../backendServices/ApiCalls";

import "swiper/css";
import "swiper/css/pagination";
import { useNavigate } from "react-router-dom";

function BuyAgain() {
  const [loading, setLoading] = useState(false);
  const nav = useNavigate();

  // console.log("cart", cart);
  // products
  const [products, setProduct] = useState([]);
  const [link, setLink] = useState();
  const productsFun = () => {
    postRequest("/buyagain","",async (response) => {
        setLoading(true);
        // console.log("response?.data", response?.data);
        if (response?.data?.status === "success") {
          setProduct(response?.data?.data);
          setLink(response?.data?.link);
          setLoading(false);
        }
        if (response?.data?.status === "error") {
          setLoading(false);
        }
      },
      (error) => {
        console.log(error?.response?.data);
        setLoading(false);
      } 
    );
  };
  useEffect(() => {
    productsFun();
    // const checkout_login_session = sessionStorage.getItem("checkout_login");
    // // console.log('checkout_login_session', checkout_login_session)
    // if (checkout_login_session) {
    //   nav("/checkout");
    //   sessionStorage.removeItem("checkout_login");
    // }
  }, []);
  return (
    <div className="sm:mt-40 md:mt-auto">
      <h2 className="text-lg font-semibold">Recent Order Details: Enhancing Your Experience with <span className="text-[#009F7F]">Elevated Market Place</span></h2>
      {loading ? (
        <div className="flex items-center justify-center">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#009F7F]"></div>
        </div>
      ) : (
        <div className="grid py-6 sm:grid-cols-2 md:grid-cols-2 gap-4 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5  grid-cols-1">
          {products.map((product, index) => (
            <ProductCard
              key={index}
              // discount={product.discount}
              id={product?.id}
              shopid={product?.shopid}
              imgSrc={product?.picture}
              link={link}
              title={product?.title?.replace(/\\/g, "")}
              // weight={product?.weight}
              originalPrice={product?.price}
              description={product?.description?.replace(/\\/g, "")}
              storeName={product?.store_name}
              category={product?.category}
              productQuantity={product?.quantity}
              size={product?.size}
              weight={product?.weight}
              // discountedPrice={product.discountedPrice}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default BuyAgain;
