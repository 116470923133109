import React, { useEffect, useState } from "react";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import {
  DataGrid,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { IoPencilSharp } from "react-icons/io5";
import { postRequest } from "../../backendServices/ApiCalls";

function PurchasedProduct() {
  const [ordersData, setOrdersData] = useState([])

  const initialState = {
    initialState: {
      columns: {
        columnVisibilityModel: {
          trackingNumber: false,
          customer: false,
          products: false,
          orderDate: false,
          total: false,
          status: false,
          actions: false,
        },
      },
    },
  };

  const gridDesign = {
    "& .MuiDataGrid-toolbarContainer": {
      "& .MuiButton-text": {
        fontSize: "13px !important",
        color: "#000",
      },
      "& .MuiBadge-badge": {
        backgroundColor: "#074682",
      },
      "& .MuiInput-root": {
        borderRadius: 2,
        paddingLeft: 2,
        overflow: "hidden",
      },
    },
  };
  // Otherwise filter will be applied on fields such as the hidden column id
  const columns = [
    {
      field: "orderId",
      headerName: "Tracking Number",
      dataGeneratorUniquenessEnabled: true,
      width: 180,
      editable: false,
      groupable: false,
      aggregable: false,
    },
    {
      field: "firstname",
      headerName: "Customer",
      dataGeneratorUniquenessEnabled: true,
      width: 180,
      editable: false,
      groupable: false,
      aggregable: false,
      renderCell: (params) => `${params.row.firstname} ${params.row.lastname}`

    },
    {
      field: "date",
      headerName: "Order Date",
      dataGeneratorUniquenessEnabled: true,
      width: 180,
      editable: false,
      groupable: false,
      aggregable: false,
    },
    {
      field: "amount",
      headerName: "Total Amount",
      width: 130,
      editable: false,
      groupable: false,
      aggregable: false,
      renderCell: (params) => `$${params.value}`
    },
    {
      field: "status",
      headerName: "Status",
      dataGeneratorUniquenessEnabled: true,
      width: 180,
      editable: false,
      groupable: false,
      aggregable: false,
    },
    {
      field: "actions",
      headerName: "Actions",
      dataGeneratorUniquenessEnabled: true,
      width: 180,
      editable: false,
      groupable: false,
      aggregable: false,
      renderCell: (params) => (
        <Link to={`/order/${params.row.orderId}`}>
          <MdOutlineRemoveRedEye size={20} className="ml-4 mt-3 cursor-pointer " />
          </Link>
      ),
    },
  ];

  const getOrders = () => {
    
    postRequest("/purchasedProduct", "", async (response) => {
      // console.log("response?.data", response?.data?.data);
      if (response?.data?.status === "success") {
        setOrdersData(response?.data?.data);
      }
    },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };
  
  useEffect(() => {
    getOrders();
  }, []);

  const rows = ordersData 

  return (
    <div>
      <div className="bg-white rounded-lg mt-8  p-8">
        <DataGrid
          className="custom-border"
          initialState={{
            initialState,
            pagination: { paginationModel: { pageSize: 6 } },
          }}
          rows={rows}
          columns={columns}
          // slots={{ toolbar: GridToolbarQuickFilter }}
          slots={{ toolbar: CustomToolbar }}
          sx={gridDesign}
          pageSizeOptions={[6, 12, 18, 24, 30]}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </div>
    </div>
  );
}

export default PurchasedProduct;

// function CustomToolbar() {
//   return (
//     <>
//       <div className="flex justify-between items-center">
//         <div className="pl-8 pb-0 border-l-4 h-6 border-green-700 -m-8">
//           <p className="font-semibold text-base">Purchased Product</p>
//         </div>
//         <GridToolbarQuickFilter debounceMs={500} />
//       </div>
//     </>
//   );
// }

function CustomToolbar() {
  return (
    <>
      <div className="block sm:hidden">
        <div className="flex flex-col pt-4">
          <div className="pl-8 pb-0 border-l-4 h-6 border-green-700 -m-8">
            <p className="font-semibold text-base">Purchased Product</p>
          </div>
          <br /><br /><br />
          <GridToolbarQuickFilter debounceMs={500} />
        </div>
      </div>
      <div className="hidden sm:block">
        <div className="flex justify-between items-center">
          <div className="pl-8 pb-0 border-l-4 h-6 border-green-700 -m-8">
            <p className="font-semibold text-base">Purchased Product</p>
          </div>
          <GridToolbarQuickFilter debounceMs={500} />
        </div>
      </div>
    </>
  );
}