import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { postRequest } from "../../backendServices/ApiCalls";
import { notification, Rate } from "antd";

function OrderDetail() {
  const [api, contextHolder] = notification.useNotification();
  const errorNotification = (placement, message, description) => {
    api.error({ message, description, placement })
  };
  const successNotification = (placement, message, description) => {
    api.success({ message, description, placement });
  };
  const { orderid } = useParams();
  const [orderData, setOrdersData] = useState([])
  const [selectedId, setSelectedId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rate, setRate] = useState(0);
  const [review, setReview] = useState("");
  const [reviewDisable, setReviewDisable] = useState(false);
  const getOrderData = () => {

    postRequest("/orderdetails", { orderid }, async (response) => {
      // console.log("response?.data", response?.data?.data);
      if (response?.data?.status === "success") {
        setOrdersData(response?.data);
      }
    },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };
  useEffect(() => {
    getOrderData();
  }, []);
if(orderData.length < 1)
  {
    return ''
  } 
  let totalAmount = 0

  const handleOpenModal = (productId) => {
    setSelectedId(productId);
    setIsModalOpen(true);
    getReview(productId);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedId(null);
    setRate(0);
    setReview("");
  };

  const getReview = (productId) => {
    setReviewDisable(false)
    postRequest("/getSingleProductReview", { productid: productId }, async (response) => {
      // console.log("response?.data", response?.data?.data);
      if (response?.data?.status === "success") {
        setReview(response?.data?.data[0]?.review)
        setRate(response?.data?.data[0]?.star)
        if (response?.data?.data !== undefined) {
          setReviewDisable(true)
        }
      }
    },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  }

  const handleReview = () => {
    if (rate === 0) {
      errorNotification('topRight', 'Error', 'Please select a rating')
      return;
    }
    if (review.trim() === "") {
      errorNotification('topRight', 'Error', 'Please enter a review')
      return;
    }
    let para = {
      productid: selectedId,
      star: rate,
      review: review
    }
    // console.log('para', para);
    postRequest("/productReview", para, async (response) => {
      // console.log("response?.data", response?.data);
      if (response?.data?.status === "success") {
        successNotification('topRight', 'Success', response?.data?.message)
        // getReview();
        handleCloseModal();
        setSelectedId(null)
        setRate(0)
        setReview("")
      }
      if (response?.data?.status === "error") {
        errorNotification('topRight', 'Error', response?.data?.message)
        // getReview();
        handleCloseModal();
        setSelectedId(null)
        setRate(0)
        setReview("")
      }
    },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  return (
    <div className="m-2 mt-48 mx-2 sm:mt-36 md:mt-32 overflow-x-hidden">
      <div className=" mt-4   shadow-md">
        <div className="flex px-6 py-3 bg-[#F7F8FA] justify-between">
          <div>
            <span class="mb-2 block font-semibold text-lg lg:mb-0 lg:inline-block lg:ltr:mr-4 lg:rtl:ml-4">
              Order Status :
            </span>
            <span class="inline-block px-3 py-1.5 capitalize rounded text-md whitespace-nowrap relative font-medium text-[#00A186] bg-[#bfe1df]  bg-opacity-10 !text-accent">
              {orderData?.data[0]?.status}
            </span>
          </div>
          <div>
            <span class="mb-2 block text-lg lg:mb-0 font-semibold lg:inline-block lg:ltr:mr-4 lg:rtl:ml-4">
              Payment Status :
            </span>
            <span class="inline-block px-3 py-1.5 rounded text-md whitespace-nowrap relative font-medium text-[#00A186] bg-[#bfe1df] bg-opacity-10 !text-accent">
              Payment Success
            </span>
          </div>
        </div>
        <div className="bg-white p-6">
          {/* <div className="justify-between  flex ">
            <div></div>
            <button
              data-variant="normal"
              class="inline-flex items-center my-2 mr-4 text-white justify-center flex-shrink-0 font-semibold leading-none rounded outline-none transition duration-300 ease-in-out focus:outline-none focus:shadow focus:ring-1 focus:ring-accent-700 text-light border border-transparent hover:bg-accent-hover px-5 py-0 h-12 mb-5 bg-blue-500 ltr:ml-auto rtl:mr-auto"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 548.176 548.176"
                class="h-4 w-4 me-3"
              >
                <path
                  d="M524.326 297.352c-15.896-19.89-36.21-32.782-60.959-38.684 7.81-11.8 11.704-24.934 11.704-39.399 0-20.177-7.139-37.401-21.409-51.678-14.273-14.272-31.498-21.411-51.675-21.411-18.083 0-33.879 5.901-47.39 17.703-11.225-27.41-29.171-49.393-53.817-65.95-24.646-16.562-51.818-24.842-81.514-24.842-40.349 0-74.802 14.279-103.353 42.83-28.553 28.544-42.825 62.999-42.825 103.351 0 2.474.191 6.567.571 12.275-22.459 10.469-40.349 26.171-53.676 47.106C6.661 299.594 0 322.43 0 347.179c0 35.214 12.517 65.329 37.544 90.358 25.028 25.037 55.15 37.548 90.362 37.548h310.636c30.259 0 56.096-10.711 77.512-32.12 21.413-21.409 32.121-47.246 32.121-77.516-.003-25.505-7.952-48.201-23.849-68.097zm-161.731 10.992L262.38 408.565c-1.711 1.707-3.901 2.566-6.567 2.566-2.664 0-4.854-.859-6.567-2.566L148.75 308.063c-1.713-1.711-2.568-3.901-2.568-6.567 0-2.474.9-4.616 2.708-6.423 1.812-1.808 3.949-2.711 6.423-2.711h63.954V191.865c0-2.474.905-4.616 2.712-6.427 1.809-1.805 3.949-2.708 6.423-2.708h54.823c2.478 0 4.609.9 6.427 2.708 1.804 1.811 2.707 3.953 2.707 6.427v100.497h63.954c2.665 0 4.855.855 6.563 2.566 1.714 1.711 2.562 3.901 2.562 6.567 0 2.294-.944 4.569-2.843 6.849z"
                  fill="currentColor"
                ></path>
              </svg>
              Download Invoice
            </button>
          </div> */}
          <div className="flex justify-between mr-4">
            <h3 class="mb-8 w-full whitespace-nowrap text-center text-lg sm:text-2xl font-semibold text-heading lg:mb-0 lg:w-1/3 lg:text-start">
              Order ID - {orderid}
            </h3>
            {/* <button
              data-variant="normal"
              class="inline-flex items-center bg-[#019376] justify-center flex-shrink-0 font-semibold leading-none rounded outline-none transition duration-300 ease-in-out focus:outline-none focus:shadow focus:ring-1 focus:ring-accent-700 bg-accent text-light border border-transparent hover:bg-accent-hover px-5 py-0 h-12"
            >
              <span class="hidden text-white sm:block">Change Status</span>
            </button> */}
          </div>
          {/* <div className="flex justify-between mr-4 mt-10 py-2 rounded-md px-10 bg-[#F7F8FA] ">
            <p className="text-lg font-semibold">Products</p>
            <p className="text-lg font-semibold">Total</p>
            <p className="text-lg font-semibold">Review</p>
          </div>
          {
              orderData?.data.map((odata) => {
                const pictureData = JSON.parse(odata.picture);
                let firstImageUrl = '';
                if (pictureData.imageNames && pictureData.imageNames.length > 0) {
                  firstImageUrl = orderData?.link + pictureData.imageNames[0];
                }
                totalAmount += odata?.quantity*odata?.amount

                return (
                  <React.Fragment key={odata.id}>
                    <div className="flex justify-between mr-4 my-4">
                      <div className="flex">
                        <img src={firstImageUrl} alt="img" className="w-16 h-12" />
                        <div className="mt-3">
                          <Link to={`/productdetail/${odata?.productId}`} target="_blank" className="w-30 hover:text-[#00A186]">{odata?.title.replace(/\\/g, '')}</Link>
                          <span className="mx-2">x</span>
                          <span className="font-semibold text-heading">{odata?.quantity}</span>
                        </div>
                      </div>
                      <div>
                        <span className="mt-3">{`$${odata?.quantity*odata?.amount}`}</span>
                      </div>
                      <div>
                        <button className="mt-3">Give a Review</button>
                      </div>
                    </div>
                    <hr />
                  </React.Fragment>
                );
              })
            } */}


          <div className="mt-10 py-2 rounded-md bg-[#F7F8FA] overflow-x-auto">
            <table className="w-full table-auto">
              <thead>
                <tr className="bg-[#F7F8FA]">
                  <th className="px-10 py-2 text-left text-lg font-semibold">Products</th>
                  <th className="px-10 py-2 text-left text-lg font-semibold">Total</th>
                  {
                    orderData?.data[0]?.status == 'delivered' ?
                      <th className="px-10 py-2 text-left text-lg font-semibold">Review</th> :""
                  }
                </tr>
              </thead>
              <tbody>
                {orderData?.data.map((odata) => {
                  const pictureData = JSON.parse(odata.picture);
                  let firstImageUrl = '';
                  if (pictureData.imageNames && pictureData.imageNames.length > 0) {
                    firstImageUrl = orderData?.link + pictureData.imageNames[0];
                  }
                  totalAmount += odata?.quantity * odata?.amount;

                  return (
                    <React.Fragment key={odata.id}>
                      <tr className="border-t">
                        <td className="px-10 py-2">
                          <div className="flex items-center">
                            <img src={firstImageUrl} alt="img" className="w-16 h-12" />
                            <div className="ml-3">
                              <Link to={`/productdetail/${odata?.productId}`} target="_blank" className="w-30 hover:text-[#00A186]">{odata?.title.replace(/\\/g, '')}</Link>
                              <span className="mx-2">x</span>
                              <span className="font-semibold text-heading">{odata?.quantity}</span>
                            </div>
                          </div>
                        </td>
                        <td className="px-10 py-2">
                          <span>{`$${odata?.quantity * odata?.amount}`}</span>
                        </td>
                        {
                          orderData?.data[0]?.status == 'delivered' ? 
                        <td className="px-10 py-2">
                          <button onClick={() => handleOpenModal(odata?.productId)} className="text-sm hover:text-[#009F7F]">Give Feedback</button>
                        </td> : ''
                        }
                      </tr>
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>




          {/* Status Update Modal */}
          {isModalOpen && (
            <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
              <div className="relative w-auto max-w-lg mx-auto my-6">
                {/*content*/}
                <div className="border rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*header*/}
                  <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                    <h3 className="text-xl font-semibold">
                      Product Review
                    </h3>
                    <button
                      className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={handleCloseModal}
                    >
                      <span className="text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                        ×
                      </span>
                    </button>
                  </div>
                  {/*body*/}
                  <div className="relative p-6 flex-auto">
                    <Rate allowHalf value={rate} onChange={(value) => setRate(value)} />
                    <textarea
                      className="w-full mt-4 p-2 border rounded"
                      rows="4"
                      placeholder="Write your review..."
                      value={review}
                      onChange={(e) => setReview(e.target.value)}
                    ></textarea>
                  </div>
                  {/*footer*/}
                  <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                    <button
                      className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={handleCloseModal}
                    >
                      Close
                    </button>
                    <button
                      className={`${reviewDisable ? "disabled:bg-[#00a1868a]" : ""} bg-[#00A186] text-white font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150`}
                      type="button"
                      onClick={handleReview} disabled={reviewDisable}
                    >
                      Submit Review
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className={isModalOpen ? "opacity-25 fixed inset-0 z-40 bg-black" : "hidden"}></div>



          <div className="flex justify-between">
            <div></div>
            <div className="w-96">
              <hr className="mb-1" />
              <hr />
              <div class="flex items-center mt-2 justify-between text-sm text-body">
                <span>Sub total</span>
                <span>${totalAmount}</span>
              </div>
              <div class="flex items-center justify-between text-base font-semibold text-heading">
                <span> Total</span>
                <span>${totalAmount}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>{contextHolder}</div>
    </div>
  );
}

export default OrderDetail;
