import React, { useState, useEffect } from 'react';
import './timeline.css'; // Import your CSS file
import { useDispatch } from 'react-redux';
import useStore from '../../contexts/store';

const Timeline = ({ hash,isConfirming , isConfirmed}) => {
    const [activeStep, setActiveStep] = useState(0); // State to manage active step
    const dispatch = useDispatch();
    const emptyCart = useStore((state) => state.emptyCart);

    useEffect(() => {
        if (hash && !activeStep) setActiveStep(1); // Activate step 1 if hash is present
        if (isConfirming) setActiveStep(2); // Activate step 2 if confirming
        if (isConfirmed){
            dispatch(emptyCart())
            setActiveStep(3); 
        } // Activate step 3 if confirmed
    }, [hash, isConfirming, isConfirmed]);

    return (
        <ul className="timeline">
            <li className={`list ${activeStep >= 1 ? 'active' : ''}`}>
                <div className="list-content">
                    <div className="details">
                        <h4 className="status-title">Transaction Created</h4>
                        you can track your transaction status by visit
                        {hash && <a href={`https://polygonscan.com/tx/${hash}`} style={{color:'#409139'}} target='_blank'>Transaction Link</a>}
                    </div>
                </div>
            </li>
            <li className={`list ${activeStep >= 2 ? 'active' : ''}`}>
                <div className="list-content">
                    <div className="details">
                        <h4 className="status-title">Waiting for confirmation</h4>
                    </div>
                </div>
            </li>
            <li className={`list ${activeStep >= 3 ? 'active' : ''}`}>
                <div className="list-content">
                    <div className="details">
                        <h4 className="status-title">Confirmed</h4>
                    </div>
                </div>
            </li>
        </ul>
    );
};

export default Timeline;
