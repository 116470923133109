import create from 'zustand';
import { persist } from 'zustand/middleware';

const useStore = create(
  persist(
    (set) => ({
      cart: [],
      addToCart: (product) => set((state) => {
        const existingProduct = state.cart.find((item) => item.id === product.id);
        if (existingProduct) {
          return {
            cart: state.cart.map((item) =>
              item.id === product.id ? { ...item, quantity: item.quantity + 1, totalPrice: (item.quantity + 1) * item.originalPrice } : item
            ),
          };
        }
        return { cart: [...state.cart, { ...product, quantity: 1, totalPrice: product.originalPrice }] };
      }),
      removeFromCart: (id) => set((state) => ({
        cart: state.cart.filter((item) => item.id !== id),
      })),
      increaseQuantity: (id) => set((state) => ({
        cart: state.cart.map((item) =>
          item.id === id ? { ...item, quantity: item.quantity + 1, totalPrice: (item.quantity + 1) * item.originalPrice } : item
        ),
      })),
      decreaseQuantity: (id) => set((state) => ({
        cart: state.cart.map((item) =>
          item.id === id && item.quantity > 1 ? { ...item, quantity: item.quantity - 1, totalPrice: (item.quantity - 1) * item.originalPrice } : item
        ),
      })),
      emptyCart: () => set({ cart: [] }),
    }),
    {
      name: 'cart-storage', // unique name for storage
      getStorage: () => localStorage, // or sessionStorage
    }
  )
);

export default useStore;
