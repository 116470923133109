import React from 'react'
import { Link } from 'react-router-dom'

export default function NotFound() {
    return (
        <div className='flex flex-col items-center gap-4 justify-center h-[90vh]'>
            <img src="/icon/error404.jpg" alt="404" width={380} />
            <p>Oops, an error has occurred. Page not found!</p>
            <Link to="/">
                <button className="bg-[#009f7f] hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                    GO TO HOME
                </button>
            </Link>
        </div>
    )
}
