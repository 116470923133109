import React, { useEffect, useState } from "react";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import {
  DataGrid,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { Link, useLocation, useParams } from "react-router-dom";
import { postRequest } from "../../backendServices/ApiCalls";
import { IoPencilSharp } from "react-icons/io5";

function Orders() {
  const [ordersData, setOrdersData] = useState([])
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const initialState = {
    initialState: {
      columns: {
        columnVisibilityModel: {
          trackingNumber: false,
          customer: false,
          products: false,
          orderDate: false,
          total: false,
          status: false,
          actions: false,
        },
      },
    },
  };

  const gridDesign = {
    "& .MuiDataGrid-toolbarContainer": {
      "& .MuiButton-text": {
        fontSize: "13px !important",
        color: "#000",
      },
      "& .MuiBadge-badge": {
        backgroundColor: "#074682",
      },
      "& .MuiInput-root": {
        borderRadius: 2,
        paddingLeft: 2,
        overflow: "hidden",
      },
    },
  };
  // Otherwise filter will be applied on fields such as the hidden column id
  const columns = [
    {
      field: "orderId",
      headerName: "Tracking Number",
      dataGeneratorUniquenessEnabled: true,
      width: 180,
      editable: false,
      groupable: false,
      aggregable: false,
    },
    {
      field: "firstname",
      headerName: "Customer",
      dataGeneratorUniquenessEnabled: true,
      width: 180,
      editable: false,
      groupable: false,
      aggregable: false,
      renderCell: (params) => `${params.row.firstname} ${params.row.lastname}`

    },
    {
      field: "date",
      headerName: "Order Date",
      dataGeneratorUniquenessEnabled: true,
      width: 180,
      editable: false,
      groupable: false,
      aggregable: false,
    },
    {
      field: "amount",
      headerName: "Total Amount",
      width: 130,
      editable: false,
      groupable: false,
      aggregable: false,
      renderCell: (params) => `$${params.value}`
    },
    {
      field: "status",
      headerName: "Status",
      dataGeneratorUniquenessEnabled: true,
      width: 180,
      editable: false,
      groupable: false,
      aggregable: false,
      renderCell: (params) => (
        <div
          onClick={() => handleOpenModal(params.row.orderId, params.value)}
          className="cursor-pointer flex items-center justify-around"
        >
          {params.value}
          <IoPencilSharp />
        </div>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      dataGeneratorUniquenessEnabled: true,
      width: 180,
      editable: false,
      groupable: false,
      aggregable: false,
      renderCell: (params) => (
        <Link to={`/dashboard/order/${params.row.orderId}`}>
          <MdOutlineRemoveRedEye size={20} className="ml-4 mt-3 cursor-pointer " />
        </Link>
      ),
    },
  ];

  const handleOpenModal = (orderId, currentStatus) => {
    setSelectedOrderId(orderId);
    setSelectedStatus(currentStatus);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleStatusUpdate = () => {
    postRequest("/updateOrderStatus", { orderId: selectedOrderId, status: selectedStatus }, async (response) => {
      console.log("response?.data", response?.data);
      if (response?.data?.status === "success") {
        getOrders();
        handleCloseModal();
      }
      if (response?.data?.status === "error") {
        getOrders();
        handleCloseModal();
      }
    },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  const getOrders = () => {

    postRequest("/userorders", "", async (response) => {
      // console.log("response?.data", response?.data?.data);
      if (response?.data?.status === "success") {
        setOrdersData(response?.data?.data);
      }
    },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  useEffect(() => {
    getOrders();
  }, []);

  const rows = ordersData || []

  return (
    <div>
      <div className="bg-white rounded-lg mt-48 mx-2 sm:mt-36 md:mt-32  p-8">
        <DataGrid
          className="custom-border"
          initialState={{
            initialState,
            pagination: { paginationModel: { pageSize: 6 } },
          }}
          rows={rows}
          columns={columns}
          // slots={{ toolbar: GridToolbarQuickFilter }}
          slots={{ toolbar: CustomToolbar }}
          sx={gridDesign}
          pageSizeOptions={[6, 12, 18, 24, 30]}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </div>
      {/* Status Update Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
          <div className="relative w-auto max-w-sm mx-auto my-6">
            {/*content*/}
            <div className="border rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              {/*header*/}
              <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                <h3 className="text-xl font-semibold">
                  Update Order Status
                </h3>
                <button
                  className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                  onClick={handleCloseModal}
                >
                  <span className="text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                    ×
                  </span>
                </button>
              </div>
              {/*body*/}
              <div className="relative p-6 flex-auto">
                <label className="block text-sm font-medium text-gray-700">Select Status:</label>
                <select
                  value={selectedStatus}
                  onChange={(e) => setSelectedStatus(e.target.value)}
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                  <option value="processing">Processing</option>
                  <option value="shipped">Shipped</option>
                  <option value="delivered">Delivered</option>
                </select>
              </div>
              {/*footer*/}
              <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                <button
                  className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={handleCloseModal}
                >
                  Close
                </button>
                <button
                  className="bg-[#00A186] text-white font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={handleStatusUpdate}
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={isModalOpen ? "opacity-25 fixed inset-0 z-40 bg-black" : "hidden"}></div>
    </div>
  );
}

export default Orders;

function CustomToolbar() {
  const location = useLocation()
  // console.log('location', location.pathname.includes("home"));
  return (
    <>
      <div className="block sm:hidden">
        <div className="flex flex-col pt-4">
          <div className="pl-8 pb-0 border-l-4 h-6 border-green-700 -m-8">
            <p className="font-semibold text-base">{location.pathname.includes("home") ? "Recent" : ""} Orders</p>
          </div>
          <br /><br /><br />
          <GridToolbarQuickFilter debounceMs={500} />
        </div>
      </div>
      <div className="hidden sm:block">
        <div className="flex justify-between items-center">
          <div className="pl-8 pb-0 border-l-4 h-6 border-green-700 -m-8">
            <p className="font-semibold text-base">{location.pathname.includes("home") ? "Recent" : ""} Orders</p>
          </div>
          <GridToolbarQuickFilter debounceMs={500} />
        </div>
      </div>
    </>
  );
}
