import React, { useState } from 'react'
import { postRequest } from '../../backendServices/ApiCalls';
import { notification } from 'antd';
import { Link } from 'react-router-dom';

export default function ForgetPassword() {
    const [api, contextHolder] = notification.useNotification();
    const errorNotification = (placement, message, description) => {
        api.error({ message, description, placement })
    };
    const successNotification = (placement, message, description) => {
        api.success({ message, description, placement });
    };
    const [loading, setLoading] = useState(false);
    const [email, setemail] = useState('')
    const handleForgetPass = () => {
        let param = {
            email
        }
        // console.log('param', param);
        setLoading(true);
        postRequest("/forgetpassword", param, async (response) => {
            console.log("response?.data", response?.data);
            if (response?.data?.status === "success") {
                successNotification('topRight', 'Success', response?.data?.message)
                setLoading(false);
            }
            if (response?.data?.status === "error") {
                // setCategoryHook(response?.data?.data)
                errorNotification('topRight', 'Error', response?.data?.message)
                setLoading(false);
            }
        },
            (error) => {
                console.log(error?.response?.data);
                setLoading(false);
            }
        );
    }
    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100">
            <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md text-center">
                <h2 className="text-xl mb-6 text-gray-800">Forget Password</h2>
                <div className='flex flex-col gap-2'>
                    <input type="email" name='email' value={email} onChange={(e) => setemail(e.target.value)} placeholder="Enter your Email" className="p-2 border rounded focus:outline-none focus:border-[#009F7F]" />
                    {
                        loading ?
                            <div className="flex items-center justify-center">
                                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#009F7F]"></div>
                            </div> :
                            <button className="bg-[#009F7F] hover:bg-green-700 text-white font-bold py-2 px-4 rounded flex justify-center items-center gap-2" onClick={handleForgetPass}>
                                Submit
                            </button>
                    }
                </div>
                <Link to='/login' className='py-5 underline hover:text-[#009F7F]'>Login</Link>
            </div>
            <div>
                {contextHolder}
            </div>
        </div>
    )
}
