import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { FaCloudUploadAlt } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { postRequest } from "../../backendServices/ApiCalls";
import { notification } from "antd";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  'XS',
  'S',
  'M',
  'L',
  'XL',
];

function getStyles(name, clothSize, theme) {
  return {
    fontWeight:
    clothSize.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function AddProduct() {
  const theme = useTheme();
  const nav = useNavigate()
  const [featuredImage, setFeaturedImage] = useState([]);
  const [imageProgress, setImageProgress] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const successNotification = (placement, message, description) => {
    api.success({ message, description, placement });
  };
  const errorNotification = (placement, message, description) => {
    api.error({ message, description, placement });
  };

  const [formData, setFormData] = useState({
    title: "",
    price: "",
    description: "",
    category: "",
    quantity: "",
    weight: "",
  });

  const [categoryHook, setCategoryHook] = useState([]);
  const [clothSize, setClothSize] = React.useState([]);


  const productCategory = () => {
    postRequest(
      "/productcategory",
      "",
      async (response) => {
        // setLoading(true);
        // console.log("response?.data", response?.data?.data);
        if (response?.data?.status === "success") {
          setCategoryHook(response?.data?.data);
          //   setLink(response?.data?.link);
          //   setLoading(false);
        }
      },
      (error) => {
        console.log(error?.response?.data);
        //   setLoading(false);
      }
    );
  };
  useEffect(() => {
    productCategory();
  }, []);

  const handleDrop = (acceptedFiles, setImages) => {
    const newFiles = acceptedFiles.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );
    setImages((prevFiles) => [...prevFiles, ...newFiles]);

    newFiles.forEach((file) => {
      // console.log(`File: ${file.name}, Size: ${file.size}, Type: ${file.type}`);
    });
  };

  const handleRemoveImage = (indexToRemove, setImages) => {
    setImages((prevFiles) =>
      prevFiles.filter((file, index) => index !== indexToRemove)
    );
  };

  const featuredDropzone = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => handleDrop(acceptedFiles, setFeaturedImage),
  });

  const thumbs = (files, setImages) =>
    files.map((file, index) => (
      <div
        className="inline-flex mb-2 mr-2 w-24 h-24 p-1 box-border  relative"
        key={index}
      >
        {imageProgress ? (
          <div role="status" className="absolute z-10 top-1/4 right-1/3">
            <div className="flex items-center justify-center">
              <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#009F7F]"></div>
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="relative min-w-0 overflow-hidden">
          <IoClose
            size={20}
            className="remove-icon absolute top-0 right-0 cursor-pointer z-10 p-1 rounded-full bg-white bg-opacity-80"
            onClick={() => handleRemoveImage(index, setImages)}
          />
          <img
            src={file.preview}
            className={`block w-auto h-full rounded ${
              imageProgress ? "brightness-50" : ""
            }`}
            alt=""
          />
        </div>
      </div>
    ));

  useEffect(() => {
    return () => {
      featuredImage.forEach((file) => URL.revokeObjectURL(file.preview));
    };
  }, [featuredImage]);

  const handleChangeSize = (event) => {
    const {
      target: { value },
    } = event;
    setClothSize(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      formData.title == "" ||
      formData.description == "<p><br></p>" ||
      formData.price == "" ||
      formData.quantity == "" ||
      featuredImage.length == 0 ||
      formData.category == "" ||
      formData.weight == ""
    ) {
      errorNotification("topRight", "Error", "Fill all the product detail");
      return;
    } 

    if (formData.category === 'clothing') {
      if (clothSize.length === 0) {
        errorNotification("topRight", "Error", "Please add cloth size!");
        return;
      }
    }
    // console.log('formData', formData);
    formData.clothSize= clothSize
    sessionStorage.setItem("productinfo", JSON.stringify(formData));
    // console.log('featuredImage', featuredImage);
    const formDataToSend = new FormData();
    formDataToSend.append("title", formData.title);
    formDataToSend.append("price", formData.price);
    formDataToSend.append("quantity", formData.quantity);
    formDataToSend.append("weight", formData.weight)
    formDataToSend.append("description", formData.description);
    formDataToSend.append("category", formData.category);
    formDataToSend.append("size", clothSize );
    featuredImage.forEach((image) => {
      formDataToSend.append("images", image);
    });
    
    setImageProgress(true);
    postRequest(
      "/uploadproduct",
      formDataToSend,
      (response) => {
        // console.log('response?.data?.status', response?.data?.status)
        // console.log('response?.data?.message', response?.data?.message)
        if (response?.data?.status === "success") {
          successNotification("topRight", "Success", response?.data?.message);
          sessionStorage.setItem("newProductId", response?.data?.ProductId)
          setImageProgress(false);
          setClothSize([]);
          // nav(`/dashboard/allproducts`)
        } else if (response?.data?.status === "error") {
          errorNotification("topRight", "Error", response?.data?.message);
          setImageProgress(false);
          setClothSize([]);
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  return (
    <>
      <div className="flex pb-5 mx-2 border-b-2 border-dashed border-border-base">
        <h1 className="text-lg font-semibold text-heading">
          Create New List
        </h1>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-2 2xl:mx-20 mx-2 my-5 pb-5 border-b-2 border-dashed border-border-base">
        <div className="flex sm:col-span-1 items-center">
          <div className="w-full px-0 pb-5">
            <h4 className="text-base font-semibold text-body-dark mb-2">
              Image
            </h4>
            <p className="text-sm text-body">
              <span>Upload your product image here</span>
            </p>
          </div>
        </div>
        <div className="col-span-2">
          <div className="text-center bg-white p-4 rounded">
            <div
              {...featuredDropzone.getRootProps({
                className:
                  "dropzone p-2 border-2 border-dashed border-gray-400 rounded mt-4",
              })}
            >
              <input {...featuredDropzone.getInputProps()} />
              <p className="mt-4 text-sm text-center text-body flex items-center flex-col">
                <FaCloudUploadAlt color="#D1D5DB" size={50} />
                <span className="font-semibold text-[#019477]">
                  Upload an image
                </span>
                or drag and drop <br />
                <span className="text-xs text-body">PNG, JPG</span>
              </p>
            </div>
            <aside className="flex flex-row flex-wrap mt-4">
              {thumbs(featuredImage, setFeaturedImage)}
            </aside>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 mx-2 lg:grid-cols-3 2xl:mx-20  gap-2 my-5 pb-5 border-b-2 border-dashed border-border-base">
        <div className="flex sm:col-span-1 items-center">
          <div className="w-full px-0 pb-5">
            <h4 className="text-base font-semibold text-body-dark mb-2">
              Description
            </h4>
            <p className="text-sm text-body">
              <span>
                Add your product description and necessary <br />
                information from here
              </span>
            </p>
          </div>
        </div>
        <div className="col-span-2">
          <div className="bg-white p-4 rounded">
            <form className="max-w-md mx-auto mt-4">
              <label
                htmlFor="title"
                className="block mb-1 font-semibold text-body-dark"
              >
                Name*
              </label>
              <input
                type="title"
                id="title"
                name="title"
                value={formData.title}
                onChange={handleChange}
                className="w-full border border-gray-300 rounded-md px-3 py-2 mb-3 focus:outline-none focus:border-blue-500"
              />
              <label
                htmlFor="price"
                className="block mb-1 font-semibold text-body-dark"
              >
                Price*
              </label>
              <input
                type="number"
                id="price"
                name="price"
                min={0}
                value={formData.price}
                onChange={handleChange}
                className="w-full border border-gray-300 rounded-md px-3 py-2 mb-3 focus:outline-none focus:border-blue-500"
              />
              <label
                htmlFor="category"
                className="block mb-1 font-semibold text-body-dark"
              >
                Category*
              </label>
              <select
                id="category"
                name="category"
                value={formData.category}
                onChange={handleChange}
                className="w-full border border-gray-300 rounded-md px-3 py-2 mb-3 focus:outline-none focus:border-blue-500"
              >
                <option value="" disabled>
                  Select a category
                </option>
                {categoryHook.map((category, i) => (
                  <option
                    key={i}
                    value={category.id}
                    disabled={category.name === "Select a category"}
                  >
                    {category.name}
                  </option>
                ))}
              </select>
              {formData.category === "14" ? (
                <>
                  <FormControl className="w-full border border-gray-300 rounded-md px-3 py-2 mb-3 focus:outline-none focus:border-blue-500">
                    <InputLabel id="demo-multiple-chip-label">Select Size</InputLabel>
                    <Select
                      labelId="demo-multiple-chip-label"
                      id="demo-multiple-chip"
                      multiple
                      value={clothSize}
                      onChange={handleChangeSize}
                      input={
                        <OutlinedInput id="select-multiple-chip" label="Select Size" />
                      }
                      renderValue={(selected) => (
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {selected.map((value) => (
                            <Chip key={value} label={value} />
                          ))}
                        </Box>
                      )}
                      MenuProps={MenuProps}
                    >
                      {names.map((name) => (
                        <MenuItem
                          key={name}
                          value={name}
                          style={getStyles(name, clothSize, theme)}
                        >
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </>
              ) : (
                ""
              )}


              <label
                htmlFor="quantity"
                className="block mb-1 font-semibold text-body-dark"
              >
                Quantity*
              </label>
              <input
                type="number"
                id="quantity"
                name="quantity"
                min={0}
                value={formData.quantity}
                onChange={handleChange}
                className="w-full border border-gray-300 rounded-md px-3 py-2 mb-3 focus:outline-none focus:border-blue-500"
              />
              

              <label
                htmlFor="weight"
                className="block mb-1 font-semibold text-body-dark"
              >
                Weight*(pound)
              </label>

              <input
                type="number"
                id="weight"
                name="weight"
                min={0}
                value={formData.weight}
                onChange={handleChange}
                className="w-full border border-gray-300 rounded-md px-3 py-2 mb-3 focus:outline-none focus:border-blue-500"
              />


              <label
                htmlFor="description"
                className="block mb-1 font-semibold text-body-dark"
              >
                Description*
              </label>
              <ReactQuill
                id="description"
                value={formData.description}
                onChange={(value) => {
                  setFormData({ ...formData, description: value });
                }}
                className="border border-gray-300 rounded-md mb-3 focus:outline-none focus:border-blue-500"
              />
            </form>
          </div>
        </div>
      </div>
      <div className="stick 2xl:mr-6 bottom-0 -mx- bg-gray-100/10 py-3 px-5 backdrop-blur text-end z-10">
        {imageProgress ? (
          <button>
            <span className="flex items-center justify-center">
              <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#009F7F]"></div>
            </span>
          </button>
        ) : (
          <button
            onClick={handleSubmit}
            type="submit"
            className="bg-[#019376] hover:bg-green-800 text-white font-bold py-4 px-6 rounded"
          >
            Add Product
          </button>
        )}
      </div>

      <div>{contextHolder}</div>
    </>
  );
}
