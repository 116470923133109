import React, { useContext, useState } from "react";
import { IoBagCheckSharp } from "react-icons/io5";
import { Button, Drawer } from "antd";
import { CloseOutlined } from "@ant-design/icons"; // Import the close icon

import "swiper/css";
import "swiper/css/pagination";
import useStore from "../contexts/store";
import { Link } from "react-router-dom";
import { UserContext } from "../contexts/UserContext";

function DrawerRight() {
  const { user, setUser } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const cart = useStore((state) => state.cart);
  const increaseQuantity = useStore((state) => state.increaseQuantity);
  const decreaseQuantity = useStore((state) => state.decreaseQuantity);
  const removeFromCart = useStore((state) => state.removeFromCart);
  // console.log("cart", cart);
  // products

  return (
    <>
      <div
        onClick={showDrawer}
        className="fixed z-10 cursor-pointer h-24 items-center justify-center right-0  top-1/2 font-semibold transform -translate-y-1/2 bg-[#009f7f] text-white p-3 rounded-l"
      >
        <div className="mb-3  mt-2 flex">
          <IoBagCheckSharp className="mr-2" size={20} />
          <p> {cart.length} Item </p>
        </div>
        <div className="text-[#009f7f] bg-white text-center rounded-md py-1">
          ${cart.reduce((acc, item) => acc + item.totalPrice, 0)}
        </div>
      </div>
      <Drawer
        className="flex "
        title={
          <div className="flex justify-between text-white items-center w-full">
            <span className="flex items-center">
              <IoBagCheckSharp className="mr-2" size={20} /> {cart.length} Item
            </span>
            <Button
              type="text"
              className="rounded-full bg-[#009f7f] text-white"
              icon={<CloseOutlined />}
              onClick={onClose}
            />
          </div>
        }
        onClose={onClose}
        open={open}
        closable={false}
      >
        {cart.length === 0 ? (
          <div className='h-full flex justify-center'>
            <div className="items-center justify-center flex-col flex">
              <svg width="140" height="176" viewBox="0 0 231.91 292">
                <defs>
                  <linearGradient
                    id="linear-gradient"
                    x1="1"
                    y1="0.439"
                    x2="0.369"
                    y2="1"
                    gradientUnits="objectBoundingBox"
                  >
                    <stop offset="0" stop-color="#029477"></stop>
                    <stop offset="1" stop-color="#009e7f"></stop>
                  </linearGradient>
                </defs>
                <g
                  id="no_cart_in_bag_2"
                  data-name="no cart in bag 2"
                  transform="translate(-1388 -351)"
                >
                  <ellipse
                    id="Ellipse_2873"
                    data-name="Ellipse 2873"
                    cx="115.955"
                    cy="27.366"
                    rx="115.955"
                    ry="27.366"
                    transform="translate(1388 588.268)"
                    fill="#ddd"
                    opacity="0.25"
                  ></ellipse>
                  <path
                    id="Path_18691"
                    data-name="Path 18691"
                    d="M29.632,0H170.368A29.828,29.828,0,0,1,200,30.021V209.979A29.828,29.828,0,0,1,170.368,240H29.632A29.828,29.828,0,0,1,0,209.979V30.021A29.828,29.828,0,0,1,29.632,0Z"
                    transform="translate(1403 381)"
                    fill="#009e7f"
                  ></path>
                  <path
                    id="Rectangle_1852"
                    data-name="Rectangle 1852"
                    d="M30,0H170a30,30,0,0,1,30,30v0a30,30,0,0,1-30,30H12.857A12.857,12.857,0,0,1,0,47.143V30A30,30,0,0,1,30,0Z"
                    transform="translate(1403 381)"
                    fill="#006854"
                  ></path>
                  <path
                    id="Rectangle_1853"
                    data-name="Rectangle 1853"
                    d="M42,0H158a42,42,0,0,1,42,42v0a18,18,0,0,1-18,18H18A18,18,0,0,1,0,42v0A42,42,0,0,1,42,0Z"
                    transform="translate(1403 381)"
                    fill="#006854"
                  ></path>
                  <path
                    id="Path_18685"
                    data-name="Path 18685"
                    d="M446.31,246.056a30,30,0,1,1,30-30A30.034,30.034,0,0,1,446.31,246.056Zm0-53.294A23.3,23.3,0,1,0,469.9,216.056,23.471,23.471,0,0,0,446.31,192.762Z"
                    transform="translate(1056.69 164.944)"
                    fill="#006854"
                  ></path>
                  <path
                    id="Path_18686"
                    data-name="Path 18686"
                    d="M446.31,375.181a30,30,0,1,1,30-30A30.034,30.034,0,0,1,446.31,375.181Zm0-53.294A23.3,23.3,0,1,0,469.9,345.181,23.471,23.471,0,0,0,446.31,321.887Z"
                    transform="translate(1057.793 95.684)"
                    fill="#009e7f"
                  ></path>
                  <circle
                    id="Ellipse_2874"
                    data-name="Ellipse 2874"
                    cx="28.689"
                    cy="28.689"
                    r="28.689"
                    transform="translate(1473.823 511.046)"
                    fill="#006854"
                  ></circle>
                  <circle
                    id="Ellipse_2875"
                    data-name="Ellipse 2875"
                    cx="15.046"
                    cy="15.046"
                    r="15.046"
                    transform="translate(1481.401 547.854) rotate(-45)"
                    fill="#009e7f"
                  ></circle>
                  <path
                    id="Path_18687"
                    data-name="Path 18687"
                    d="M399.71,531.27a71.755,71.755,0,0,1,12.65-13.6c3.4-2.863-1.5-7.726-4.882-4.882a78.392,78.392,0,0,0-13.73,15c-2.56,3.644,3.424,7.1,5.962,3.485Z"
                    transform="translate(1060.579 -35.703)"
                    fill="#006854"
                  ></path>
                  <path
                    id="Path_18688"
                    data-name="Path 18688"
                    d="M412.913,527.786a78.419,78.419,0,0,0-13.73-15c-3.38-2.843-8.289,2.017-4.882,4.882a71.785,71.785,0,0,1,12.65,13.6c2.535,3.609,8.525.162,5.962-3.485Z"
                    transform="translate(1060.566 -35.704)"
                    fill="#006854"
                  ></path>
                  <path
                    id="Path_18689"
                    data-name="Path 18689"
                    d="M583.278,527.786a78.417,78.417,0,0,0-13.73-15c-3.38-2.843-8.289,2.017-4.882,4.882a71.768,71.768,0,0,1,12.65,13.6c2.535,3.609,8.525.162,5.962-3.485Z"
                    transform="translate(970.304 -35.704)"
                    fill="#006854"
                  ></path>
                  <path
                    id="Path_18690"
                    data-name="Path 18690"
                    d="M570.075,531.27a71.77,71.77,0,0,1,12.65-13.6c3.4-2.863-1.5-7.726-4.882-4.882a78.407,78.407,0,0,0-13.73,15c-2.56,3.644,3.424,7.1,5.962,3.485Z"
                    transform="translate(970.318 -35.703)"
                    fill="#006854"
                  ></path>
                  <path
                    id="Path_18692"
                    data-name="Path 18692"
                    d="M301.243,287.464a19.115,19.115,0,0,1,8.071,9.077,19.637,19.637,0,0,1,1.6,7.88v26.085a19.349,19.349,0,0,1-9.672,16.957c-10.048-6.858-16.544-17.742-16.544-30S291.2,294.322,301.243,287.464Z"
                    transform="translate(1292.301 101.536)"
                    fill="url(#linear-gradient)"
                  ></path>
                  <path
                    id="Path_18693"
                    data-name="Path 18693"
                    d="M294.371,287.464a19.115,19.115,0,0,0-8.071,9.077,19.637,19.637,0,0,0-1.6,7.88v26.085a19.349,19.349,0,0,0,9.672,16.957c10.048-6.858,16.544-17.742,16.544-30S304.419,294.322,294.371,287.464Z"
                    transform="translate(1118.301 101.536)"
                    fill="url(#linear-gradient)"
                  ></path>
                </g>
              </svg>{" "}
              <p className="text-md font-semibold">No Products Found</p>
            </div>
            <div className="absolute -bottom-1 w-full">
              <div className="bg-white py-3 w-full mx-auto  flex justify-center">
                <div className="w-[96%] flex h-12 bg-[#009f7f] justify-between rounded-full p-1 text-sm font-bold md:h-14">
                  <span className="flex h-full text-white flex-1 items-center px-5 text-light">Checkout</span>
                  <span className="flex h-full bg-white text-[#009f7f] shrink-0 items-center rounded-full bg-light px-5">
                    $0.00
                  </span>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
          <div className="mb-20">
            {cart.map((item, i) => (
              <div key={i}>
                <div className="flex border-b p-3">
                  <div className="flex overflow-hidden flex-col-reverse items-center mr-2 w-12 h-24 bg-gray-100 text-heading rounded-full">
                    <button
                      onClick={() => decreaseQuantity(item.id)}
                      className="cursor-pointer p-2 transition-colors duration-200 hover:bg-accent-hover focus:outline-0 hover:!bg-gray-100"
                    >
                      <span className="sr-only">Decrease</span>
                      <svg
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        class="h-3 w-3 stroke-2.5"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M20 12H4"
                        ></path>
                      </svg>
                    </button>
                    <div className="flex flex-1 items-center justify-center px-3 text-sm font-semibold  text-heading">
                      {item.quantity}
                    </div>
                    <button
                      disabled={item.quantity === item.productQuantity}
                      onClick={() => increaseQuantity(item.id)}
                      className="cursor-pointer p-2 transition-colors duration-200 hover:bg-accent-hover focus:outline-0 hover:!bg-gray-100"
                    >
                      <span className="sr-only">Increase</span>
                      <svg
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        class="md:w-4.5 h-3.5 w-3.5 stroke-2.5 md:h-4.5"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                        ></path>
                      </svg>
                    </button>
                  </div>
                  <div className="w-52">
                    <img src={item.image} className="w-16 h-16 mt-4" alt="Logo" />
                  </div>
                  <div className="w-96">
                    <div>
                      <h3 className="font-bold truncate-3-lines text-heading">{item.title}</h3>
                      <p className="my-2.5 font-semibold text-accent text-[#009f7f]">
                        ${item.originalPrice}
                      </p>
                      {item.category === "clothing" && (
                        <span className="font-semibold text-xs  text-body" style={{ fontSize: '15px' }}>
                          size: {item.selectedSize}
                        </span>
                      )}
                      {item.quantity === item.productQuantity && (
                        <>
                          <br /><span className="font-semibold text-xs  text-bod" style={{ color: 'red' }}>We don't have more stock than this.</span>
                        </>
                      )}
                    </div>
                  </div>
                  <span className="font-bold text-heading  ltr:ml-auto rtl:mr-auto ">
                    ${item.totalPrice}
                  </span>
                  <button
                    onClick={() => removeFromCart(item.id)}
                    className="flex h-7 w-7 shrink-0 items-center justify-center rounded-full text-muted transition-all duration-200 hover:bg-gray-100 hover:text-red-600 focus:bg-gray-100 focus:text-red-600 focus:outline-0 ltr:ml-3 ltr:-mr-2 rtl:mr-3 rtl:-ml-2"
                  >
                    <span className="sr-only">Remove</span>
                    <svg
                      class="h-3 w-3"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </button>

                </div>
              </div>
            ))}
          </div>
            <div className="absolute -bottom-1 w-[95%]">
              <div className="bg-white py-3 w-full mx-auto  flex justify-center">
                <Link className="w-[96%] flex h-12 bg-[#009f7f] justify-between rounded-full p-1 text-sm font-bold md:h-14" to={!user ? "/login" : "/checkout"} onClick={() => sessionStorage.setItem("checkout_login", "true")}>
                  <span className="flex h-full text-white flex-1 items-center px-5 text-light">Checkout</span>
                  <span className="flex h-full bg-white text-[#009f7f] shrink-0 items-center rounded-full bg-light px-5">
                    ${cart.reduce((acc, item) => acc + item.totalPrice, 0)}
                  </span>
                </Link>
              </div>
            </div>
          </>
        )}
      </Drawer>
    </>
  );
}

export default DrawerRight;
