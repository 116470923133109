import * as React from 'react'
import {  
  useDisconnect,
  useWaitForTransactionReceipt, 
  useWriteContract 
} from 'wagmi'
import {abi} from './abi'
import { polygon } from 'viem/chains';
import { Alert, Button } from '@mui/material';
import { Payments } from '@mui/icons-material';
import Timeline from '../timeline/Timeline';
import LoadingButton from '@mui/lab/LoadingButton';

const  CreateTransaction = ({cartTotalPrice}) => {
  const { disconnect } = useDisconnect()
  const [trxStatus, setTrxStatus] = React.useState(false)
  const { 
    data: hash,
    error, 
    isPending, 
    writeContract 
  } = useWriteContract() 

  async function submit() { 
    try{
      await writeContract({
        abi,
        address: '0x248319bE74Fa97938012a50A71A860e16efE79c9',
        functionName: 'transfer',
        chainId: polygon.id,
        args: ['0xBEBADcB614731f08A8a1c57c9380b0F66C62DEBc', (cartTotalPrice   * (10 ** 9)).toString()],

      })
    }
    catch(e){
      console.log(e.message)
    }

  } 

  const { isLoading: isConfirming, isSuccess: isConfirmed } = 
    useWaitForTransactionReceipt({ 
      hash, 
    }) 

React.useEffect(() => {
  hash && setTrxStatus(true)
}, [hash])

    return (
    <div className='row'>
      {
        !trxStatus ? (
          <>
          <div className='col-12'>
          <LoadingButton color='success' loading={isPending} variant='contained' sx={{borderRadius:'50px', mt:2}} startIcon={<Payments />} onClick={()=>submit()}
                >{isPending ? 'Confirming...' : 'Pay Now From Wallet'}</LoadingButton>
    
              {
                error && (
                  <Alert sx={{mt:2, textAlign:'left'}} severity='error'>{error.details}</Alert>
                )
              }
    
          </div>
    
          <div className='col-12'>
            <Button color='error' sx={{mt:5}} onClick={() => disconnect()}>Disconnect</Button>
          </div>
          </>
          
        )
        :
        (
          <Timeline hash={hash}  isConfirming={isConfirming} isConfirmed={isConfirmed} />
          // hash && <div>Transaction Hash: {hash}</div>
          // isConfirming && <div>Waiting for confirmation...</div>
          // isConfirmed && <div>Transaction confirmed.</div>
        )
      }



    </div>
  )
}
export default CreateTransaction;