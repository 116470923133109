import React, { useEffect, useState } from "react";
import DrawerRight from "../../components/DrawerRight";
import useStore from "../../contexts/store";
import ProductCard from "../../components/ProductCard";
import { useParams } from "react-router-dom";
import { GetProductCategory, postRequest } from "../../backendServices/ApiCalls";
import { FaRegImage } from "react-icons/fa6";

const categories = [
  {
    name: "bakery",
    src: "/icon/3.png",
    paragraph:
      "Get your favorite bakery items baked and delivered to your doorsteps at any time",
    style: { top: "37%" },
    heading: "Get Your Bakery Items Delivered",
  },
  {
    name: "makeup",
    src: "/icon/6.png",
    style: { top: "22%" },
    paragraph:
      "Easiest and cheapest way to get your branded & imported makeups",
    heading: "Branded & imported makeups",
  },
  {
    name: "clothing",
    src: "/icon/1.png",
    style: { top: "40%" },
    paragraph:
      "Ready to wear dresses tailored for you online. Hurry up while stock lasts.",
    heading: "Shop your designer dresses",
  },
  {
    name: "furniture",
    style: { top: "12%" },
    src: "/icon/7.png",
    paragraph:
      "Make Your house a home with our wide collection of beautiful furniture",
    heading: "Exclusive furniture Made Only For You",
  },
  {
    name: "daily-needs",
    src: "/icon/4.png",
    style: { top: "25%" },
    paragraph: "We source the best healthy foods for you.",
    heading: "You Deserve To Eat Fresh",
  },
  {
    name: "books",
    src: "/icon/9.png",
    style: { top: "30%" },
    paragraph: "Get your favorite Books at affordable prices",
    heading: "Meet Your Next favorite Books",
  },
  {
    style: { top: "8%" },
    name: "electronics",
    src: "/icon/8.png",
    paragraph: "Get your electronic devices at affordable prices",
    heading: "Exclusive Electronic Devices Made Only For You",
  },
  {
    name: "medicine",
    src: "/icon/5.png",
    style: { top: "40%" },
    paragraph:
      "Get your medicine delivered at your doorsteps all day every day",
    heading: "Medicine Delivered in 90 Minutes",
  },
  {
    name: "bags",
    src: "/icon/2.png",
    style: { top: "40%" },
    paragraph:
      "Get your exclusive & branded bags delivered to you in no time",
    heading: "Exclusive Branded bags",
  },
];

export default function ProductCategory() {
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [offset, setOffset] = useState(0); // Track the offset for pagination
  const [totalResults, setTotalResults] = useState(0);
  const { category } = useParams();
  const cart = useStore((state) => state.cart);
  const [products, setProduct] = useState([]);
  const [link, setLink] = useState();
  const [banner, setBanner] = useState();
  const [isImageLoading, setIsImageLoading] = useState(true);
  let limit = 20

  const handleImageLoad = () => {
    setIsImageLoading(false);
  };

  const productsFun = (offset) => {
    setLoadingMore(true); // Start loading for pagination
    let param = { category: category, limit, offset: offset };
    GetProductCategory(param, async (response) => {
      setLoading(true);
      if (response?.data?.status === "success") {
        const newProducts = response?.data?.data.filter(product => !products.some(p => p.id === product.id));
          setProduct((prevProducts) => [...prevProducts, ...newProducts]);
        // setProduct(response?.data?.data);
        setTotalResults(response?.data?.totalResults[0]?.totalProducts)
        setLink(response?.data?.link);
      }
      setLoading(false);
      setLoadingMore(false);
    },
      (error) => {
        console.log(error?.response?.data);
        setLoading(false);
        setLoadingMore(false);
      }
    );
  };

  const CategoryImg = () => {
    // setLoadingMore(true); 
    // let param = { category: category.charAt(0).toUpperCase() + category.slice(1) };
    let param = { category: category.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') };
    // console.log('param', param)
    postRequest("/categoryimage",param,async (response) => {
      // setLoading(true);
      if (response?.data?.status === "success") {
        setBanner(response?.data?.link + response?.data?.data[0]?.banner);
        // setLink(response?.data?.link);
        // setLoading(false);
      }
      if (response?.data?.status === "error") {
        // setLoading(false);
      }
    },
    (error) => {
      console.log(error?.response?.data);
      setLoading(false);
    } 
  );
  };
  useEffect(() => {
    CategoryImg()
    setProduct([])
    productsFun(offset);
  }, [offset, category]);

  // const currentCategory = categories.find((cat) => cat.name === category);


  const handleLoadMore = () => {
    setOffset((prevOffset) => prevOffset + limit); // Increment offset on load more
  };

  return (
    <div className="">
      {/* {currentCategory && ( */}
        <div className="relative text-center mb-10">
          <img
            src={banner}
            onLoad={handleImageLoad}
            // alt={currentCategory.text}
            className="max-w-full"
          />
          {isImageLoading && <div className="custom-skeleton rounded w-full h-60 flex items-center justify-center">
            <FaRegImage color="#BFBFBF" size={50} />
          </div>}
          {/* <img
            src={currentCategory.src}
            alt={currentCategory.text}
            className="max-w-full"
          /> */}
          {/* <div
            className="absolute left-1/2 transform -translate-x-1/2"
            style={currentCategory.style}
          >
            <h1 className="text-sm font-bold mt-[-4%] sm:mt-0 tracking-tight text-heading lg:text-3xl xl:text-4xl whitespace-nowrap">
              {currentCategory.heading}
            </h1>
          </div> */}
          {/* <div
            className="absolute text-[10px] sm:text-base mt-4 sm:mt-12 left-1/2 transform -translate-x-1/2"
            style={currentCategory.style}
          >
            {currentCategory.paragraph}
          </div> */}
        </div>
      {/*  )} */}
      {!loading ? (
        <div>
          <div className="grid px-6 pb-6 sm:grid-cols-2 md:grid-cols-2 gap-4 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 grid-cols-1">
            {products.map((product, index) => (
              <ProductCard
              key={index}
              id={product?.id}
              shopid={product?.shopid}
              imgSrc={product?.picture}
              link={link}
              title={product?.title?.replace(/\\/g, '')}
              originalPrice={product?.price}
              description={product?.description?.replace(/\\/g, '')}
              storeName={product?.store_name}
              category={product?.category}
              productQuantity={product?.quantity}
              size={product?.size}
              weight={product?.weight}
              average_star={product?.average_star}
              reviewusers={product?.reviewusers}
              />
            ))}
          </div>

          {loadingMore ?
            (
              <div className="flex items-center justify-center">
                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#009F7F]"></div>
              </div>
            ) : (
              totalResults-offset> limit ?
              <div className="flex justify-center my-4">
                <button
                  onClick={handleLoadMore}
                  className="bg-[#009f7f] hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                >
                  Load More Products
                </button>
              </div> :''
            )}

        </div>
      ) : (
        <div className="flex items-center justify-center mt-6">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#009F7F]"></div>
        </div>
      )}

      {(products.length === 0 && !loadingMore) && (
          <div className="flex flex-col justify-center items-center">
            <svg width="140" height="176" viewBox="0 0 231.91 292">
              <defs>
                <linearGradient
                  id="linear-gradient"
                  x1="1"
                  y1="0.439"
                  x2="0.369"
                  y2="1"
                  gradientUnits="objectBoundingBox"
                >
                  <stop offset="0" stop-color="#029477"></stop>
                  <stop offset="1" stop-color="#009e7f"></stop>
                </linearGradient>
              </defs>
              <g
                id="no_cart_in_bag_2"
                data-name="no cart in bag 2"
                transform="translate(-1388 -351)"
              >
                <ellipse
                  id="Ellipse_2873"
                  data-name="Ellipse 2873"
                  cx="115.955"
                  cy="27.366"
                  rx="115.955"
                  ry="27.366"
                  transform="translate(1388 588.268)"
                  fill="#ddd"
                  opacity="0.25"
                ></ellipse>
                <path
                  id="Path_18691"
                  data-name="Path 18691"
                  d="M29.632,0H170.368A29.828,29.828,0,0,1,200,30.021V209.979A29.828,29.828,0,0,1,170.368,240H29.632A29.828,29.828,0,0,1,0,209.979V30.021A29.828,29.828,0,0,1,29.632,0Z"
                  transform="translate(1403 381)"
                  fill="#009e7f"
                ></path>
                <path
                  id="Rectangle_1852"
                  data-name="Rectangle 1852"
                  d="M30,0H170a30,30,0,0,1,30,30v0a30,30,0,0,1-30,30H12.857A12.857,12.857,0,0,1,0,47.143V30A30,30,0,0,1,30,0Z"
                  transform="translate(1403 381)"
                  fill="#006854"
                ></path>
                <path
                  id="Rectangle_1853"
                  data-name="Rectangle 1853"
                  d="M42,0H158a42,42,0,0,1,42,42v0a18,18,0,0,1-18,18H18A18,18,0,0,1,0,42v0A42,42,0,0,1,42,0Z"
                  transform="translate(1403 381)"
                  fill="#006854"
                ></path>
                <path
                  id="Path_18685"
                  data-name="Path 18685"
                  d="M446.31,246.056a30,30,0,1,1,30-30A30.034,30.034,0,0,1,446.31,246.056Zm0-53.294A23.3,23.3,0,1,0,469.9,216.056,23.471,23.471,0,0,0,446.31,192.762Z"
                  transform="translate(1056.69 164.944)"
                  fill="#006854"
                ></path>
                <path
                  id="Path_18686"
                  data-name="Path 18686"
                  d="M446.31,375.181a30,30,0,1,1,30-30A30.034,30.034,0,0,1,446.31,375.181Zm0-53.294A23.3,23.3,0,1,0,469.9,345.181,23.471,23.471,0,0,0,446.31,321.887Z"
                  transform="translate(1057.793 95.684)"
                  fill="#009e7f"
                ></path>
                <circle
                  id="Ellipse_2874"
                  data-name="Ellipse 2874"
                  cx="28.689"
                  cy="28.689"
                  r="28.689"
                  transform="translate(1473.823 511.046)"
                  fill="#006854"
                ></circle>
                <circle
                  id="Ellipse_2875"
                  data-name="Ellipse 2875"
                  cx="15.046"
                  cy="15.046"
                  r="15.046"
                  transform="translate(1481.401 547.854) rotate(-45)"
                  fill="#009e7f"
                ></circle>
                <path
                  id="Path_18687"
                  data-name="Path 18687"
                  d="M399.71,531.27a71.755,71.755,0,0,1,12.65-13.6c3.4-2.863-1.5-7.726-4.882-4.882a78.392,78.392,0,0,0-13.73,15c-2.56,3.644,3.424,7.1,5.962,3.485Z"
                  transform="translate(1060.579 -35.703)"
                  fill="#006854"
                ></path>
                <path
                  id="Path_18688"
                  data-name="Path 18688"
                  d="M412.913,527.786a78.419,78.419,0,0,0-13.73-15c-3.38-2.843-8.289,2.017-4.882,4.882a71.785,71.785,0,0,1,12.65,13.6c2.535,3.609,8.525.162,5.962-3.485Z"
                  transform="translate(1060.566 -35.704)"
                  fill="#006854"
                ></path>
                <path
                  id="Path_18689"
                  data-name="Path 18689"
                  d="M583.278,527.786a78.417,78.417,0,0,0-13.73-15c-3.38-2.843-8.289,2.017-4.882,4.882a71.768,71.768,0,0,1,12.65,13.6c2.535,3.609,8.525.162,5.962-3.485Z"
                  transform="translate(970.304 -35.704)"
                  fill="#006854"
                ></path>
                <path
                  id="Path_18690"
                  data-name="Path 18690"
                  d="M570.075,531.27a71.77,71.77,0,0,1,12.65-13.6c3.4-2.863-1.5-7.726-4.882-4.882a78.407,78.407,0,0,0-13.73,15c-2.56,3.644,3.424,7.1,5.962,3.485Z"
                  transform="translate(970.318 -35.703)"
                  fill="#006854"
                ></path>
                <path
                  id="Path_18692"
                  data-name="Path 18692"
                  d="M301.243,287.464a19.115,19.115,0,0,1,8.071,9.077,19.637,19.637,0,0,1,1.6,7.88v26.085a19.349,19.349,0,0,1-9.672,16.957c-10.048-6.858-16.544-17.742-16.544-30S291.2,294.322,301.243,287.464Z"
                  transform="translate(1292.301 101.536)"
                  fill="url(#linear-gradient)"
                ></path>
                <path
                  id="Path_18693"
                  data-name="Path 18693"
                  d="M294.371,287.464a19.115,19.115,0,0,0-8.071,9.077,19.637,19.637,0,0,0-1.6,7.88v26.085a19.349,19.349,0,0,0,9.672,16.957c10.048-6.858,16.544-17.742,16.544-30S304.419,294.322,294.371,287.464Z"
                  transform="translate(1118.301 101.536)"
                  fill="url(#linear-gradient)"
                ></path>
              </g>
            </svg>{" "}
            <p className="text-md font-semibold">No Products Found</p>
          </div>
      )}
      {/* Drawer side bar */}

      {/* Drawer */}
      <DrawerRight />
    </div>
  );
}

// src="/icon/grocery.webp"
// src="/icon/makeup1.png"
// src="/icon/clothes.webp"
// src="/icon/bag.png"
