import React from "react";
import Home from "./Home";
import Shops from "./Shops";
import SingleShop from "./SingleShop";
import ProductCategory from "./ProductCategory";
import Checkout from "./Checkout";
import { Route, Routes } from "react-router-dom";
import PrivateRoute from "../PrivateRoute";
import Payment from "./Payment";
import ProductDetail from "./ProductDetail";
import OrderSuccess from "./OrderSuccess";
import BuyAgain from "./BuyAgain";
import PurchasedProduct from "./PurchasedProduct";
import SearchProduct from "./SearchProduct";
import CustomerService from "./CustomService";
import Sell from "./Sell";
import Profile from "./Profile";
import PrivateRouteForBuyer from "../PrivateRouteForBuyer";
import OrderDetail from "../seller/OrderDetail";
import NotFound from "../../components/NotFound";

export default function BuyerLinks() {
  return (
    <>
      {/* <Navbar /> */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/shops" element={<Shops />} />
        <Route path="/shop/:shopname?/:id?" element={<SingleShop />} />
        <Route path="/product/:category" element={<ProductCategory />} />
        {/* <Route path="/checkout" element={<Checkout/>} /> */}
        <Route path="/shops/:shopname?/:id?" element={<Shops />} />
        <Route path="/productdetail/:id" element={<ProductDetail />} />
        <Route path="/checkout" element={<PrivateRouteForBuyer Component={Checkout} />}/>
        <Route path="/buyagain" element={<PrivateRouteForBuyer Component={BuyAgain} />}/>
        <Route path="/paymentDescription" element={<PrivateRouteForBuyer Component={Payment} />}/>
        {/* <Route path="/order/:orderid" element={<PrivateRouteForBuyer Component={OrderSuccess} />}/> */}
        <Route path="/purchasedProduct" element={<PrivateRouteForBuyer Component={PurchasedProduct} />}/>
        <Route path="/search" element={<SearchProduct />} />
        <Route path="/customerService" element={<CustomerService />} />
        <Route path="/sell" element={<Sell />} />
        <Route path="/order/:orderid" element={<PrivateRouteForBuyer Component={OrderDetail} />}/>
        <Route path="/profile" element={<PrivateRouteForBuyer Component={Profile} />}/>

        {/* Catch-all route for undefined paths */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}
