import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { postRequest } from '../../backendServices/ApiCalls';
import ProductCard from '../../components/ProductCard';

export default function SearchProduct() {
    const [products, setProducts] = useState([]);
    const [link, setLink] = useState();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    // console.log('searchParams', searchParams)
    const category = searchParams.get('category');
    const title = searchParams.get('title');
    // console.log('category', category)
    // console.log('title', title)

    const handleSearch = () => {
        let param = {
            category: category,
            title: title
        }
        // console.log('param', param)
        postRequest("/getsearchproducts", param, async (response) => {
            if (response?.data?.status === "success") {
                setProducts(response?.data?.data);
                setLink(response?.data?.link);
                // console.log('response?.data?.data', response?.data?.data)
            }
        },
            (error) => {
                console.log(error?.response?.data);
            }
        );
    }

    useEffect(() => {
        handleSearch()
    }, [])

    useEffect(() => {
        handleSearch()
    }, [title])

    return (
        <div>
            <p>Result for <span className='text-[#009f7f]'>"{title}"</span></p>
        <div className="grid py-6 sm:grid-cols-2 md:grid-cols-2 gap-4 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5  grid-cols-1">
            {products.map((product, index) => (
                <ProductCard
                    key={index}
                    id={product?.id}
                    shopid={product?.shopid}
                    imgSrc={product?.picture}
                    link={link}
                    title={product?.title?.replace(/\\/g, '')}
                    originalPrice={product?.price}
                    description={product?.description?.replace(/\\/g, '')}
                    storeName={product?.store_name}
                    category={product?.category}
                    productQuantity={product?.quantity}
                    size={product?.size}
                    weight={product?.weight}
                    average_star={product?.average_star}
                    reviewusers={product?.reviewusers}
                />
            ))}
        </div>
        </div>
    )
}
