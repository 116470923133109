import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import { postRequest } from "../../backendServices/ApiCalls";

ReactModal.setAppElement('#root');

export default function CustomerService() {
  const [address, setAddress] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);

  const getData = () => {
    postRequest(
      "/getaddress",
      "",
      (response) => {
        if (response?.data?.status === "success") {
          setAddress(response?.data?.data[0] || {}); // Ensure address is an empty object if data is not present
        } else if (response?.data?.status === "error") {
          // errorNotification("topRight", "Error", response?.data?.message);
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  useEffect(() => {
    getData();
  }, []);

  const openModal = (card) => {
    setSelectedCard(card);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedCard(null);
  };

  const cards = [
    { text: "A delivery, order or return", img: "/icon/0.1.png" },
    { text: "International shopping", img: "/icon/0.2.png" },
    { text: "Payment, charges or gift cards", img: "/icon/0.3.png" },
    { text: "Login, address, security & privacy", img: "/icon/0.4.png" },
    { text: "Kindle, Fire or Alexa device", img: "/icon/0.5.png" },
    { text: "eBooks, Prime Videos, Music or Games", img: "/icon/0.6.png" },
    { text: "Prime", img: "/icon/0.7.png" },
    { text: "Memberships, subscriptions or communications", img: "/icon/0.8.png" },
    { text: "Something else", img: "/icon/0.9.png" },
  ];

  return (
    <div className="bg-[#007185] h-auto min-h-full pb-4 rounded-md">
      <div className="bg-[#007185] shadow-b shadow-lg rounded-md">
        <div className="flex flex-wrap py-2 px-6">
          <h1 className="text-white font-normal border-r text-lg border-white pr-6">
            Customer Service
          </h1>
          <h1 className="text-white font-medium text-lg pl-6">Home</h1>
        </div>
      </div>
      <div className="w-full max-w-6xl mx-auto mt-12 px-4">
        <p className="text-white font-bold text-3xl text-center md:text-left">
          {`Welcome to Elevated Customer Service, ${address.firstname || " "}`}
        </p>
        <p className="text-white font-normal text-xl mt-3 text-center md:text-left">
          What would you like help with today? You can quickly take care of most things here, or connect with us when needed.
        </p>
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 mt-6">
          {cards.map((card, index) => (
            <div key={index} className="flex bg-white cursor-pointer rounded-lg px-4 py-6 gap-3" onClick={() => openModal(card)}>
              <div className="w-10 h-10 mt-1">
                <img src={card.img} alt="Icon" />
              </div>
              <p className="my-auto font-medium">{card.text}</p>
            </div>
          ))}
        </div>
      </div>
      {selectedCard && (
        <ReactModal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          contentLabel="Card Details"
          className="modal z-10"
          overlayClassName="modal-overlay"
          shouldCloseOnOverlayClick={true}
        >
          <button className="modal-close-button" onClick={closeModal}>
            &times;
          </button>
          <h2 className="text-lg font-medium">{selectedCard.text}</h2>
          <form className="mt-4">
            <label className="block text-sm font-medium">Email:</label>
            <input
              type="email"
              defaultValue={address.email || ""}
              className="w-full px-2 py-1 mt-1 border rounded"
            />
            <label className="block text-sm font-medium mt-4">Phone:</label>
            <input
              type="tel"
              defaultValue={address.mobile || ""}
              className="w-full px-2 py-1 mt-1 border rounded"
            />
            <label className="block text-sm font-medium mt-4">Details:</label>
            <textarea
              className="w-full px-2 py-1 mt-1 border rounded"
              rows="4"
            ></textarea>
            <button
              className="bg-[#009f7f] my-3 mx-5 rounded-lg">
              <p className="font-semibold px-3 py-1 text-white text-sm">
               Submit
              </p>
          </button>
          </form>
        </ReactModal>
      )}
    </div>
  );
}
