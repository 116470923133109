import React, { useContext } from "react";
import { Drawer } from "antd";
import "swiper/css";
import "swiper/css/pagination";
import { CloseOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { UserContext } from "../contexts/UserContext";

function LeftNavbar({ open, onClose, categoryHook, isDashboard }) {
	const { user, setUser } = useContext(UserContext);
	// console.log('user', user)
	let shopUser = true
	if (user?.ShopUser == 0) {
			shopUser = false
	}
	return (
		<>
			<Drawer
				className=""
				title={
					<>
						<div className="flex gap-2 items-center">
							<img src="/icon/profile.svg" className="size-7 rounded-full" />
							<p className="text-white font-bold text-lg">Hello, {user ? user?.username : 'Sign in'}</p>
						</div>
						<button className="absolute top-4 -right-8 pointer-events-auto bg-none border-none text-2xl cursor-pointer" onClick={onClose}>
							<CloseOutlined style={{ color: "#ffffff" }} />
						</button>
					</>
				}
				onClose={onClose} open={open} closable={false} placement="left">
				{
					isDashboard ?
						<ul className="flex flex-col">
							<Link onClick={onClose} className="hover:bg-[#0da889] hover:text-white px-10 py-3 cursor-pointer" to={`/dashboard/home`}>Dashboard</Link>
							<Link onClick={onClose} className="hover:bg-[#0da889] hover:text-white px-10 py-3 cursor-pointer" to={`/dashboard/product`}>Add List</Link>
							<Link onClick={onClose} className="hover:bg-[#0da889] hover:text-white px-10 py-3 cursor-pointer" to={`/dashboard/allproducts`}>All Lists</Link>
							<Link onClick={onClose} className="hover:bg-[#0da889] hover:text-white px-10 py-3 cursor-pointer" to={`${!shopUser ? "/dashboard/shop" : "/dashboard/updateShop"}`}>
								{!shopUser ? "Add new shop" : "Update shop"}</Link>
							<Link onClick={onClose} className="hover:bg-[#0da889] hover:text-white px-10 py-3 cursor-pointer" to={`/dashboard/orders`}>Orders</Link>
						</ul>
						:
						<ul className="flex flex-col">
							<Link onClick={onClose} className="hover:bg-[#0da889] hover:text-white px-10 py-3 cursor-pointer"
								to={`/`}>Home</Link>
							{categoryHook.map((category, i) => {
								return (
									<Link onClick={onClose} key={i} className="hover:bg-[#0da889] hover:text-white px-10 py-3 cursor-pointer"
										to={`/product/${category?.name?.toLowerCase().replace(/\s+/g, "-")}`}>
										{category?.name}
									</Link>
								)
							})
							}
						</ul>
				}
			</Drawer>
		</>
	);
}

export default LeftNavbar;
