import React from 'react'
import Navbar from '../../components/Navbar'
import BuyerLinks from './BuyerLinks'

export default function index() {
    return (
        <>
            <Navbar childComponent={BuyerLinks} />
        </>
    )
}
