// import React from "react";
// import { Link } from "react-router-dom";

// function Sell() {
//   const EcommerceCard = [
//     {
//       imgIcon: "/icon/Ecardicon/MobileApp.svg",
//       title: "What is ecommerce?",
//       description:
//         "Electronic commerce (ecommerce) is the trading of goods and services on the internet. Learn about the advantages and disadvantages of this selling channel.",
//     },
//     {
//       imgIcon: "/icon/Ecardicon/LineChart.svg",
//       title: "Build a business",
//       description:
//         "For entrepreneurs and growing businesses, ecommerce can be a profitable model to adapt either as the sole focus of your business, or as an additional selling channel.",
//     },
//     {
//       imgIcon: "/icon/Ecardicon/DeliveryTruck.svg",
//       title: "Ecommerce fulfillment",
//       description:
//         "Ecommerce fulfillment is a vital ingredient to growing a successful online retail channel. Here’s what to consider when looking for an order fulfillment service.",
//     },
//     {
//       imgIcon: "/icon/Ecardicon/ProductsInBoxes.svg",
//       title: "Inventory management",
//       description:
//         "Maintaining the right amount of inventory to meet demand can help keep your business humming. Get effective inventory management tips for ecommerce business owners.",
//     },
//     {
//       imgIcon: "/icon/Ecardicon/Checklist.svg",
//       title: "What is dropshipping?",
//       description:
//         "This guide covers the definition of dropshipping, how it works, pros and cons, related Elevated policies, and alternatives to consider for ecommerce businesses.",
//     },
//     {
//       imgIcon: "/icon/Ecardicon/Stores.svg",
//       title: "Create an Elevated storefront",
//       description:
//         "Learn how to tell your brand story using Elevated tools. Build immersivei storefronts and customize product detail pages with rich media and content.",
//     },
//     {
//       imgIcon: "/icon/Ecardicon/Books.svg",
//       title: "Sell books on Elevated",
//       description:
//         "Learn how to sell used books, new books, or your own self-published books. We've got the steps to help you get started—and seven tips to help you succeed.",
//     },
//     {
//       imgIcon: "/icon/Ecardicon/SellerUniversity.svg",
//       title: "Seller University",
//       description:
//         "Our online training program is designed to help brands, businesses, and entrepreneurs learn how to succeed as Elevated selling partners.",
//     },
//     {
//       imgIcon: "/icon/Ecardicon/Spotlight.svg",
//       title: "Elevated Selling Partner Blog",
//       description:
//         "Read updates, articles, and stories about getting started in ecommerce and growing your business in Elevated stores.",
//     },
//   ];
//   return (
//     <div className="w-full max-w-7xl mx-auto">
//       <div className="sm:hidden ">
//         <div className="flex bg-[#009F7F] text-white px-2 py-1">
//           <img src="/icon/starGreen.svg" />
//           <p>
//             Get <span className="font-bold">10% back</span> on your first{" "}
//             <span className="font-bold"> $50,000</span> in branded sales.{" "}
//             <span className="font-bold underline"> Learn more</span>
//           </p>
//         </div>
//       </div>
//       <div className="sm:grid grid-cols-3 flex mt-2 justify-around items-center">
//         <p className="font-bold text-2xl">Sell with Elevated</p>
//         <div className="hidden sm:block">
//           <div className="flex mt-2">
//             <img src="/icon/starGreen.svg" />
//             <p>
//               Get <span className="font-bold">10% back</span> on your first{" "}
//               <span className="font-bold"> $50,000</span> in branded sales.{" "}
//               <span className="font-bold underline"> Learn more</span>
//             </p>
//           </div>
//         </div>
//         <div className="flex justify-center">
//         <Link to='/sellerRegister' className="hover:text-white font-semibold text-lg px-5 py-2 bg-[#009F7F] text-white rounded-3xl">Sign up</Link>
//         </div>
//       </div>
//       <div className="bg-[#E9F5FD] h-auto flex mt-4 pt-8">
//         <div>
//           <div className="text-[350%] text-center sm:text-left sm:text-[500%] font-extrabold pl-[8%] ">
//             Start selling with Elevated
//           </div>
//           <p className="font-medium text-center sm:text-left text-xl pl-[8%] mt-2">
//             The fastest-growing and preferred acquisition channel for over half
//             our multichannel sellers.
//           </p>
//           <Link to='/sellerRegister' className="inline-block hover:text-white font-semibold shadow-md  text-2xl px-6 pb-3 ml-12 my-6 py-2 bg-[#009F7F] text-white rounded-3xl">
//             Sign up
//           </Link>
//           {/* <p className="mt-2 font-medium  ml-12 mb-8">
//             $39.99 a month + selling fees
//           </p> */}
//         </div>
//         <div className="w-full hidden sm:block">
//           <img src="/icon/lofi.png" />
//         </div>
//       </div>
//       <div className="flex flex-col sm:flex-row mt-28 gap-6">
//         <div className="sm:w-[45%]">
//           <img src="/icon/tall.png" />
//         </div>
//         <div className="sm:w-[55%] pr-10">
//           <div className="bg-[#49850F] gap-2 w-52 mt-[-3%] rounded-2xl flex px-2 py-1">
//             <img src="/icon/star.svg" />
//             <p className="text-white font-medium">New Seller Incentives</p>
//           </div>
//           <p className="font-extrabold text-[260%] mt-4">
//             Get started with $50,000 in incentives
//           </p>
//           <p className="font-medium mt-4">
//             Ready to sell with Elevated? As a new seller, you can take advantage
//             of a series of incentives.
//           </p>
//           <div className="bg-[#F2F6E1] mt-4  px-4 py-6 rounded-3xl">
//             <div className="flex mt-1 ">
//               <img src="/icon/tick.svg" className="size-5" />
//               <p>
//                 <span className="font-semibold">10% back </span> on your first
//                 $50,000 in branded sales, then{" "}
//                 <span className="font-semibold"> 5% back </span>
//                 through your first year until you reach $1,000,000
//               </p>
//             </div>
//             <div className="flex mt-3 ">
//               <img src="/icon/tick.svg" className="size-5" />
//               <p>
//                 <span className="font-semibold"> $100 off </span> shipments into
//                 the Elevated fulfillment network using the Elevated Partnered
//                 Carrier program
//               </p>
//             </div>
//             <div className="flex mt-3 ">
//               <img src="/icon/tick.svg" className="size-5" />
//               <p>
//                 <span className="font-semibold">
//                   {" "}
//                   Free storage and customer returns
//                 </span>{" "}
//                 with auto-enrollment in the FBA New Selection program
//               </p>
//             </div>
//             <div className="flex mt-3 ">
//               <img src="/icon/tick.svg" className="size-5" />
//               <p>
//                 <span className="font-semibold"> $50 credit </span> to create
//                 Sponsored Products or Sponsored Brands ads
//               </p>
//             </div>
//           </div>
//         </div>
//       </div>
//       <p className="text-[350%] mt-16 sm:text-[400%] mb-6 font-extrabold text-center">
//         Brand case studies
//       </p>
//       <div className="flex flex-col sm:flex-row gap-8  px-10">
//         <div className="shadow-2xl rounded-xl mb-6">
//           <img src="/icon/hope1.png" />

//           <div className="bg-gray-200 w-[28%] rounded-lg mx-8">
//             <p className="font-medium px-3 py-1 text-sm mt-6 ">Case study</p>
//           </div>
//           <p className="font-semibold mx-8 mt-4">Frashly Picked</p>
//           <p className="mx-8 mt-4 pb-4">
//             Freshly Picked sells high-end shoes and accessories for babies,
//             children and their moms to help make life a little more fun,
//             colorful and easier.
//           </p>
//         </div>
//         <div className="shadow-2xl rounded-xl mb-6">
//           <img src="/icon/hope2.png" />

//           <div className="bg-gray-200 w-[28%] rounded-lg mx-8">
//             <p className="font-medium px-3 py-1 text-sm mt-6 ">Case study</p>
//           </div>
//           <p className="font-semibold mt-4 mx-8">Hope &amp; Henry</p>
//           <p className="mx-8 mt-4 pb-4">
//             Hope &amp; Henry sells beautiful, high-quality, and affordable
//             clothing using organic cotton and sharing profits with the women and
//             men who sew them.
//           </p>
//         </div>
//         <div className="shadow-2xl rounded-xl mb-6">
//           <img src="/icon/hope3.png" />

//           <div className="bg-gray-200 w-[28%] rounded-lg mx-8">
//             <p className="font-medium px-3 py-1 text-sm mt-6 ">Case study</p>
//           </div>
//           <p className="font-semibold mt-4 mx-8">New Republic</p>
//           <p className="mx-8 mt-4 pb-4">
//             New Republic sells refined styles of men's footwear and accessories
//             that incorporate unexpected twists on memorable classics at
//             affordable prices
//           </p>
//         </div>
//       </div>

//       <div className="bg-[#E2F4F7] rounded-2xl mt-20 py-6 px-16">
//         <div className="flex flex-col sm:flex-row items-center sm:items-start space-y-4 sm:space-y-0 sm:space-x-8">
//           <div className="w-full sm:w-1/2 text-center sm:text-left">
//             <p className="font-semibold text-lg mt-8">Programs</p>
//             <p className="font-extrabold text-3xl mt-4 sm:text-[350%] leading-tight">
//               Unlock a universe of selling opportunities
//             </p>
//             <p className="font-medium text-base mt-4">
//               What type of Elevated seller are you? Play to your strengths and
//               create your selling strategy with a variety of programs made to
//               support your unique business.
//             </p>
//           </div>
//           <div className="w-full sm:w-1/2">
//             <img
//               src="/icon/sell.svg"
//               className="w-full h-auto"
//               alt="Sell icon"
//             />
//           </div>
//         </div>
//       </div>
//       <p className="text-[350%] mt-16 sm:text-[400%] mb-6 font-extrabold text-center">
//         Introduction to ecommerce selling
//       </p>
//       <div className="grid mt-16 p-6 gap-x-5 gap-y-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
//         {EcommerceCard.map((card, index) => {
//           return (
//             <div className="bg-white p-10 rounded-xl shadow-lg">
//               <img
//                 src={card.imgIcon}
//                 alt={card.title + " image"}
//                 className="size-20"
//               />
//               <p className="text-4xl font-bold text-[#232f3e]">{card.title}</p>
//               <p className="text-lg text-[#6c7778]">{card.description}</p>
//             </div>
//           );
//         })}
//       </div>
//     </div>
//   );
// }

// export default Sell;




import React from 'react'

function Sell() {
  return (
    <div className='text-center font-serif font-extrabold text-[300%] sm:text-[500%]'>Comming Soon...</div>
  )
}

export default Sell