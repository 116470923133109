import React, { useRef, useState } from "react";
import Card from "react-credit-cards";
import { formatCreditCardNumber, formatCVC, formatExpirationDate, formatFormData } from "./utils";
import "react-credit-cards/es/styles-compiled.css";


const CreditCard = ({ address, onSubmit, loading }) => {
  const [state, setState] = useState({ number: "", name: "", lastname: "", expiry: "", cvc: "", issuer: "", focused: "", formData: null });

  const formRef = useRef(null);

  const handleCallback = ({ issuer }, isValid) => {
    if (isValid) {
      setState(prevState => ({ ...prevState, issuer }));
    }
  };

  const handleInputFocus = ({ target }) => {
    setState(prevState => ({ ...prevState, focused: target.name }));
  };

  const handleInputChange = ({ target }) => {
    let value = target.value;

    if (target.name === "number") {
      value = formatCreditCardNumber(value);
    } else if (target.name === "expiry") {
      value = formatExpirationDate(value);
    } else if (target.name === "cvc") {
      value = formatCVC(value);
    }

    setState(prevState => ({ ...prevState, [target.name]: value }));
  };

  const handleSubmit = e => {
    e.preventDefault();
    const formData = [...e.target.elements]
      .filter(d => d.name)
      .reduce((acc, d) => {
        acc[d.name] = d.value;
        return acc;
      }, {});

    setState(prevState => ({ ...prevState, formData }));
    let param = {
      cvc: formData.cvc,
      expiry: formData.expiry,
      issuer: formData.issuer,
      lastname: formData.lastname,
      firstname: formData.name,
      accnumber: formData.number,
      city: address.city,
      state: address.state,
      zipCode: address.zipCode,
      address: address.address,
    }
    // console.log("🚀 ~ handleSubmit ~ param:", param)
    onSubmit(param);
  };

  const { number, name, lastname, expiry, cvc, focused, issuer, formData } = state;

  return (
    <div className="flex flex-col-reverse md:flex-row gap-2 items-center" key="Payment">
      <form className="w-full" ref={formRef} onSubmit={handleSubmit}>
        <div className="mb-4 flex gap-2">
          <input type="tel" name="number" className="w-full p-2 border rounded focus:outline-none focus:border-[#009F7F]"
            placeholder="Card Number" pattern="[\d| ]{16,22}" value={number} required onChange={handleInputChange} onFocus={handleInputFocus} />
          {/* <small className="text-gray-500">E.g.: 49..., 51..., 36..., 37...</small> */}
        </div>
        <div className="mb-4">
          <input type="text" name="name" className="w-full p-2 border rounded focus:outline-none focus:border-[#009F7F]"
            placeholder="First Name" value={name} required onChange={handleInputChange} onFocus={handleInputFocus} />
        </div>
        <div className="mb-4">
          <input type="text" name="lastname" className="w-full p-2 border rounded focus:outline-none focus:border-[#009F7F]"
            placeholder="Last Name" value={lastname} required onChange={handleInputChange} onFocus={handleInputFocus} />
        </div>
        <div className="flex gap-3 flex-col sm:flex-row mb-4">
          <div className="sm:w-1/2">
            <input type="tel" name="expiry" className="w-full p-2 border rounded focus:outline-none focus:border-[#009F7F]"
              placeholder="Valid Thru (MM/YY)" pattern="\d\d/\d\d" value={expiry} required onChange={handleInputChange} onFocus={handleInputFocus} />
          </div>
          <div className="sm:w-1/2">
            <input type="tel" name="cvc" className="w-full p-2 border rounded focus:outline-none focus:border-[#009F7F]"
              placeholder="CVC" pattern="\d{3,4}" value={cvc} required onChange={handleInputChange} onFocus={handleInputFocus} />
          </div>
        </div>
        <input type="hidden" name="issuer" value={issuer} />
        <div className="text-center">
          {
            loading ?
              <div className="flex items-center justify-center">
                <div className="animate-spin rounded-full h-9 w-9 border-t-2 border-b-2 border-[#009F7F]"></div>
              </div>
              :
              <button className="bg-[#009F7F] text-white py-2 w-full sm:w-[50%] sm:mx-auto rounded-lg hover:bg-[#007B66]">PAY</button>
          }
        </div>
      </form>

      <Card number={number} name={name} expiry={expiry} cvc={cvc} focused={focused} callback={handleCallback} />

      {/* {formData && (
        <div className="App-highlight">
          {formatFormData(formData).map((d, i) => (
            <div key={i}>{d}</div>
          ))}
        </div>
      )} */}
      {/* <SupportedCards /> */}
    </div>
  );
};

export default CreditCard;
