import { http, createConfig } from 'wagmi'
import { polygon } from 'wagmi/chains'
import { metaMask } from 'wagmi/connectors'

const projectId = 'cc6c11ca798adbb55ad43625ceb64935'
const metadata = {
  name: 'Elevated Market Place',
  description: 'A True Decentralized Shopping Experience',
  url: 'https://shop.elevatedmarketplace.world', // origin must match your domain & subdomain
  icons: ['https://shop.elevatedmarketplace.world/assets/img/logo/logo.png']
};

export const config = createConfig({
  chains: [polygon],
  metadata,
  chainId:polygon.id,
  connectors: [
    metaMask(),
  ],
  transports: {
    [polygon.id]: http(),
  },
})