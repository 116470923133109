
import React, { useEffect, useState } from 'react';
import { Modal, Input, notification } from 'antd';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import { postRequest } from '../backendServices/ApiCalls';

const AddressModal = ({ showModal, handleOk, handleCancel, isModalOpen, isEditMode, Address }) => {
  const [api, contextHolder] = notification.useNotification();
  const successNotification = (placement, message, description) => {
    api.success({ message, description, placement });
  };
  const errorNotification = (placement, message, description) => {
    api.error({ message, description, placement });
  };
  const options = countryList().getData();
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zipCode, setZipCode] = useState('');
  const handleCountryChange = (value) => {
    setSelectedCountry(value);
  };

  useEffect(() => {
    if (isModalOpen && Address) {
      const initialCountry = options.find(option => option.label === Address?.country);
      setSelectedCountry(initialCountry || null);
      setFirstName(Address?.firstname || '');
      setLastName(Address?.lastname || '');
      setPhoneNumber(Address?.mobile || '');
      setStreetAddress(Address?.address || '');
      setCity(Address?.city || '');
      setState(Address?.state || '');
      setZipCode(Address?.zipCode || '');
    }
  }, [isModalOpen, Address, options]);

  const handleSubmit = () => {
    const formData = {
      country: selectedCountry ? selectedCountry.label : '',
      firstName,
      lastName,
      phoneNumber,
      streetAddress,
      // aptDetails,
      city,
      state,
      zipCode,
    };
//     console.log("Form data ",formData);
// return 
    postRequest("/updateaddress",formData,(response) => {
            if (response?.data?.status === "success") {
              successNotification("topRight", "Success", response?.data?.message);
              handleCancel()
            } else if (response?.data?.status === "error") {
              errorNotification("topRight", "Error", response?.data?.message);
              handleCancel()
            }
          },
          (error) => {
            console.log(error?.response?.data);
          }
        );
  };

  const customSelectStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? '#009f7f' : '#ccc',
      '&:hover': {
        borderColor: '#009f7f',
      },
      boxShadow: state.isFocused ? '0 0 0 1px #009f7f' : 'none',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#009f7f' : state.isFocused ? '#e6f7f4' : null,
      color: state.isSelected ? '#fff' : '#333',
      '&:hover': {
        backgroundColor: '#e6f7f4',
        color: 'black',
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'black',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#999',
    }),
  };

  return (
    <>
      <Modal
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
        className="custom-modal"
      >
        <div className="w-full">
          <h2 className="text-lg font-semibold border-b shadow-sm w-full">
            {isEditMode ? 'Update your address' : 'Enter a new address'}
          </h2>
          <div>
            <h2 className="text-lg font-semibold mt-4">{isEditMode ? 'Edit your address' : 'Add a new address'}</h2>
            <div className="mt-6">
              <p className='font-semibold text-sm mb-1'>Country/Region</p>
              <Select
                className='inputColorForAddress outline-0 hover:border-[#009f7f]'
                options={options}
                value={selectedCountry}
                onChange={handleCountryChange}
                placeholder="Select a country"
                styles={customSelectStyles}
              />
            </div>
            {/* <div className="mt-4">
              <p className='font-semibold text-sm mb-1'>First name</p>
              <Input
                placeholder="First Name"
                className='inputColorForAddress outline-0 hover:border-[#009f7f]'
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div className="mt-4">
              <p className='font-semibold text-sm mb-1'>Last name</p>
              <Input
                placeholder="Last Name"
                className='inputColorForAddress outline-0 hover:border-[#009f7f]'
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <div className="mt-4">
              <p className='font-semibold text-sm mb-1'>Phone number</p>
              <Input
                placeholder="Phone number"
                className='inputColorForAddress outline-0 hover:border-[#009f7f]'
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
              <p className='text-xs mt-1'>May be used to assist delivery</p>
            </div> */}
            <div className="mt-4">
              <p className='font-semibold text-sm mb-1'>Address</p>
              <Input
                placeholder="Street address or P.O. Box"
                className='inputColorForAddress outline-0 hover:border-[#009f7f] mb-1'
                value={streetAddress}
                onChange={(e) => setStreetAddress(e.target.value)}
              />
              {/* <Input
                placeholder="Apt, suite, unit, building, floor, etc."
                className='inputColorForAddress outline-0 hover:border-[#009f7f]'
                value={aptDetails}
                onChange={(e) => setAptDetails(e.target.value)}
              /> */}
            </div>
            <div className="mt-4 flex gap-4">
              <div>
                <p className='font-semibold text-sm mb-1'>City</p>
                <Input
                  placeholder="E.g New York"
                  className='inputColorForAddress outline-0 hover:border-[#009f7f]'
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
              </div>
              <div>
                <p className='font-semibold text-sm mb-1'>State</p>
                <Input
                  placeholder="State"
                  className='inputColorForAddress outline-0 hover:border-[#009f7f]'
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                />
              </div>
              <div>
                <p className='font-semibold text-sm mb-1'>ZIP Code</p>
                <Input
                  placeholder="5-digit-Zipcode"
                  className='inputColorForAddress outline-0 hover:border-[#009f7f]'
                  value={zipCode}
                  onChange={(e) => setZipCode(e.target.value)}
                />
              </div>
            </div>
            <button className="bg-[#009f7f] mt-10 rounded-lg" onClick={handleSubmit}>
              <p className="font-semibold px-3 py-1 text-white text-sm">{isEditMode ? 'Update address' : 'Use this address'}</p>
            </button>
          </div>
        </div>
      </Modal>
      <div>{contextHolder}</div>
    </>
  );
};

export default AddressModal;
