import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Dashoard from './Dashoard'
import AddProduct from './AddProduct'
import UpdateProduct from './UpdateProduct'
import AddShop from './AddShop'
import AllProducts from './AllProducts'
import Orders from './Orders'
import OrderDetail from './OrderDetail'
import UpdateShop from './UpdateShop'
import NotFound from '../../components/NotFound'
export default function SellerLinks() {
    return (
        <>
            <Routes>
                <Route path="/home" element={<Dashoard />} />
                <Route path="/product" element={<AddProduct />} />
                <Route path="/updateProduct/:id" element={<UpdateProduct />} />
                <Route path="/shop" element={<AddShop />} />
                <Route path="/allproducts" element={<AllProducts />} />
                <Route path="/orders" element={<Orders />} />
                <Route path="/order/:orderid" element={<OrderDetail />} />
                <Route path="/updateShop" element={<UpdateShop />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </>
    )
}
