import React, { useEffect, useState } from "react";
import ShopCard from "../../components/ShopCard";
import { shopByName } from "../../backendServices/ApiCalls";
import { useLocation, useParams } from "react-router-dom";

function Shops() {

  let { pathname } = useLocation();
  // let { shopname } = useParams();
  // console.log('pathname=>', pathname.split('/').filter(Boolean).join('/'))
  // console.log('shopname', shopname)
  const [shop, setShop] = useState([]);
  const [link, setLink] = useState("");
  const [bannerLink, setBannerLink] = useState("");
  const [logoLink, setLogoLink] = useState("");

  useEffect(() => {
    let param = {
      shopname: pathname.split("/").filter(Boolean).join("/"),
    };
    shopByName(
      param,
      async (response) => {
        if (response?.data?.status === "success") {
          // console.log('response?.data?.data', response?.data?.data)
          setShop(response?.data?.data);
          setLink(response?.data?.link);
          setBannerLink(response?.data?.banner_link);
          setLogoLink(response?.data?.logo_link);
          
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  }, []);

  return (
    <div className="">
      <h3 className="mb-8 text-2xl font-bold text-heading">All Shops</h3>
      <div className="bg-white rounded-lg mt-4 pt-6">
        <div className="grid p-6 sm:grid-cols-2 gap-4 lg:grid-cols-3 grid-cols-1">
          {shop.map((shop) => (
            <ShopCard
              key={shop?.id}
              id={shop?.id}
              title={shop?.store_name}
              description={shop?.store_desc}
              tags={shop?.tags}
              bannerLink={bannerLink}
              banner={shop?.store_banner_image}
              logoLink={logoLink}
              storeLogo={shop?.store_logo}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Shops;
