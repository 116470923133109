import React, { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';

export default function PrivateRoute({ Component }) {
  const { user } = useContext(UserContext);
  const location = useLocation();
  const token = localStorage.getItem("token");
  const role = sessionStorage.getItem("role");
  // console.log('user in route', user);
  // console.log('role in route', role);
  // let sellerRole
  // // let sellerRole = false;
  // if(user.seller == 0){
  //   sellerRole = false;
  //   // console.log('the user is buyer')
  // }
  // if(user.seller == 1 && user.sellerApprove == 'approved') {
  //   sellerRole = true;
  //   console.log('the user is seller and approved')
  // }
  // console.log('(user == false && token == null) || role == seller', (user == false && token == null) || role == 'buyer')
  if ((user == false && token == null) || role == 'buyer') {
    return <Navigate to="/" state={{ from: location.pathname }} replace />;
  }

  return <Component />;
}
