import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { postRequest } from '../../backendServices/ApiCalls';
import { notification } from 'antd';

export default function Register() {
    const nav = useNavigate()
    const [api, contextHolder] = notification.useNotification();
    const errorNotification = (placement, message, description) => {
        api.error({ message, description, placement })
    };
    const successNotification = (placement, message, description) => {
        api.success({ message, description, placement });
    };
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        password: '',
        phoneNumber: '',
        address: '',
        firstname: '',
        lastname: '',
        referralcode: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission logic here
        // console.log(formData)
        setLoading(true);
        postRequest("/register", formData, async (response) => {
            // console.log("response?.data", response?.data);
            if (response?.data?.status === "success") {
                // setCategoryHook(response?.data?.data)
                successNotification('topRight', 'Success', response?.data?.message)
                setTimeout(() => {
                    nav('/login')
                }, 2000);
                setLoading(false);
            }
            if (response?.data?.status === "error") {
                // setCategoryHook(response?.data?.data)
                errorNotification('topRight', 'Error', response?.data?.message)
                setLoading(false);
            }
        },
            (error) => {
                console.log(error?.response?.data);
                setLoading(false);
            }
        );
    };

    return (
        <>
            <div className="min-h-screen flex items-center justify-center bg-gray-100">
                <div className="bg-white py-5 px-8 rounded-lg shadow-md w-full max-w-md text-cente">
                    <h2 className="text-2xl mb-1 font-semibold text-gray-800">Create account</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="grid gap-2">
                            <div className="">
                                <input
                                    type="text"
                                    name="username"
                                    value={formData.username}
                                    onChange={handleChange}
                                    placeholder="Username"
                                    className="w-full p-2 border border-gray-300 rounded mt-2 focus:outline-none focus:border-[#009F7F]"
                                />
                            </div>
                            <div className="">
                                <input
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    placeholder="Email"
                                    className="w-full p-2 border border-gray-300 rounded mt-2 focus:outline-none focus:border-[#009F7F]"
                                />
                            </div>
                            <div className="flex space-x-4">
                                <input
                                    type="text"
                                    name="firstname"
                                    value={formData.firstname}
                                    onChange={handleChange}
                                    placeholder="First Name"
                                    className="w-1/2 p-2 border border-gray-300 rounded mt-2 focus:outline-none focus:border-[#009F7F]"
                                />
                                <input
                                    type="text"
                                    name="lastname"
                                    value={formData.lastname}
                                    onChange={handleChange}
                                    placeholder="Last Name"
                                    className="w-1/2 p-2 border border-gray-300 rounded mt-2 focus:outline-none focus:border-[#009F7F]"
                                />
                            </div>
                            <div className="flex space-x-4">
                                <input
                                    type="password"
                                    name="password"
                                    value={formData.password}
                                    onChange={handleChange}
                                    placeholder="Password"
                                    className="w-1/2 p-2 border border-gray-300 rounded mt-2 focus:outline-none focus:border-[#009F7F]"
                                />
                                <input
                                    type="text"
                                    name="phoneNumber"
                                    value={formData.phoneNumber}
                                    onChange={handleChange}
                                    placeholder="Phone Number"
                                    className="w-1/2 p-2 border border-gray-300 rounded mt-2 focus:outline-none focus:border-[#009F7F]"
                                />
                            </div>
                            <div className="">
                                <input
                                    type="text"
                                    name="address"
                                    value={formData.address}
                                    onChange={handleChange}
                                    placeholder="Address"
                                    className="w-full p-2 border border-gray-300 rounded mt-2 focus:outline-none focus:border-[#009F7F]"
                                />
                            </div>
                            <div className="">
                                <input
                                    type="text"
                                    name="referralcode"
                                    value={formData.referralcode}
                                    onChange={handleChange}
                                    placeholder="Referral Code"
                                    className="w-full p-2 border border-gray-300 rounded mt-2 focus:outline-none focus:border-[#009F7F]"
                                />
                            </div>
                        </div>
                        {loading ? (
                            <div className="flex items-center justify-center">
                                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#009F7F]"></div>
                            </div>
                        ) : (
                            <button
                                type="submit"
                                className="w-full bg-[#009F7F] text-white p-2 rounded mt-4 hover:bg-green-700"
                            >
                                Register
                            </button>
                        )}
                    </form>
                    <div className='flex gap-x-2 mt-2'>
                        <p>Already have an account?</p>
                        <Link to="/login" className="text-[#009F7F] hover:underline">Next</Link>
                    </div>
                </div>
                <div>{contextHolder}</div>
            </div>
        </>
    );
}
