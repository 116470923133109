import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { WagmiProvider, useAccount, useChainId } from 'wagmi'
import { config } from './chainConfig'
import { Account } from './ConnectedAccount' 
import { WalletOptions } from './WalletOptions' 
import CreateTransaction from './CreateTransaction'
import UpdateNetwork from './UpdateNetwork'

const queryClient = new QueryClient()
function ConnectWallet() { 
  const { isConnected } = useAccount() 
  if (isConnected) return <Account /> 
  return <WalletOptions /> 
} 

function UpdateChain({cartTotalPrice}) { 
  const { chainId } = useAccount()
  const { isConnected } = useAccount() 
  if(isConnected)
  {
  if (chainId !== 137) return <UpdateNetwork /> 
  return <CreateTransaction cartTotalPrice={cartTotalPrice} />
  }
} 

function Web3ModalProvider({totalCartAmount}) {

  return (
    <>
        <ConnectWallet /> 
        <UpdateChain cartTotalPrice={totalCartAmount} />
    </>
    
  );
}

export default Web3ModalProvider;
