import React from "react";
import { useNavigate } from "react-router-dom";

function SummaryCard(props) {
  const nav = useNavigate()
  const gotoPage = (uri) =>{
    nav(uri)
  }

  return (
    <div className={`w-full border-g h-24 xl:h-32 p-4 xl:p-8 bg-white-300  border-b-4 ${props.border} rounded-md  flex hover:bg-gray-100 hover:cursor-pointer`} onClick={()=>gotoPage(props.route)}>
      <div className="flex h-15 w-20  rounded ">
        <img src={props.icon} alt="Flowbite Logo" />
      </div>
      <div className="flex ml-1 w-full flex-col text-left">
        <h4 className="mb-1 font-semibold font-normal text-body">{props.title}</h4>
        <span className="mb-2 sm:text-xs text-[10px] sm:block hidden  font-normal text-heading">{props.details}</span>
      </div>
    </div>
  );
}

export default SummaryCard;
