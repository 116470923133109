import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { FaCloudUploadAlt } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { postRequest } from "../../backendServices/ApiCalls";
import { notification } from "antd";
import { useNavigate, useParams } from "react-router-dom";

export default function UpdateShop() {
  const [logoImage, setLogoImage] = useState([]);
  const [bannerImages, setBannerImages] = useState([]);
  const [storeLogoName, setStoreLogoName] = useState(null);
  const [storeBannerName, setStoreBannerName] = useState(null);
  const [logoProgress, setLogoProgress] = useState(false);
  const [bannerProgress, setBannerProgress] = useState(false);
  const initialization = { storeTitle: "", tag: "", description: "" };
  const [formData, setFormData] = useState(initialization);
  const [infoProgress, setInfoProgress] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const nav = useNavigate();
  //   const { id } = useParams();

  useEffect(() => {
    const fetchShopData = async () => {
      await postRequest(`/getstoredetail`, "", (response) => {
        if (response?.data?.status === "success") {
          const data = response?.data?.data[0];
          // console.log('response?.data', response?.data)
          // console.log('data', data)
          setFormData({
            storeTitle: data?.store_name,
            tag: data?.tags,
            description: data?.store_desc,
          });
          //   console.log('formData', formData);
          // console.log('response?.data?.storelogolink + data.store_logo', response?.data?.storelogolink + data.store_logo);
          setStoreLogoName(response?.data?.storelogolink + data.store_logo);
          setStoreBannerName(response?.data?.storebannerlink + data.store_banner_image);
          //   setLogoImage([{ preview: data.storeLogo }]);
          //   setBannerImages([{ preview: data.storeBanner }]);
        }
      });
    };

    fetchShopData();
  }, []);

  //   const featuredDropzone = useDropzone({
  //     accept: { "image/*": [] },
  //     onDrop: (acceptedFiles) => {
  //       setLogoImage(
  //         acceptedFiles.map((file) =>
  //           Object.assign(file, {
  //             preview: URL.createObjectURL(file),
  //           })
  //         )
  //       );
  //       const formData = new FormData();
  //       formData.append("file", acceptedFiles[0]);
  //       formData.append("upload_preset", "cqvhndwd");
  //       formData.append("cloud_name", "dg0t0timf");
  //       setLogoProgress(true);

  //       fetch("https://api.cloudinary.com/v1_1/dg0t0timf/image/upload", {
  //         method: "POST",
  //         body: formData,
  //       })
  //         .then((response) => response.json())
  //         .then((data) => {
  //           setStoreLogoName(data.secure_url);
  //           setLogoProgress(false);
  //         })
  //         .catch((error) => {
  //           setLogoProgress(false);
  //         });
  //     },
  //   });

  //   const galleryDropzone = useDropzone({
  //     accept: { "image/*": [] },
  //     onDrop: (acceptedFiles) => {
  //       setBannerImages(
  //         acceptedFiles.map((file) =>
  //           Object.assign(file, {
  //             preview: URL.createObjectURL(file),
  //           })
  //         )
  //       );
  //       const formData = new FormData();
  //       formData.append("file", acceptedFiles[0]);
  //       formData.append("upload_preset", "cqvhndwd");
  //       formData.append("cloud_name", "dg0t0timf");
  //       setBannerProgress(true);

  //       fetch("https://api.cloudinary.com/v1_1/dg0t0timf/image/upload", {
  //         method: "POST",
  //         body: formData,
  //       })
  //         .then((response) => response.json())
  //         .then((data) => {
  //           setStoreBannerName(data.secure_url);
  //           setBannerProgress(false);
  //         })
  //         .catch((error) => {
  //           setBannerProgress(false);
  //         });
  //     },
  //   });

  //   const thumbsLogo = (logoImage, setLogoImage) => (
  //     <div className="flex flex-row flex-wrap mt-4">
  //       {logoImage.map((file) => (
  //         <div className="relative w-24 h-24 mr-4 mb-4" key={file.name}>
  //           <img
  //             src={file.preview}
  //             className="w-full h-full object-cover rounded-lg"
  //             alt="Preview"
  //           />
  //           <button
  //             onClick={() => setLogoImage([])}
  //             className="absolute top-0 right-0 m-1 p-1 bg-red-500 text-white rounded-full"
  //           >
  //             <IoClose />
  //           </button>
  //           {logoProgress && (
  //             <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-50 rounded-lg">
  //               <div className="loader"></div>
  //             </div>
  //           )}
  //         </div>
  //       ))}
  //     </div>
  //   );

  //   const thumbsHeader = (bannerImages, setBannerImages) => (
  //     <div className="flex flex-row flex-wrap mt-4">
  //       {bannerImages.map((file) => (
  //         <div className="relative w-24 h-24 mr-4 mb-4" key={file.name}>
  //           <img
  //             src={file.preview}
  //             className="w-full h-full object-cover rounded-lg"
  //             alt="Preview"
  //           />
  //           <button
  //             onClick={() => setBannerImages([])}
  //             className="absolute top-0 right-0 m-1 p-1 bg-red-500 text-white rounded-full"
  //           >
  //             <IoClose />
  //           </button>
  //           {bannerProgress && (
  //             <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-50 rounded-lg">
  //               <div className="loader"></div>
  //             </div>
  //           )}
  //         </div>
  //       ))}
  //     </div>
  //   );

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const openNotification = (type, message) => {
    api[type]({
      message: message,
      duration: 2,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setInfoProgress(true);

    const body = {
      storename: formData.storeTitle,
      tags: formData.tag,
      description: formData.description,
      //   storeLogo: storeLogoName,
      //   storeBanner: storeBannerName,
    };
    // console.log('body', body);
    // return 
    await postRequest(`/addstoreinfo`, body, (response) => {
      if (response?.data?.status === "success") {
        openNotification("success", response.data.message);
        setTimeout(() => {
          nav("/dashboard/home");
        }, 1000);
      } else {
        openNotification("error", response.data.message);
      }
      setInfoProgress(false);
    });
  };

  return (
    <div className="w-[90%] mt-48 sm:mt-36 md:mt-32 mx-auto pb-3">
      <div className="py-5 lg:pt-1 lg:pb-5">
        <div className="flex flex-col w-full">
          <span className="flex font-semibold text-xl lg:text-2xl ml-1 md:ml-0 md:mb-2">
            Update Shop
          </span>
        </div>
      </div>
      {contextHolder}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-8 lg:gap-8">
        <div className="mb-4">
          <label
            htmlFor="fileInput"
            className="block text-gray-700 font-semibold mb-2"
          >
            Logo Image:
          </label>
          {/* <div
            {...featuredDropzone.getRootProps({ className: "dropzone" })}
            className="w-full border-2 border-gray-300 border-dashed p-8 cursor-pointer"
          >
            <input {...featuredDropzone.getInputProps()} />
            <div className="text-center">
              <FaCloudUploadAlt className="mx-auto text-gray-500 text-3xl" />
              <p className="mt-2 text-gray-500">Drag and drop or click to upload</p>
            </div>
          </div> */}
          <aside className="flex flex-row flex-wrap mt-4">
            <img src={storeLogoName} alt={formData.storeTitle} />
            {/* {thumbsLogo(logoImage, setLogoImage)} */}
          </aside>
        </div>

        <div className="mb-4">
          <label
            htmlFor="fileInput"
            className="block text-gray-700 font-semibold mb-2"
          >
            Header Image:
          </label>
          {/* <div
            {...galleryDropzone.getRootProps({ className: "dropzone" })}
            className="w-full border-2 border-gray-300 border-dashed p-8 cursor-pointer"
          >
            <input {...galleryDropzone.getInputProps()} />
            <div className="text-center">
              <FaCloudUploadAlt className="mx-auto text-gray-500 text-3xl" />
              <p className="mt-2 text-gray-500">Drag and drop or click to upload</p>
            </div>
          </div> */}
          <aside className="flex flex-row flex-wrap mt-4">
            <img src={storeBannerName} alt={formData.storeTitle} />
            {/* {thumbsHeader(bannerImages, setBannerImages)} */}
          </aside>
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-8 lg:gap-8">
          <div>
            <label htmlFor="storeTitle" className="block text-gray-700 font-semibold mb-2">
              Store Title:
            </label>
            <input
              type="text"
              name="storeTitle"
              value={formData.storeTitle}
              onChange={handleChange}
              placeholder="Store title"
              className="w-full border border-gray-300 p-2 rounded-lg"
            />
          </div>

          <div>
            <label htmlFor="tag" className="block text-gray-700 font-semibold mb-2">
              Tag:
            </label>
            <input
              type="text"
              name="tag"
              value={formData.tag}
              onChange={handleChange}
              placeholder="Tag"
              className="w-full border border-gray-300 p-2 rounded-lg"
            />
          </div>
        </div>

        <div className="mb-4">
          <label htmlFor="description" className="block text-gray-700 font-semibold mb-2">
            Description:
          </label>
          <ReactQuill
            name="description"
            value={formData.description}
            onChange={(value) =>
              setFormData((prevData) => ({ ...prevData, description: value }))
            }
            className="border border-gray-300 rounded-lg"
          />
        </div>

        <div className="mt-8">
          <button
            type="submit"
            className="bg-[#009F7F] hover:bg-[#007B63] text-white font-semibold py-2 px-4 rounded-lg w-full"
          >
            {infoProgress ? (
              <div role="status" className="flex justify-center items-center">
                <svg
                  aria-hidden="true"
                  className="w-6 h-6 text-gray-200 animate-spin fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08197 50.5908C9.08197 73.1427 27.4481 91.5088 50 91.5088C72.5519 91.5088 90.918 73.1427 90.918 50.5908C90.918 28.0389 72.5519 9.67275 50 9.67275C27.4481 9.67275 9.08197 28.0389 9.08197 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5531C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7235 75.2124 7.41289C69.5422 4.10237 63.2754 1.94025 56.7459 1.05126C51.8299 0.367181 46.8409 0.446529 41.958 1.27873C39.4712 1.69471 37.9625 4.19778 38.5996 6.62326C39.2367 9.04874 41.7178 10.4815 44.209 10.1071C47.8824 9.561 51.6295 9.52675 55.3062 10.0178C60.6948 10.7309 65.925 12.612 70.6331 15.5563C75.3412 18.5005 79.4172 22.4564 82.6272 27.1718C85.1443 30.8191 87.1338 34.799 88.5306 38.9985C89.302 41.367 91.5422 42.9109 93.9676 42.2739Z"
                    fill="currentFill"
                  />
                </svg>
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              "Update Store"
            )}
          </button>
        </div>
      </form>
    </div>
  );
}
