// Google api
// import React, { useEffect, useState } from 'react';

// const LocationComponent = () => {
//   const [location, setLocation] = useState({ latitude: null, longitude: null });
//   const [country, setCountry] = useState(null);
//   const [error, setError] = useState(null);

//   const getLocation = () => {
//     if (navigator.geolocation) {
//       navigator.geolocation.getCurrentPosition(
//         (position) => {
//           const { latitude, longitude } = position.coords;
//           setLocation({ latitude, longitude });
//           getCountry(latitude, longitude);
//         },
//         (err) => {
//           setError(err.message);
//         }
//       );
//     } else {
//       setError('Geolocation is not supported by this browser.');
//     }
//   };

//   const getCountry = (latitude, longitude) => {
//     const apiKey = 'AIzaSyCFxo-d9v1DlB4BGheOVENEo8BwLinmQnM';
//     const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;

//     fetch(url)
//       .then((response) => response.json())
//       .then((data) => {
//         // console.log('data', data)
//         if (data.status === 'OK') {
//           const addressComponents = data.results[0].address_components;
//           const countryComponent = addressComponents.find(component =>
//             component.types.includes('country')
//           );
//           setCountry(countryComponent ? countryComponent.long_name : 'Country not found');
//         } else {
//           setError('Geocoding failed');
//         }
//       })
//       .catch((err) => {
//         setError(err.message);
//       });
//   };

//   useEffect(() => {
//     getLocation()
//   }, [])
  

//   return (
//     <div>
//       {location.latitude && location.longitude ? (
//         <div>
//           {/* <p>Latitude: {location.latitude}</p> */}
//           {/* <p>Longitude: {location.longitude}</p> */}
//           {country && <p>Country: {country}</p>}
//         </div>
//       ) : (
//         <p>{error ? error : 'Please Enable Location'}</p>
//       )}
//     </div>
//   );
// };

// export default LocationComponent;








// // pi.geoapify.com
// import React, { useEffect, useState } from 'react';

// const LocationComponent = () => {
//   const [location, setLocation] = useState({ latitude: null, longitude: null });
//   const [country, setCountry] = useState(null);
//   const [error, setError] = useState(null);

//   const getLocation = () => {
//     if (navigator.geolocation) {
//       navigator.geolocation.getCurrentPosition(
//         (position) => {
//           const { latitude, longitude } = position.coords;
//           setLocation({ latitude, longitude });
//           getCountry(latitude, longitude);
//         },
//         (err) => {
//           setError(err.message);
//         }
//       );
//     } else {
//       setError('Geolocation is not supported by this browser.');
//     }
//   };

//   const getCountry = (latitude, longitude) => {
//     const apiKey = '060e790539364647911aa6ae54df8df7'; // Use your Geoapify API key here
//     const url = `https://api.geoapify.com/v1/geocode/reverse?lat=${latitude}&lon=${longitude}&apiKey=${apiKey}`;

//     fetch(url)
//       .then((response) => response.json())
//       .then((data) => {
//         if (data.features && data.features.length > 0) {
//           const address = data.features[0].properties;
//           setCountry(address.country || 'Country not found');
//         } else {
//           setError('Geocoding failed');
//         }
//       })
//       .catch((err) => {
//         setError(err.message);
//       });
//   };

//   useEffect(() => {
//     getLocation();
//   }, []);

//   return (
//     <div>
//       {location.latitude && location.longitude ? (
//         <div>
//           {country && <p>{country}</p>}
//         </div>
//       ) : (
//         <p>{error ? error : 'Please Enable Location'}</p>
//       )}
//     </div>
//   );
// };

// export default LocationComponent;



import React, { useEffect, useState } from 'react';

const LocationComponent = () => {
  const [country, setCountry] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const getCountryByIP = () => {
    const apiKey = process.env.REACT_APP_GEOAPIFY;
    const url = `https://api.geoapify.com/v1/ipinfo?apiKey=${apiKey}`;

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setIsLoading(false);
        if (data.country) {
          setCountry(data.country.name || 'Country not found');
        } else {
          setError('IP-based geolocation failed');
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err.message);
      });
  };

  useEffect(() => {
    getCountryByIP();
  }, []);

  return (
    <div>
      {isLoading ? (
        <p></p>  // Displaying a single character while loading
      ) : country ? (
        <p>{country}</p>
      ) : (
        <p>{error ? error : 'Fetching country information...'}</p>
      )}
    </div>
  );
};

export default LocationComponent;

