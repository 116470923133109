import React from "react";
import { Link } from "react-router-dom";

function ShopCard({
  id,
  title,
  description,
  storeLogo,
  logoLink,
  bannerLink,
  banner,
  tags,
}) {
  // let imageNames = [];
  // if (imgSrc) {
  //   try {
  //     const pictureData = JSON.parse(imgSrc);
  //     // console.log("pictureData", pictureData);
  //     imageNames = pictureData.imageNames;
  //   } catch (e) {
  //     console.error("Invalid JSON in item.picture", e);
  //   }
  // }

  const truncateDescription = (desc, maxLength) => {
    if (desc?.length > maxLength) {
      return `${desc?.substring(0, maxLength)}...`;
    }
    return desc;
  };

  const truncatedDescription = truncateDescription(description, 100);
  return (
    <>
      <div className="w-full border-shop p-4 bg-white rounded-md relative h-[70%">
        <img
          src={bannerLink + banner}
          alt={title}
          className="rounded w-[100%] sm:h-[65%]"
        />
        {/* <p className="absolut bg-green-500 z-10 top-0">{tags}</p> */}
        <div className="mt-1">
          {tags.split(",").map((tag, key) => {
            return (
              <span className="bg-[#0da889] text-white mx-0.5 px-1 py-0.5 rounded-sm" key={key}>
                {tag}
              </span>
            );
          })}
        </div>
        <div className="flex pt-4">
          <div className="flex h-16 w-24 my-auto rounded-full ">
            <img
              src={logoLink + storeLogo}
              alt={title}
              className="rounded-full"
            />
          </div>
          <div className="flex w-full flex-col mt-2 text-end">
            <Link
              to={`/shop/${title?.replace(/\s/g, '-')}/${id}`}
              className="mb-2 sm:text-lg font-semibold text-heading hover:text-[#0DA889]"
            >
              {title}
            </Link>
            <span className="flex text-xs text-body">
              <svg
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="h-3.5 w-3.5 shrink-0 text-gray-500 ltr:mr-1 rtl:ml-1"
              >
                <path
                  d="M12 0C7.34756 0 3.5625 3.78506 3.5625 8.4375C3.5625 10.0094 3.99792 11.5434 4.82198 12.8743L11.5197 23.6676C11.648 23.8744 11.874 24 12.1171 24C12.119 24 12.1208 24 12.1227 24C12.3679 23.9981 12.5944 23.8686 12.7204 23.6582L19.2474 12.7603C20.026 11.4576 20.4375 9.96277 20.4375 8.4375C20.4375 3.78506 16.6524 0 12 0ZM18.0406 12.0383L12.1065 21.9462L6.0172 12.1334C5.33128 11.0257 4.95938 9.74766 4.95938 8.4375C4.95938 4.56047 8.12297 1.39687 12 1.39687C15.877 1.39687 19.0359 4.56047 19.0359 8.4375C19.0359 9.7088 18.6885 10.9541 18.0406 12.0383Z"
                  fill="currentColor"
                ></path>
                <path
                  d="M12 4.21875C9.67378 4.21875 7.78125 6.11128 7.78125 8.4375C7.78125 10.7489 9.64298 12.6562 12 12.6562C14.3861 12.6562 16.2188 10.7235 16.2188 8.4375C16.2188 6.11128 14.3262 4.21875 12 4.21875ZM12 11.2594C10.4411 11.2594 9.17813 9.9922 9.17813 8.4375C9.17813 6.88669 10.4492 5.61563 12 5.61563C13.5508 5.61563 14.8172 6.88669 14.8172 8.4375C14.8172 9.96952 13.5836 11.2594 12 11.2594Z"
                  fill="currentColor"
                ></path>
              </svg>
              <div dangerouslySetInnerHTML={{ __html: truncatedDescription }} />
            </span>{" "}
          </div>
        </div>
      </div>
    </>
  );
}

export default ShopCard;
